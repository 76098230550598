import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchAdminRequest = () => ({
    type: ActionTypes.FETCH_ADMINS_REQUEST,
});

export const fetchAdminSuccess = (admins) => ({
    type: ActionTypes.FETCH_ADMINS_SUCCESS,
    payload: admins
});

export const fetchAdminFailure = (error) => ({
    type: ActionTypes.FETCH_ADMINS_FAILURE,
    payload: error
});


export const fetchAdmins = (pageindex, itemsPerPage) => {
    return async (dispatch) => {
        dispatch(fetchAdminRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + `path=admin&type=get-list-data&page=${pageindex}&number_per_page=${itemsPerPage}`, 
                    {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });
                
                const data = response.data.respons || {"data_list": [], "all_data_count":0};
                dispatch(fetchAdminSuccess(data));
            }
            else {
                const data = {"data_list": [{ "admin_id": 1, 'login_id': 'hakaru_admin', 'admin_name': 'rakusuru@hakaru.jp', 'lilz_gauge_token': '123456', 'lilz_gauge_api_token_date': '2020/04/13' },
                { "admin_id": 2, 'login_id': 'hakaru_admin2', 'admin_name': 'rakusuru@hakaru.jp', 'lilz_gauge_token': '123456', 'lilz_gauge_api_token_date': '2020/04/13' },
                { "admin_id": 3, 'login_id': 'hakaru_admin', 'admin_name': 'rakusuru@hakaru.jp', 'lilz_gauge_token': '123456', 'lilz_gauge_api_token_date': '2020/04/13' },
                { "admin_id": 4, 'login_id': 'hakaru_admin', 'admin_name': 'rakusuru@hakaru.jp', 'lilz_gauge_token': '123456', 'lilz_gauge_api_token_date': '2020/04/13' },
                { "admin_id": 5, 'login_id': 'hakaru_admin', 'admin_name': 'rakusuru@hakaru.jp', 'lilz_gauge_token': '123456', 'lilz_gauge_api_token_date': '2020/04/13' }
                ], "all_data_count": 5};
                dispatch(fetchAdminSuccess(data));
            }


        } catch (error) {
            dispatch(fetchAdminFailure(error.message));
        }
    };
};
