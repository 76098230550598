import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  maintenance: [],
  error: null,
  alldatanumber: 0 
};

const maintenancelistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_MAINTENANCE_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_MAINTENANCE_SUCCESS:
      return { ...state, loading: false,maintenance: action.payload.data_list,error: null, alldatanumber: action.payload.all_data_count };
    case ActionTypes.FETCH_MAINTENANCE_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default maintenancelistReducer;
