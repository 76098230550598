import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CameraChange from '../components/CameraChange';
import { fetchCameraChange, fetchGetGaugeData, submitCameraDelete, submitCameraEdit, submitAddPhotoAuto, submitAddPhotoManual, submitRemoveGaugeManual, searchImage, submitUpdateGaugeManual} from '../actions/camerachange';

const mapStateToProps = (state) => {
    const { cameraChange } = state;
    return {
        cameraData: cameraChange.cameraData, 
        loading: cameraChange.loading,
        error: cameraChange.error,
        dataCount: cameraChange.dataCount
    };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCameraChange,
    fetchGetGaugeData,
    submitCameraDelete,
    submitCameraEdit,
    submitAddPhotoAuto,
    submitAddPhotoManual,
    submitRemoveGaugeManual,
    submitUpdateGaugeManual,
    searchImage
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CameraChange);