import React from 'react';
import PropTypes from 'prop-types';
import './Modal.css';

const Modal = ({ 
  isOpen, 
  onClose, 
  message, 
  onConfirm = () => {}, 
  showCancelButton = false 
}) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <span className="modal-close" onClick={onClose}>✖</span>
        <p className="modal-text">{message}</p>
        <hr className="modal-divider" />
        {showCancelButton ? (
          <div className="btn-modal">
            <button className="btn-modal-close" onClick={onConfirm}>OK</button>
            <button className="btn-modal-close" onClick={onClose}>キャンセル</button>
          </div>
        ) : (
          <button className="btn-modal-close" onClick={onClose}>OK</button>
        )}
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func,
  showCancelButton: PropTypes.bool
};

export default Modal;
