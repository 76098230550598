import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  facilitydata: {},
  error: null
};
const facilityEditReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FACILITY_EDIT_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FACILITY_EDIT_SUCCESS:
      return { ...state, loading: false, facilitydata: action.payload };
    case ActionTypes.FACILITY_EDIT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.FACILITY_UPDATE_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FACILITY_UPDATE_SUCCESS:
      return { ...state, loading: false};
    case ActionTypes.FACILITY_UPDATE_FAILURE:
      return { ...state, loading: false};
    default:
      return state;
  }
};

export default facilityEditReducer;
