// eslint-disable-next-line import/no-anonymous-default-export
export default {
    REQUEST_SEND_LOGIN_INFORMATION: 'REQUEST_SEND_LOGIN_INFORMATION',
    RECEIVE_SEND_LOGIN_INFORMATION_SUCCESS: 'RECEIVE_SEND_LOGIN_INFORMATION_SUCCESS',
    RECEIVE_SEND_LOGIN_INFORMATION_FAILURE: 'RECEIVE_SEND_LOGIN_INFORMATION_FAILURE',
    RECEIVE_OTP_ENABLED: 'RECEIVE_OTP_ENABLED',

    CHECK_OTP_REQUEST: 'CHECK_OTP_REQUEST',
    CHECK_OTP_SUCCESS: 'CHECK_OTP_SUCCESS',
    CHECK_OTP_FAILURE: 'CHECK_OTP_FAILURE',

    RESEND_OTP_REQUEST: 'RESEND_OTP_REQUEST',
    RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
    RESEND_OTP_FAILURE: 'RESEND_OTP_FAILURE',

    CLEAR_ERROR: 'CLEAR_ERROR',

    REFRESH_ACCESS_TOKEN_REQUEST: 'REFRESH_ACCESS_TOKEN_REQUEST',
    REFRESH_ACCESS_TOKEN_SUCCESS: 'REFRESH_ACCESS_TOKEN_SUCCESS',
    REFRESH_ACCESS_TOKEN_FAILURE: 'REFRESH_ACCESS_TOKEN_FAILURE',

    FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
    FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',

    LOGOUT_USER: 'LOGOUT_USER',

    FETCH_FACILITIES_REQUEST: 'FETCH_FACILITIES_REQUEST',
    FETCH_FACILITIES_SUCCESS: 'FETCH_FACILITIES_SUCCESS',
    FETCH_FACILITIES_FAILURE: 'FETCH_FACILITIES_FAILURE',
    FETCH_IMAGE_LIST_REQUEST: 'FETCH_IMAGE_LIST_REQUEST',
    FETCH_IMAGE_LIST_SUCCESS: 'FETCH_IMAGE_LIST_SUCCESS',
    FETCH_IMAGE_LIST_FAILURE: 'FETCH_IMAGE_LIST_FAILURE',
    
    FETCH_COUNT_REQUEST: 'FETCH_COUNT_REQUEST',
    FETCH_COUNT_SUCCESS: 'FETCH_COUNT_SUCCESS',
    FETCH_COUNT_FAILURE: 'FETCH_COUNT_FAILURE',
    SET_SELECTED_ITEM: 'SET_SELECTED_ITEM',

    FETCH_COMPANY_SUCCESS: 'FETCH_COMPANY_SUCCESS',

    FETCH_BATTERY_GRAPH_DATA_REQUEST: 'FETCH_BATTERY_GRAPH_DATA_REQUEST',
    FETCH_BATTERY_GRAPH_DATA_SUCCESS: 'FETCH_BATTERY_GRAPH_DATA_SUCCESS',
    FETCH_BATTERY_GRAPH_DATA_FAILURE: 'FETCH_BATTERY_GRAPH_DATA_FAILURE',
    SET_SELECTED_CAMERA: 'SET_SELECTED_CAMERA',

    FETCH_SEARCHLIST_REQUEST: 'FETCH_SEARCHLIST_REQUEST',
    FETCH_SEARCHLIST_SUCCESS: 'FETCH_SEARCHLIST_SUCCESS',
    FETCH_SEARCHLIST_FAILURE: 'FETCH_SEARCHLIST_FAILURE',

    DATA_REGISTER_REQUEST: 'DATA_REGISTER_REQUEST',
    DATA_REGISTER_SUCCESS: 'DATA_REGISTER_SUCCESS',
    DATA_REGISTER_FAILURE: 'DATA_REGISTER_FAILURE',

    DATA_EDIT_REQUEST: 'DATA_EDIT_REQUEST',
    DATA_EDIT_SUCCESS: 'DATA_EDIT_SUCCESS',
    DATA_EDIT_FAILURE: 'DATA_EDIT_FAILURE',

    DATA_DELETE_REQUEST: 'DATA_DELETE_REQUEST',
    DATA_DELETE_SUCCESS: 'DATA_DELETE_SUCCESS',
    DATA_DELETE_FAILURE: 'DATA_DELETE_FAILURE',

    FETCH_MANAGE_COMPANYINFO_REQUEST : 'FETCH_MANAGE_COMPANYINFO_REQUEST',
    FETCH_MANAGE_COMPANYINFO_SUCCESS : 'FETCH_MANAGE_COMPANYINFO_SUCCESS',
    FETCH_MANAGE_COMPANYINFO_FAILURE : 'FETCH_MANAGE_COMPANYINFO_FAILURE',

    FETCH_COMPANIES_REQUEST : 'FETCH_COMPANIES_REQUEST',
    FETCH_COMPANIES_SUCCESS : 'FETCH_COMPANIES_SUCCESS',
    FETCH_COMPANIES_FAILURE : 'FETCH_COMPANIES_FAILURE',

    FETCH_ADMINS_REQUEST : 'FETCH_ADMINS_REQUEST',
    FETCH_ADMINS_SUCCESS : 'FETCH_ADMINS_SUCCESS',
    FETCH_ADMINS_FAILURE : 'FETCH_ADMINS_FAILURE',

    REGISTER_ADMIN_REQUEST : 'REGISTER_ADMIN_REQUEST',
    REGISTER_ADMIN_SUCCESS : 'REGISTER_ADMIN_SUCCESS',
    REGISTER_ADMIN_FAILURE : 'REGISTER_ADMIN_FAILURE',

    ADMIN_EDIT_REQUEST : 'ADMIN_EDIT_REQUEST',
    ADMIN_EDIT_SUCCESS : 'ADMIN_EDIT_SUCCESS',
    ADMIN_EDIT_FAILURE : 'ADMIN_EDIT_FAILURE',

    FETCH_USERS_REQUEST : 'FETCH_USERS_REQUEST',
    FETCH_USERS_SUCCESS : 'FETCH_USERS_SUCCESS',
    FETCH_USERS_FAILURE : 'FETCH_USERS_FAILURE',

    REGISTER_USER_REQUEST : 'REGISTER_USER_REQUEST',
    REGISTER_USER_SUCCESS : 'REGISTER_USER_SUCCESS',
    REGISTER_USER_FAILURE : 'REGISTER_USER_FAILURE',

    USER_EDIT_REQUEST : 'USER_EDIT_REQUEST',
    USER_EDIT_SUCCESS : 'USER_EDIT_SUCCESS',
    USER_EDIT_FAILURE : 'USER_EDIT_FAILURE',

    USER_UPDATE_REQUEST : 'USER_UPDATE_REQUEST',
    USER_UPDATE_SUCCESS : 'USER_UPDATE_SUCCESS',
    USER_UPDATE_FAILURE : 'USER_UPDATE_FAILURE',

    USER_DELETE_REQUEST: 'USER_DELETE_REQUEST',
    USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
    USER_DELETE_FAILURE: 'USER_DELETE_FAILURE',

    ADMIN_EDIT_SUBMIT_REQUEST : 'ADMIN_EDIT_SUBMIT_REQUEST',
    ADMIN_EDIT_SUBMIT_SUCCESS : 'ADMIN_EDIT_SUBMIT_SUCCESS',
    ADMIN_EDIT_SUBMIT_FAILURE : 'ADMIN_EDIT_SUBMIT_FAILURE',

    ADMIN_DELETE_REQUEST: 'ADMIN_DELETE_REQUEST',
    ADMIN_DELETE_SUCCESS: 'ADMIN_DELETE_SUCCESS',
    ADMIN_DELETE_FAILURE: 'ADMIN_DELETE_FAILURE',

    UPDATE_PASSWORD_REQUEST : 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS : 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE : 'UPDATE_PASSWORD_FAILURE',

    FETCH_MANAGEMENT_UNIT_REQUEST : 'FETCH_MANAGEMENT_UNIT_REQUEST',
    FETCH_MANAGEMENT_UNIT_SUCCESS : 'FETCH_MANAGEMENT_UNIT_SUCCESS',
    FETCH_MANAGEMENT_UNIT_FAILURE : 'FETCH_MANAGEMENT_UNIT_FAILURE',

    REGISTER_COMPANY_REQUEST : 'REGISTER_COMPANY_REQUEST',
    REGISTER_COMPANY_SUCCESS : 'REGISTER_COMPANY_SUCCESS',
    REGISTER_COMPANY_FAILURE : 'REGISTER_COMPANY_FAILURE',

    COMPANY_EDIT_REQUEST : 'COMPANY_EDIT_REQUEST',
    COMPANY_EDIT_SUCCESS : 'COMPANY_EDIT_SUCCESS',
    COMPANY_EDIT_FAILURE : 'COMPANY_EDIT_FAILURE',

    COMPANY_DELETE_REQUEST : 'COMPANY_EDIT_REQUEST',
    COMPANY_DELETE_SUCCESS : 'COMPANY_EDIT_SUCCESS',
    COMPANY_DELETE_FAILURE : 'COMPANY_EDIT_FAILURE',

    REGISTER_FACILITY_REQUEST : 'REGISTER_FACILITY_REQUEST',
    REGISTER_FACILITY_SUCCESS : 'REGISTER_FACILITY_SUCCESS',
    REGISTER_FACILITY_FAILURE : 'REGISTER_FACILITY_FAILURE',

    FETCH_FACILITY_REQUEST : 'FETCH_FACILITY_REQUEST',
    FETCH_FACILITY_SUCCESS : 'FETCH_FACILITY_SUCCESS',
    FETCH_FACILITY_FAILURE : 'FETCH_FACILITY_FAILURE',

    REPLACE_FACILITY_REQUEST : 'REPLACE_FACILITY_REQUEST',
    REPLACE_FACILITY_SUCCESS : 'REPLACE_FACILITY_SUCCESS',
    REPLACE_FACILITY_FAILURE : 'REPLACE_FACILITY_FAILURE',

    FACILITY_EDIT_REQUEST : 'FACILITY_EDIT_REQUEST',
    FACILITY_EDIT_SUCCESS : 'FACILITY_EDIT_SUCCESS',
    FACILITY_EDIT_FAILURE : 'FACILITY_EDIT_FAILURE',

    FACILITY_UPDATE_REQUEST : 'FACILITY_UPDATE_REQUEST',
    FACILITY_UPDATE_SUCCESS : 'FACILITY_UPDATE_SUCCESS',
    FACILITY_UPDATE_FAILURE : 'FACILITY_UPDATE_FAILURE',

    FACILITY_DELETE_REQUEST : 'FACILITY_DELETE_REQUEST',
    FACILITY_DELETE_SUCCESS : 'FACILITY_DELETE_SUCCESS',
    FACILITY_DELETE_FAILURE : 'FACILITY_DELETE_FAILURE',

    CHECK_GAUGE_DATA_REQUEST : 'CHECK_GAUGE_DATA_REQUEST',
    CHECK_GAUGE_DATA_SUCCESS : 'CHECK_GAUGE_DATA_SUCCESS',
    CHECK_GAUGE_DATA_FAILURE : 'CHECK_GAUGE_DATA_FAILURE',

    SET_MENU_DATA: 'SET_MENU_DATA',
    UPDATE_MENU: 'UPDATE_MENU',
    UPDATE_COMPANY_IN_MENU: 'UPDATE_COMPANY_IN_MENU',

    FETCH_CAMERA_LIST_REQUEST : 'FETCH_CAMERA_LIST_REQUEST',
    FETCH_CAMERA_LIST_SUCCESS : 'FETCH_CAMERA_LIST_SUCCESS',
    FETCH_CAMERA_LIST_FAILURE : 'FETCH_CAMERA_LIST_FAILURE',

    REGISTER_CAMERA_REQUEST : 'REGISTER_CAMERA_REQUEST',
    REGISTER_CAMERA_SUCCESS : 'REGISTER_CAMERA_SUCCESS',
    REGISTER_CAMERA_FAILURE : 'REGISTER_CAMERA_FAILURE',

    CAMERA_EDIT_REQUEST : 'CAMERA_EDIT_REQUEST',
    CAMERA_EDIT_SUCCESS : 'CAMERA_EDIT_SUCCESS',
    CAMERA_EDIT_FAILURE : 'CAMERA_EDIT_FAILURE',

    CAMERA_EDIT_SUBMIT_REQUEST : 'CAMERA_EDIT_SUBMIT_REQUEST',
    CAMERA_EDIT_SUBMIT_SUCCESS : 'CAMERA_EDIT_SUBMIT_SUCCESS',
    CAMERA_EDIT_SUBMIT_FAILURE : 'CAMERA_EDIT_SUBMIT_FAILURE',

    FETCH_GET_GAUGE_DATA_REQUEST : 'FETCH_GET_GAUGE_DATA_REQUEST',
    FETCH_GET_GAUGE_DATA_SUCCESS : 'FETCH_GET_GAUGE_DATA_SUCCESS',
    FETCH_GET_GAUGE_DATA_FAILURE : 'FETCH_GET_GAUGE_DATA_FAILURE',


    FETCH_CAMERA_INFO_REQUEST: 'FETCH_CAMERA_INFO_REQUEST',
    FETCH_CAMERA_INFO_SUCCESS: 'FETCH_CAMERA_INFO_SUCCESS',
    FETCH_CAMERA_INFO_FAILURE: 'FETCH_CAMERA_INFO_FAILURE',
    CLEAR_CAMERA_INFO: 'CLEAR_CAMERA_INFO',


    FETCH_CAMERA_CHANGE_DATA_REQUEST : 'FETCH_CAMERA_CHANGE_DATA_REQUEST',
    FETCH_CAMERA_CHANGE_DATA_SUCCESS : 'FETCH_CAMERA_CHANGE_DATA_SUCCESS',
    FETCH_CAMERA_CHANGE_DATA_FAILURE : 'FETCH_CAMERA_CHANGE_DATA_FAILURE',
    
    CAMERA_DELETE_REQUEST: 'CAMERA_DELETE_REQUEST',
    CAMERA_DELETE_SUCCESS: 'CAMERA_DELETE_SUCCESS',
    CAMERA_DELETE_FAILURE: 'CAMERA_DELETE_FAILURE',

    CAMERA_ADDPHOTO_AUTO_REQUEST: 'CAMERA_ADDPHOTO_AUTO_REQUEST',
    CAMERA_ADDPHOTO_AUTO_SUCCESS: 'CAMERA_ADDPHOTO_AUTO_SUCCESS',
    CAMERA_ADDPHOTO_AUTO_FAILURE: 'CAMERA_ADDPHOTO_AUTO_FAILURE',

    CAMERA_ADDPHOTO_MANUAL_REQUEST: 'CAMERA_ADDPHOTO_MANUAL_REQUEST',
    CAMERA_ADDPHOTO_MANUAL_SUCCESS: 'CAMERA_ADDPHOTO_MANUAL_SUCCESS',
    CAMERA_ADDPHOTO_MANUAL_FAILURE: 'CAMERA_ADDPHOTO_MANUAL_FAILURE',

    REMOVE_GAUGE_REQUEST: 'REMOVE_GAUGE_REQUEST',
    REMOVE_GAUGE_SUCCESS: 'REMOVE_GAUGE_SUCCESS',
    REMOVE_GAUGE_FAILURE: 'REMOVE_GAUGE_FAILURE',

    UPDATE_GAUGE_REQUEST: "UPDATE_GAUGE_REQUEST",
    UPDATE_GAUGE_SUCCESS: "UPDATE_GAUGE_SUCCESS",
    UPDATE_GAUGE_FAILURE: "UPDATE_GAUGE_FAILURE",

    FETCH_MAIL_REQUEST : 'FETCH_MAIL_REQUEST',
    FETCH_MAIL_SUCCESS : 'FETCH_MAIL_SUCCESS',
    FETCH_MAIL_FAILURE : 'FETCH_MAIL_FAILURE',

    FETCH_DELETEDLOG_REQUEST :'FETCH_DELETEDLOG_REQUEST',
    FETCH_DELETEDLOG_SUCCESS :'FETCH_DELETEDLOG_SUCCESS',
    FETCH_DELETEDLOG_FAILURE :'FETCH_DELETEDLOG_FAILURE',

    FETCH_MAINTENANCE_REQUEST : 'FETCH_MAINTENANCE_REQUEST',
    FETCH_MAINTENANCE_SUCCESS : 'FETCH_MAINTENANCE_SUCCESS',
    FETCH_MAINTENANCE_FAILURE :'FETCH_MAINTENANCE_FAILURE',

    MAINTENANCE_DELETE_REQUEST : 'MAINTENANCE_DELETE_REQUEST',
    MAINTENANCE_DELETE_SUCCESS : 'MAINTENANCE_DELETE_SUCCESS',
    MAINTENANCE_DELETE_FAILURE : 'MAINTENANCE_DELETE_FAILURE',

    REGISTER_MAINTENANCE_REQUEST : 'REGISTER_MAINTENANCE_REQUEST',
    REGISTER_MAINTENANCE_SUCCESS : 'REGISTER_MAINTENANCE_SUCCESS',
    REGISTER_MAINTENANCE_FAILURE : 'REGISTER_MAINTENANCE_FAILURE',

    MAINTENANCE_UPDATE_REQUEST : 'MAINTENANCE_UPDATE_REQUEST',
    MAINTENANCE_UPDATE_SUCCESS : 'MAINTENANCE_UPDATE_SUCCESS',
    MAINTENANCE_UPDATE_FAILURE : 'MAINTENANCE_UPDATE_FAILURE',

    FETCH_DELETELOGEDIT_REQUEST : 'FETCH_DELETELOGEDIT_REQUEST',
    FETCH_DELETELOGEDIT_SUCCESS : 'FETCH_DELETELOGEDIT_SUCCESS',
    FETCH_DELETELOGEDIT_FAILURE : 'FETCH_DELETELOGEDIT_FAILURE',
        
    MAIL_EDIT_REQUEST : 'MAIL_EDIT_REQUEST',
    MAIL_EDIT_SUCCESS : 'MAIL_EDIT_SUCCESS',
    MAIL_EDIT_FAILURE : 'MAIL_EDIT_FAILURE',
    
    MAIL_UPDATE_REQUEST : 'MAIL_UPDATE_REQUEST',
    MAIL_UPDATE_SUCCESS: 'MAIL_UPDATE_SUCCESS',
    MAIL_UPDATE_FAILURE : 'MAIL_UPDATE_FAILURE',

    FETCH_DELETEDLOGEDIT_REQUEST : 'FETCH_DELETEDLOGEDIT_REQUEST',
    FETCH_DELETEDLOGEDIT_SUCCESS : 'FETCH_DELETEDLOGEDIT_SUCCESS',
    FETCH_DELETEDLOGEDIT_FAILURE : 'FETCH_DELETEDLOGEDIT_FAILURE',

    FETCH_COMPANYNEW_REQUEST : 'FETCH_TEST_REQUEST',
    FETCH_COMPANYNEW_SUCCESS : 'FETCH_TEST_SUCCESS',
    FETCH_COMPANYNEW_FAILURE : 'FETCH_TEST_FAILURE',

    FETCH_DELETEDLOGEDITMSG_REQUEST : 'FETCH_DELETEDLOGEDITMSG_REQUEST',
    FETCH_DELETEDLOGEDITMSG_SUCCESS : 'FETCH_DELETEDLOGEDITMSG_SUCCESS',
    FETCH_DELETEDLOGEDITMSG_FAILURE : 'FETCH_DELETEDLOGEDITMSG_FAILURE',

    INCREMENT_OTP_ATTEMPT : 'INCREMENT_OTP_ATTEMPT',

};
