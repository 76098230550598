import ActionTypes from '../constants/ActionTypes'; 

const initialState = {
    loading: false,
    error: null,
    loginId: '', 
    userType: ''
};

const changePasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UPDATE_PASSWORD_REQUEST:
            return { ...state, loading: true, error: null };
        case ActionTypes.UPDATE_PASSWORD_SUCCESS:
            return { ...state, loading: false, message: action.payload, error: null };
        case ActionTypes.UPDATE_PASSWORD_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default changePasswordReducer;
