import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './AdminList.css';


const AdminList = ({ admins = [], loading, error, fetchAdmins, alldatanumber }) => {
    const itemsPerPage = 20;
    const location = useLocation();
    const navigate = useNavigate();
    const [adminsList, setAdminsList] = useState([{}]);
    // const [pageindex, setPageIdx] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(alldatanumber / itemsPerPage);
    const [isLoading, setisLoading] = useState(true);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const page = parseInt(query.get('page'), 10) || 1;  
        setCurrentPage(page);
        fetchAdmins(page, itemsPerPage)
            .finally(() => setisLoading(false));
    }, [fetchAdmins, itemsPerPage, location.search]); 

    useEffect(() => {
        if (admins) {
            setAdminsList(admins);
        }
    }, [admins]);

    const handleButtonClick = () => {
        navigate('/AdminNew');
    };
    

    const handleEditAdmin = useCallback((admin_id) => {
        navigate(`/AdminEdit?admin_id=${admin_id}&page=${currentPage}`);
    }, [navigate, currentPage]);

    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

        return (
            <div className="pager">
                <ul className="pagination">
                    {pages.map(page => (
                        <li key={page} className="page-item">
                        <div onClick={() => onPageChange(page)} className={`${currentPage === page ? 'active' : ''}`}>
                            <span>{page}</span>
                        </div>
                    </li>
                    
                    ))}
                </ul>
            </div>
        );
    };

    const handleMenuClick = (page) => {
        if (page === currentPage) {
            // 現在のページなら、再読み込みします。
            fetchAdmins(page, itemsPerPage);
        }
        navigate(`?page=${page}`); 
        setCurrentPage(page); 
    };

    const handlePageChange = (page) => {
        // navigate(`?page=${page}`);
        handleMenuClick(page);
        // fetchAdmins(page, itemsPerPage);
    };

    return (
        <div id='Main' className='MainMail'  >
           <div id='Contents' className='ContentsMail'>
                <div id="ajax-view-disp">
                    {/*管理者一覧 */}
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>管理者一覧</span>
                                </div>
                                <div className="lay-master-set__frame" >
                                    <div className="lay-master__table">
                                        {/* <div className="get_api_management" style={{ float: 'left' }}> */}
                                        <button type="button" id="" className='mod-btn__roundborderNew' onClick={handleButtonClick}>
                                            新規作成
                                        </button>
                                        {/* </div> */}
                                        {/* テーブル */}
                                        <form id="AdminList"style={{ marginTop: '15px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-admin-cot1">ログインID</th>
                                                        <th className="col-admin-cot2">ユーザ名</th>
                                                        <th className="col-admin-cot3">LilzGauge <br/> APIトークン登録</th>
                                                        <th className="col-admin-cot4">LilzGauge<br/> APIトークン登録日</th>
                                                        <th className="col-admin-cot5">編集</th>
                                                    </tr>
                                                </thead>
                                                {!isLoading && (
                                                    <tbody>
                                                        {adminsList.length > 0 ? (
                                                            adminsList.map((admin, index) => (
                                                                <tr key={index} className="lay-tr">
                                                                    <td className="col-admin-cot1" style={{ textAlign: 'left' }} >{admin.login_id}</td>
                                                                    <td className="col-admin-cot2" style={{ textAlign: 'left' }} >{admin.admin_name}</td>
                                                                    <td className="col-admin-cot3" style={{ textAlign: 'left' }} >{admin.lilz_gauge_token ? '有' : '無'}</td>
                                                                    <td className="col-admin-cot4">{admin.lilz_gauge_token_date}</td>
                                                                    <td className="col-admin-cot5">
                                                                        <button
                                                                            type="button"
                                                                            id={`admin_info_edit_${index}`}
                                                                            className="mod-btn__companylist"
                                                                            onClick={() => handleEditAdmin(admin.admin_id)}
                                                                        > 編集
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                        <tr>
                                                            {/*データが利用できない場合の表示 */}
                                                            <td colSpan="5" style={{ textAlign: "center" }}>
                                                                <div className="mod-no-data">表示データが存在しません。</div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                    </tbody>
                                                )}
                                            </table>
                                        </form>
                                        {totalPages > 1 && (
                                            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                                        )}                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AdminList.propTypes = {
    admins: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchAdmins: PropTypes.func.isRequired
};

export default React.memo(AdminList);