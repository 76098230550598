import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './UserList.css';
import Modal from '../Modal/Modal';

//ユーザ権限
const AUTHORITY_CLASS = {
    0: "一般",
    1: "管理者（一般）",
    9: "管理者（システム）",
};

const UserList = ({ users = [], loading, error, fetchUsers, alldatanumber }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [usersList, seUsersList] = useState(['']);
    const [search, setSearch] = useState({ company_code: '', company_name: '', login_id: '', user_name:''});
    const itemsPerPage = 20;
    // const [pageindex, setPageIdx] = useState(1);
    const [pageindex] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    // const [modalMessage, setModalMessage] = useState('');
    const [modalMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(alldatanumber / itemsPerPage);
    const [isLoading, setisLoading] = useState(true);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const page = parseInt(query.get('page'), 10) || 1;
        setCurrentPage(page);
        setisLoading(true);
        setSearch({ company_code: '', company_name: '',login_id :'' ,user_name : '' });
        fetchUsers(page, itemsPerPage)
            .finally(() => setisLoading(false));
    }, [fetchUsers,itemsPerPage,location.search]); 
    
    useEffect(() => {
        if (users) {
            seUsersList(users);
        }
    }, [users]);

    const handleButtonClick = () => {
        navigate('/UserNew');
    };

    const handleEditUser = useCallback((user_id) => {
        navigate(`/UserEdit?user_id=${user_id}&page=${currentPage}`);
    }, [navigate, currentPage]);

    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };

    const handleSearchSubmit = (event) => {
        if (event) event.preventDefault();

        fetchUsers(pageindex, itemsPerPage, search.company_code, search.company_name, search.login_id, search.user_name);
    };

    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
        // ページネーションインターフェイスをレンダリングします
        return (
            <div className="pager">
                <ul className="pagination">
                    {pages.map(page => (
                        <li key={page} className="page-item">
                            <div onClick={() => onPageChange(page)} className={`${currentPage === page ? 'active' : ''}`}>
                                <span>{page}</span>
                            </div>
                        </li>

                    ))}
                </ul>
            </div>
        );
    };

    const handMenuClick = (page) => {
        if (page === currentPage) {
            fetchUsers(page, itemsPerPage, search.company_code, search.company_name, search.login_id, search.user_name);
        }
        navigate (`?page=${page}`);
        setCurrentPage(page);
    }


    const handlePageChange = (page) => {
        // setCurrentPage(page);
        // fetchUsers(page, itemsPerPage, search.company_code, search.company_name, search.login_id, search.user_name);
        handMenuClick(page);
    };
    


    return (
        <div id='Main' className='MainMail'>
            <div id='Contents' className='ContentsMail'>
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage} />
                )}
                <div id="ajax-view-disp">
                    {/* 管理者一覧 */}
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>ユーザ一覧</span>
                                </div>
                                <form onSubmit={handleSearchSubmit} style={{ marginTop: '20px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="mod-form-graph__set d-none">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>

                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>ログインID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="login_id" name="login_id" value={search.login_id} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>ユーザ名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="user_name" name="user_name" value={search.user_name} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame" style={{ marginTop: '20px' }}>
                                    <div className="lay-master__table">

                                        <button type="button" id="" className='mod-btn__roundborderNew' onClick={handleButtonClick}>
                                            新規作成
                                        </button>
                                       
                                        <form id="UserList" style={{ marginTop: '15px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-user-cot1">組織名</th>
                                                        <th className="col-user-cot2">ログインID</th>
                                                        <th className="col-user-cot3">ユーザ名</th>
                                                        <th className="col-user-cot4">設定権限	</th>
                                                        <th className="col-user-cot5">編集</th>
                                                    </tr>
                                                </thead>
                                                {!isLoading && (
                                                    <tbody>
                                                        {usersList.length > 0 ? (
                                                            usersList.map((user, index) => (
                                                                <tr key={index} className={user.is_delete === 2 ? 'disabled' : ''}>
                                                                    <td className="col-user-cot1" style={{ textAlign: 'left' }} >{user.company_name}</td>
                                                                    <td className="col-user-cot2" style={{ textAlign: 'left' }} >{user.login_id}</td>
                                                                    <td className="col-user-cot3" style={{ textAlign: 'left' }} >{user.user_name}</td>
                                                                    <td className="col-user-cot4">{AUTHORITY_CLASS[user.authority_class] || ""}</td>
                                                                    <td className="col-user-cot5">
                                                                        <button
                                                                            type="button"
                                                                            id={`admin_info_edit_${index}`}
                                                                            className="mod-btn__companylist"
                                                                            onClick={() => handleEditUser(user.user_id)}
                                                                        > 編集
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                {/*データが利用できない場合の表示 */}
                                                                <td colSpan="5" style={{ textAlign: "center" }}>
                                                                    <div className="mod-no-data">表示データが存在しません。</div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                )}
                                            </table>
                                        </form>
                                        
                                        {totalPages > 1 && (
                                            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                                        )}                                       
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

UserList.propTypes = {
    users: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchUsers: PropTypes.func.isRequired
};

export default React.memo(UserList);