import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DeletedLogList from "../components/DeletedLogList";
import { fetchDeletedLog } from "../actions/deletedloglist";

const mapStateToProps = (state) => {

  return {
    deletedLog: state.deletedLoglist.deletedLog,
    loading: state.deletedLoglist.loading,
    error: state.deletedLoglist.error,
    alldatanumber: state.deletedLoglist.alldatanumber

  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchDeletedLog
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DeletedLogList);