import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './FacilityNew.css';
import Modal from '../Modal/Modal';
import CompanySearch from '../CompanySearch/CompanySearch';

//コンポーネント FacilityNew は施設の登録に使用されます
const FacilityNew = ({registerFacility,getManagementCompanyInfo, loading, error,  companyinfo = {} }) => {
    // const location = useLocation();
    const navigate = useNavigate();
    const [facility_name, setFacilityName] = useState('');
    const [facility_id, setFacilityId] = useState('');
    const [company_name, setCompany_name] = useState('');
    const [company_id, setCompany_id] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        getManagementCompanyInfo();
    }, [getManagementCompanyInfo]);

    useEffect(() => {
        if (Object.keys(companyinfo).length > 0) {
            setCompany_name(companyinfo.company_name);
            setCompany_id(companyinfo.company_id);
        }
        
    }, [companyinfo]);
    
    //処理は前のページに戻ります
    const handleBackClick = () => {
        navigate(-1);
    };
    
    //新規施設登録の処理中
    const handleRegister = async () => {
        try {
            const payload = {
                "facility_name": facility_name,
                "company_id": company_id,
            };
            const response = await registerFacility(payload);
            setModalMessage(response?.message || "登録しました。");
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage("登録に失敗しました。");
            setIsModalOpen(true);
        }
    };

    //企業検索ポップアップを開く
    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    //企業検索ポップアップを閉じる
    const handleClosePopup = () => {
        setShowPopup(false);
    };
     
    //ポップアップからの会社の選択を処理します
    const handleSelect = (item, id) => {
        setCompany_name(item); 
        setCompany_id(id);
    };

    return (
        <div id='Main' className='MainEdit' >
            <div id='Contents'className='ContentEdit' >
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                            navigate(-1); 
                        }}
                        message={modalMessage}
                    />
                )}
                {showPopup && (<CompanySearch onClose={handleClosePopup} onSelect={handleSelect} />)}
                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>施設マスタ新規登録</span>
                                </div>
                                <div className="lay-master-set__form">
                                    <form id="FacilityNew">
                                        <div className="wrapper" style={{ textAlign: 'left' }}>
                                            <div className="mail-send__set">
                                                <div className="mail-input">
                                                    <div style={{ width: '130%', marginTop: '20px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className='add-btn'>
                                                                <button type="button" className="mod-btn__roundborder add" onClick={handleRegister}>
                                                                登録
                                                                </button>
                                                            </div>
                                                            <div className='back-btn'>
                                                                <button type="button" className="mod-btn__adminback back" onClick={handleBackClick}>
                                                                    戻る
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mod-form__set">
                                                        <dl style={{ marginTop: '40px' }}>
                                                            <dt>登録組織</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input className='readonlyCls' type="text" id="company_name" name="company_name" value={company_name} onChange={(e) => setCompany_name(e.target.value)} readOnly/>
                                                                </div>
                                                            </dd>
                                                            <button type="button" className="mod-btn__company_option" style={{ marginLeft: '10px' }} onClick={handleOpenPopup} >
                                                                組織検索
                                                            </button>
                                                        </dl>
                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>施設ID</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input className='readonlyCls' type="text" id="facility_id" name="facility_id" value={facility_id} onChange={(e) => setFacilityId(e.target.value)} readOnly/>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>施設名</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input type="text" id="facility_name" name="facility_name" value={facility_name} onChange={(e) => setFacilityName(e.target.value)} autoComplete="off"/>
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

//小道具のデータ型を確認する
FacilityNew.propTypes = {
    accessToken: PropTypes.string,
    registerFacility: PropTypes.func.isRequired,
    getManagementCompanyInfo: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
};

export default React.memo(FacilityNew);