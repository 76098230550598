// index.js in the 'reducers' directory

import { combineReducers } from 'redux';
import loginReducer from './login';
import adminListReducer from './adminListReducer';
import adminNewReducer from './adminNewReducer';
import companySearchReducer from './companySearchReducer';
import adminEditReducer from './adminEditReducer';
import changePasswordReducer from './changePasswordReducer';
import companyListReducer from  './companyListReducer';
import companyEditReducer from './companyEditReducer';
import userListReducer from './userListReducer';
import userNewReducer from './userNewReducer';
import userEditReducer from './userEditReducer';
import cameraListReducer from './cameraListReducer'
import cameraChangeReducer from './cameraChangeReducer';
import facilityNewReducer from './facilityNewReducer';
import facilityListReducer from './facilityListReducer';
import facilityEditReducer from './facilityEditReducer';
import mailListReducer from './mailListReducer';
import maileditReducer from './maileditReducer';
import maintenancelistReducer from './maintenancelistReducer';
import maintenancenewReducer from './maintenancenewReducer';
import deletedloglistReducer from './deletedloglistReducer';
import deletedlogeditReducer from './deletedlogeditReducer';

const rootReducer = combineReducers({
  login: loginReducer,
  adminList: adminListReducer,
  adminNew: adminNewReducer,
  adminEdit: adminEditReducer,
  facilityNew: facilityNewReducer,
  facilityList: facilityListReducer,
  facilityEdit: facilityEditReducer,
  mailList: mailListReducer,
  mailSend: maileditReducer,
  maintenanceList: maintenancelistReducer,
  maintenancenew: maintenancenewReducer,
  deletedLoglist: deletedloglistReducer,
  deletedlogedit: deletedlogeditReducer,
  companySearch: companySearchReducer,
  changePassword: changePasswordReducer,
  companyList: companyListReducer,
  companyEdit: companyEditReducer,
  cameraList: cameraListReducer,
  cameraChange: cameraChangeReducer,
  userList: userListReducer,
  userNew: userNewReducer,
  userEdit: userEditReducer
  
});

export default rootReducer;
