import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchCompanyRequest = () => ({
    type: ActionTypes.FETCH_CAMERA_LIST_REQUEST
});

export const fetchCompanySuccess = (cameras) => ({
    type: ActionTypes.FETCH_CAMERA_LIST_SUCCESS,
    payload: cameras
});

export const fetchCompanyFailure = (error) => ({
    type: ActionTypes.FETCH_CAMERA_LIST_FAILURE,
    payload: error
});


export const fetchCameras = (pageindex, itemsPerPage, company_id, company_name, facility_id, facility_name,device_id,camera_name) => {
    return async (dispatch) => {
        dispatch(fetchCompanyRequest());
        try {
            if (!window.debug) {
                let strFilter = "";
                if (company_id) {
                    strFilter += `&company_id=${company_id}`;
                }
                if (company_name) {
                    strFilter += `&company_name=${company_name}`;
                }
                if (facility_id) {
                    strFilter += `&falicity_id=${facility_id}`;
                }
                if (facility_name) {
                    strFilter += `&facility_name=${facility_name}`;
                }
                if (device_id) {
                    strFilter += `&device_id=${device_id}`;
                }
                if (camera_name) {
                    strFilter += `&camera_name=${camera_name}`;
                }
                const accessToken = localStorage.getItem('accessTokenCompany');
                const response = await axiosInstance.get(BASE_URL + `path=camera&type=get-list-data&page=${pageindex}&number_per_page=${itemsPerPage}` + strFilter, 
                    {
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                        }
                    });

                    const data = response.data.respons || {"data_list": [], "all_data_count":0};
                    dispatch(fetchCompanySuccess(data));
            }
            else {
                const data = [{
                    "device_id": "2468800051800111",
                    "camera_name": "2468800051800111",
                    "display_name": "施設１	",
                    // "facility_id": %s,
                    "facility_name": "施設１",
                    // "company_id": %s,
                    // "company_code": %s,
                    "company_name": "MU28_ウォーターエージェンシー",
                    "deleted_row": 0,
                    "gauge_count": 1
                },
                {
                    "device_id": "2468800051800111",
                    "camera_name": "2468800051800111",
                    "display_name": "施設2	",
                    // "facility_id": %s,
                    "facility_name": "施設2",
                    // "company_id": %s,
                    // "company_code": %s,
                    "company_name": "MU28_ウォーターエージェンシー",
                    "deleted_row": 0,
                    "gauge_count": 1
                }
                
                ];
                dispatch(fetchCompanySuccess(data));
            }
        } catch (error) {
            dispatch(fetchCompanyFailure(error.message));
        }
    };
};


