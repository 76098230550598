import ActionTypes from "../constants/ActionTypes";
// import message from "../constants/message";

const initialState = {
  loading: false,
  companyinfo: {},
  error: null,
};
const adminNewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REGISTER_ADMIN_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.REGISTER_ADMIN_SUCCESS:
      return { ...state, loading: false, message: action.payload };
    case ActionTypes.REGISTER_ADMIN_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.FETCH_MANAGE_COMPANYINFO_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_MANAGE_COMPANYINFO_SUCCESS:
      return { ...state, loading: false, companyinfo: action.payload };
    case ActionTypes.FETCH_MANAGE_COMPANYINFO_FAILURE:
      return { ...state, loading: false, message: action.payload };
    default:
      return state;
  }
};

export default adminNewReducer;
