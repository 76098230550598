import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";
 
//アクションにはベース編集情報をロードする必要があります
export const fetchFacilityEditRequest = () => ({
    type: ActionTypes.FACILITY_EDIT_REQUEST
});

//基本編集情報のロード時にアクションが成功しました
export const fetchFacilityEditSuccess = (admin) => ({
    type: ActionTypes.FACILITY_EDIT_SUCCESS,
    payload: admin
});

//アクションはベース編集情報をロードできませんでした
export const fetchFacilityEditFailure = (error) => ({
    type: ActionTypes.FACILITY_EDIT_FAILURE,
    payload: error
});

//基本編集情報のロードを実行します
export const fetchFacilityEdit = (facility_id) => {
    return async (dispatch) => {
        dispatch(fetchFacilityEditRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + 'path=facility&type=get-data&id=' + facility_id, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || {};
                dispatch(fetchFacilityEditSuccess(data));
                return response;
            }
            else {
                const data = {
                    "facility_name": "test",
                    "facility_id": "3",
                    "company_id": 1,
                    "company_name": "長谷工コーポレーション",
                    "is_delete" :0,
                }
                dispatch(fetchFacilityEditSuccess(data));
                return data;
            }

        } catch (error) {
            dispatch(fetchFacilityEditFailure(error.message));
        }
    };
};

//削除依頼
export const fetchFacilityDeleteRequest = () => ({
    type: ActionTypes.FACILITY_DELETE_REQUEST
});

//アクションは削除に成功しました
export const fetchFacilityDeleteSuccess = (admin) => ({
    type: ActionTypes.FACILITY_DELETE_SUCCESS,
    payload: admin
});

//アクションの削除に失敗しました
export const fetchFacilityDeleteFailure = (error) => ({
    type: ActionTypes.FACILITY_DELETE_FAILURE,
    payload: error
});

//状態を削除または更新する関数
export const deleteFacility = (facility_id, facility_name, company_id, is_delete) => {
    return async (dispatch) => {
        dispatch(fetchFacilityDeleteRequest());
        const payload = {
            facility_id: facility_id,
            facility_name: facility_name,
            company_id: company_id,
            is_delete: is_delete,
        };

        try {
            const accessToken = localStorage.getItem('accessToken');
            const response = await axiosInstance.post(BASE_URL + 'path=facility&type=update-info-data', payload, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('API Response:', response);

            if (response.data.status === 200) {
                const message = "完全に削除しました。";
                dispatch(fetchFacilityDeleteSuccess(message));
                return message;
            }
        } catch (error) {
            console.error('API Call Error:', error);
            dispatch(fetchFacilityDeleteFailure(error.message));
        }
    };
};

//情報更新のリクエスト
export const updateFacilityRequest = () => ({
    type: ActionTypes.FACILITY_UPDATE_REQUEST,
});

//情報の更新時にアクションが成功しました
export const updateFacilitySuccess = (message) => ({
    type: ActionTypes.FACILITY_UPDATE_SUCCESS,
    payload: message,
});

//アクションで情報を更新できませんでした
export const updateFacilityFailure = (error) => ({
    type: ActionTypes.FACILITY_UPDATE_FAILURE,
    payload: error,
});

//ベース情報を更新する関数です
export const submitFacilityEdit = (facilitydata) => {
    return async (dispatch) => {
        dispatch(updateFacilityRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + 'path=facility&type=update-info-data', facilitydata, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                let message = response.data.respons;
                if (response.data.status === 200) {
                    message = "変更しました。"; 
                }
                dispatch(updateFacilitySuccess(message));
                return { message }; 
            } else {
                const msg = "変更しました。";
                // const response = { data: { respons: msg } };
                dispatch(updateFacilitySuccess(msg));
                return { message: msg };
            }
        } catch (error) {
            const message = error.response?.data?.respons || "通信エラーが発生しました。";
            dispatch(updateFacilitySuccess(message));
            return { message };
        }
    };
};


