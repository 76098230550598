import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './CameraList.css';

const CameraList = ({ fetchCameras, cameras = [], loading, error,alldatanumber}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const itemsPerPage = 20;
    const [pageindex] = useState(1);
    const [CameraList, setCameraList] = useState(['']);
    const [search, setSearch] = useState({ company_id: '', company_name: '', facility_id: '', facility_name: '' ,device_id:'',camera_name:''});
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(alldatanumber / itemsPerPage);
    const [isLoading, setisLoading] = useState(true);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const page = parseInt(query.get('page'), 10) || 1;
        // setPageIdx(page); 
        setCurrentPage(page); 
        setisLoading(true);
        setSearch({ company_code: '', company_name: '', facility_id : '' , facility_name : '' , device_id : '' , camera_name : '' });
        fetchCameras(page, itemsPerPage)
            .finally(() => setisLoading(false));
    }, [fetchCameras, itemsPerPage, location.search]);
    
    
    useEffect(() => {
        if (cameras) {
            setCameraList(cameras);
        }
    }, [cameras]);
    console.log("Updated CameraList:", CameraList);


    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };

    const handleSearchSubmit = (event) => {
        if (event) event.preventDefault();
        fetchCameras(pageindex, itemsPerPage, search.company_id, search.company_name, search.facility_id, search.facility_name,search.device_id, search.camera_name);
    };

    //ページネーションを表示。
    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
        // ページネーションインターフェイスをレンダリングします
        return (
            <div className="pager">
                <ul className="pagination">
                    {pages.map(page => (
                        <li key={page} className="page-item">
                        <div onClick={() => onPageChange(page)} className={`${currentPage === page ? 'active' : ''}`}>
                            <span>{page}</span>
                        </div>
                    </li>
                    
                    ))}
                </ul>
            </div>
        );
    };

    const handleMenuClick = (page) => {
        if (page === currentPage) {
            // 現在のページなら、再読み込みします。
            fetchCameras(page, itemsPerPage, search.company_id, search.company_name, search.facility_id, search.facility_name, search.device_id, search.camera_name);
        }
        navigate(`?page=${page}`); 
        setCurrentPage(page); 
    };
    
    //ページネーションでのページ変更イベントの処理
    const handlePageChange = (page) => {
        // navigate(`?page=${page}`);
        handleMenuClick(page);
        // fetchCameras(page, itemsPerPage, search.company_id, search.company_name, search.facility_id, search.facility_name,search.device_id, search.camera_name);
    };

    return (
        <div id='Main' className='MainMail' >
           <div id='Contents' className='ContentsMail'>
                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>カメラ一覧</span>
                                </div>

                                <form  onSubmit={handleSearchSubmit}  style={{ marginTop: '20px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_id" name="company_id" value={search.company_id || '' } onChange={handleSearchChange} autoComplete="off" />

                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name || ''} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="mod-form-graph__set d-none">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name || ''} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>施設ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="facility_id" name="facility_id" value={search.facility_id || ''} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>施設名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="facility_name" name="facility_name" value={search.facility_name|| ''} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="mod-form-graph__set d-none">
                                                    <dl>
                                                        <dt>施設名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="facility_name" name="facility_name" value={search.facility_name|| ''} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>デバイスID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="device_id" name="device_id" value={search.device_id|| ''} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>カメラ名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="camera_name" name="camera_name" value={search.camera_name|| ''} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                            <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame"style={{ marginTop: '20px' }}>
                                    <div className="lay-master__table">
                                        <form id="CameraList">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-camera-cot1">組織名</th>
                                                        <th className="col-camera-cot2">施設名</th>
                                                        <th className="col-camera-cot3">デバイスID</th>
                                                        <th className="col-camera-cot4">カメラ名</th>
                                                        <th className="col-camera-cot5">計器数</th>
                                                        <th className="col-camera-cot6">編集</th>
                                                    </tr>
                                                </thead>
                                                {!isLoading && (
                                                    <tbody>
                                                        {cameras.length > 0 ? (
                                                            cameras.map((val, index) => (
                                                                <tr key={index} className={val.is_delete === 1 ? 'disabled' : ''}>
                                                                    <td className="col-camera-cot1" style={{ textAlign: 'left' }} >{val.company_name}</td>
                                                                    <td className="col-camera-cot2" style={{ textAlign: 'left' }} >{val.facility_name}</td>
                                                                    <td className="col-camera-cot3" style={{ textAlign: 'left' }} >{val.device_id}</td>
                                                                    <td className="col-camera-cot4" style={{ textAlign: 'left' }} >{val.camera_name}</td>
                                                                    <td className="col-camera-cot5">{val.gauge_count}</td>
                                                                    <td className="col-camera-cot6">
                                                                        <button type="button" 
                                                                            id={`facility_info_edit_${index}`} 
                                                                            className="mod-btn__companylist"
                                                                            onClick={() => navigate(`/CameraChange?id=${val.device_id}&company_id=${val.company_id}&page=${currentPage}`)}
                                                                            > 編集
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                {/*データが利用できない場合の表示 */}
                                                                <td colSpan="6" style={{ textAlign: "center" }}>
                                                                    <div className="mod-no-data">表示データが存在しません。</div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                )}
                                            </table>
                                        </form>
                                        {totalPages > 1 && (
                                            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                                        )}                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CameraList.propTypes = {
    cameras: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchCameras: PropTypes.func.isRequired,
    alldatanumber: PropTypes.number.isRequired
};

export default React.memo(CameraList);