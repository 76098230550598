import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AdminEdit from "../components/AdminEdit";
import { fetchAdminEdit, deleteAdmin, submitAdminEdit} from "../actions/adminedit";

const mapStateToProps = (state) => {
  const { adminEdit } = state;
  return {
    admindata: adminEdit.admindata,
    loading: adminEdit.loading,
    error: adminEdit.error
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAdminEdit,
      deleteAdmin,
      submitAdminEdit
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdminEdit);