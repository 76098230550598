import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  maildata: {},
  error: null
};
const maileditReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.MAIL_EDIT_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.MAIL_EDIT_SUCCESS:
      return { ...state, loading: false, maildata: action.payload };
    case ActionTypes.MAIL_EDIT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.MAIL_UPDATE_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.MAIL_UPDATE_SUCCESS:
      return { ...state, loading: false};
    case ActionTypes.MAIL_UPDATE_FAILURE:
      return { ...state, loading: false};
    default:
      return state;
  }
};

export default maileditReducer;
