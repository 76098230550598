import ActionTypes from '../constants/ActionTypes';

const initialState = {
    loading: false,
    companydata: {},
    error: null,
};

const companyReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.COMPANY_EDIT_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.COMPANY_EDIT_SUCCESS:
            return { ...state, loading: false, companydata: action.payload };
        case ActionTypes.COMPANY_EDIT_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.REGISTER_COMPANY_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.REGISTER_COMPANY_SUCCESS:
            return { ...state, loading: false, message: action.payload };
        case ActionTypes.REGISTER_COMPANY_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default companyReducer;
