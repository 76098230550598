import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './CompanyList.css';
import Modal from '../Modal/Modal';

const CompanyList = ({ fetchCompanies, fetchManagementUnitData, companies = [], loading, error, alldatanumber }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [search, setSearch] = useState({ company_code: '', company_name: '' });
    const [companiesList, setCompaniesList] = useState(companies);
    const itemsPerPage = 20;
    const [pageindex] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(alldatanumber / itemsPerPage);
    const authorityClass = useSelector(state => state.login.user ? state.login.user.authorityClass : 0);
    const [isLoading, setisLoading] = useState(true);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const page = parseInt(query.get('page'), 10) || 1;  
        setCurrentPage(page);
        setisLoading(true);
        setSearch({ company_code: '', company_name: '' });
        fetchCompanies(page, itemsPerPage)
            .finally(() => setisLoading(false));
    }, [fetchCompanies,itemsPerPage,location.search]);

    useEffect(() => {
        if (companies.length > 0) {
            setCompaniesList(companies);
        }
    }, [companies]);

    const handleFetchManagementData = async () => {
        try {
            await fetchManagementUnitData();
            setModalMessage('取得しました。');
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error fetching management unit data:', error);
        }
    };

    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };

    const handleSearchSubmit = (event) => {
        if (event) event.preventDefault();
        fetchCompanies(pageindex, itemsPerPage, search.company_code, search.company_name)
    };

    const handleCompanyEdit = useCallback((company_id) => {
        navigate(`/CompanyEdit?company_id=${company_id}&page=${currentPage}`);
    }, [navigate, currentPage]);

    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

        return (
            <div className="pager">
                <ul className="pagination">
                    {pages.map(page => (
                        <li key={page} className="page-item">
                        <div onClick={() => onPageChange(page)} className={`${currentPage === page ? 'active' : ''}`}>
                            <span>{page}</span>
                        </div>
                    </li>
                    
                    ))}
                </ul>
            </div>
        );
    };

    const handMenuClick = (page) => {
        if(page === currentPage) {
            fetchCompanies(page,itemsPerPage, search.company_code, search.company_name);
        }
        navigate(`?page=${page}`);
        setCurrentPage(page);
    };

    const handlePageChange = (page) => {
        // setCurrentPage(page);
        // fetchCompanies(page, itemsPerPage, search.company_code, search.company_name);
        handMenuClick(page);
    };
    

    return (
        <div id='Main' className='MainMail'  >
           <div id='Contents'className='ContentsMail'>
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}/>
                )}
                <div id="ajax-view-disp">
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>組織一覧</span>
                                </div>

                                <form onSubmit={handleSearchSubmit} style={{ marginTop: '20px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>


                                            <li>
                                                <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame" style={{ marginTop: '20px' }}>
                                    <div className="lay-master__table">
                                        {authorityClass === 1 && (
                                            <button type="button" className='mod-btn__roundborderNew' onClick={handleFetchManagementData} > データ取得 </button>
                                        )} 
                                        <form id="CompanyList" style={{ marginTop: '15px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-company-cot1">組織ID</th>
                                                        <th className="col-company-cot2">組織名</th>
                                                        <th className="col-company-cot3">管理ユニットID</th>
                                                        <th className="col-company-cot4">管理ユニット名</th>
                                                        <th className="col-company-cot5">取得日</th>
                                                        <th className="col-company-cot6">編集</th>
                                                    </tr>
                                                </thead>
                                                {!isLoading && (
                                                    <tbody>
                                                        {companiesList.length > 0 ? (
                                                            companiesList.map((val, index) => (
                                                                <tr key={index} className={val.is_hidden === 1 ? 'disabled' : ''}>
                                                                    <td className="col-company-cot1" style={{ textAlign: 'left' }} >{val.company_code}</td>
                                                                    <td className="col-company-cot2" style={{ textAlign: 'left' }}>{val.company_name}</td>
                                                                    <td className="col-company-cot3" style={{ textAlign: 'left' }}>{val.manage_unit_id}</td>
                                                                    <td className="col-company-cot4" style={{ textAlign: 'left' }} >{val.manage_unit_name}</td>
                                                                    <td className="col-company-cot5">{val.create_date}</td>
                                                                    <td className="col-company-cot6">
                                                                        <button
                                                                            type="button"
                                                                            id={`messageEdit_btn${index}`}
                                                                            className="mod-btn__companylist"
                                                                            onClick={() => handleCompanyEdit(val.company_id)}
                                                                            > 編集
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                {/*データが利用できない場合の表示 */}
                                                                <td colSpan="6" style={{ textAlign: "center" }}>
                                                                    <div className="mod-no-data">表示データが存在しません。</div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                )}
                                            </table>
                                        </form>
                                        {totalPages > 1 && (
                                            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                                        )}                                        
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CompanyList.propTypes = {
    companies: PropTypes.array.isRequired,
    // managementUnitData: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchCompanies: PropTypes.func.isRequired,
    fetchManagementUnitData: PropTypes.func.isRequired
};

export default React.memo(CompanyList);