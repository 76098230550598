import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  companyinfo: {},
  data: [],
  accessToken: null,
  error: null,
  companies: []
};
const facilityNewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REGISTER_FACILITY_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.REGISTER_FACILITY_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case ActionTypes.REGISTER_FACILITY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.FETCH_COMPANIES_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_COMPANIES_SUCCESS:
      return { ...state, loading: false, companies: action.payload };
    case ActionTypes.FETCH_COMPANIES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.FETCH_MANAGE_COMPANYINFO_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_MANAGE_COMPANYINFO_SUCCESS:
      return { ...state, loading: false, companyinfo: action.payload };
    case ActionTypes.FETCH_MANAGE_COMPANYINFO_FAILURE:
      return { ...state, loading: false, message: action.payload };
    default:
      return state;
  }
};

export default facilityNewReducer;
