import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './CompanySearch.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompany } from '../../actions/companysearch';

function CompanySearch({ onSelect, onClose }) {
    const dispatch = useDispatch();
    const { data, alldatanumber = 0 } = useSelector((state) => state.companySearch || { data: [], loading: false, error: null });
    const [search, setSearch] = useState({ company_code: '', company_name: '' });
    const [filteredData, setFilteredData] = useState([]);
    const itemsPerPage = 20;
    const [pageindex, setPageIdx] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(alldatanumber / itemsPerPage);
    const [isLoading, setisLoading] = useState(true);

    useEffect(() => {
        dispatch(fetchCompany(pageindex, itemsPerPage)).finally(() => {
            setisLoading(false); 
        });
    }, [dispatch, pageindex, itemsPerPage]);
    

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    const handleSelect = (item, id) => {
        onSelect(item, id);
        onClose();
    };

    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };

    const handleFilterCompany = (event) => {
        event.preventDefault();
        dispatch(fetchCompany(pageindex, itemsPerPage, search.company_code, search.company_name));
    };
    
    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
        // ページネーションインターフェイスをレンダリングします
        return (
            <div className="pager">
                <ul className="pagination">
                    {pages.map(page => (
                        <li key={page} className="page-item">
                        <div onClick={() => onPageChange(page)} className={`${currentPage === page ? 'active' : ''}`}>
                            <span>{page}</span>
                        </div>
                    </li>
                    
                    ))}
                </ul>
            </div>
        );
    };

    //ページネーションでのページ変更イベントの処理
    const handlePageChange = (page) => {
        setPageIdx(page); 
        setCurrentPage(page);
        fetchCompany(page, itemsPerPage, search.company_code, search.company_name);
    };
    
    return (
        <div className="popup-wrapper-company">
            <div className="popup">
                <div className="popup-top">
                    <span>組織選択画面</span>
                    <button className="popup-close" onClick={onClose}>&times;</button>
                </div>
                <div className="popup-content">
                    <form onSubmit={handleFilterCompany}>
                        <div className="lay-search">
                            <div className="lay-search-wrap-company">
                                <div className="lay-master-set__title">
                                    <span>組織マスタ一覧</span>
                                </div>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>

                                            <li>
                                            <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>

                                    <div className="lay-master-set__frame">
                                        {isLoading ? (
                                            <div className="loading-spinner"></div>
                                        ) : (
                                            <div className="lay-master__table">
                                                <table style={{ width: "100%" }}>

                                                    <thead>
                                                        <tr>
                                                            <th className="col-companysearch-cot1">組織ID</th>
                                                            <th className="col-companysearch-cot2">組織名</th>
                                                            <th className="col-companysearch-cot3">選択</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        {filteredData.map((val, index) => (
                                                            <tr key={index} className="lay-tr">
                                                                <td className="col-companysearch-cot1">{val.company_code}</td>
                                                                <td className="col-companysearch-cot2">{val.company_name}</td>
                                                                <td className="col-companysearch-cot3">
                                                                    <button
                                                                        type="button" className="mod-btn__companylist"
                                                                        onClick={() => handleSelect(val.company_name, val.company_id)}
                                                                    >
                                                                        選択
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>

                                                {totalPages > 1 && (
                                                    <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                                                )}
                                            </div>
                                        )}
                                    </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

CompanySearch.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired
};

export default CompanySearch;
