import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

//削除済みリストを取得するリクエストを開始します
export const fetchDeletedLogRequest = () => ({
    type: ActionTypes.FETCH_DELETEDLOG_REQUEST,
});

//リストの受信時に正常に処理されました
export const fetchDeletedLogSuccess = (deletedLog) => ({
    type: ActionTypes.FETCH_DELETEDLOG_SUCCESS,
    payload: deletedLog,
});

//リスト取得時の失敗の処理
export const fetchDeletedLogFailure = (error) => ({
    type: ActionTypes.FETCH_DELETEDLOG_FAILURE,
    payload: error,
});

//この関数は削除されたレコードのリストをロードします。
export const fetchDeletedLog = (pageindex, itemsPerPage, company_code, company_name) => {
    return async (dispatch) => {
        dispatch(fetchDeletedLogRequest());
        try {
            if (!window.debug) {
                let strFilter = "";
                if (company_code) {
                    strFilter = `&company_code=${company_code}`;
                }
                if (company_name) {
                    strFilter = `&company_name=${company_name}`;
                }
                const accessToken = localStorage.getItem('accessTokenCompany');
                const response = await axiosInstance.get( BASE_URL + `path=company&type=get-list-data&page=${pageindex}&number_per_page=${itemsPerPage}${strFilter}&is_delete=1`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`, 
                    }
                });
                
                const data = response.data.respons || {"data_list": [], "all_data_count":0};
                dispatch(fetchDeletedLogSuccess(data));
            }
            else {
                const data = {"data_list": [{ "company_id": 1, 'company_code': 'nguyen_test', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 2, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456', 'manage_unit_name': '123456',  'update_date': '2020/04/13' },
                    { "company_id": 3, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 4, 'company_code': 'osaka_gas_facilities', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 5, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456', 'manage_unit_name': '123456',  'update_date': '2020/04/13' },
                    { "company_id": 6, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 7, 'company_code': 'osaka_gas_facilities', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 8, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456', 'manage_unit_name': '123456',  'update_date': '2020/04/13' },
                    { "company_id": 9, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 10, 'company_code': 'osaka_gas_facilities', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 11, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456', 'manage_unit_name': '123456',  'update_date': '2020/04/13' },
                    { "company_id": 12, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 13, 'company_code': 'osaka_gas_facilities', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 14, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456', 'manage_unit_name': '123456',  'update_date': '2020/04/13' },
                    { "company_id": 15, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 16, 'company_code': 'osaka_gas_facilities', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 17, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456', 'manage_unit_name': '123456',  'update_date': '2020/04/13' },
                    { "company_id": 18, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 19, 'company_code': 'osaka_gas_facilities', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 20, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456', 'manage_unit_name': '123456',  'update_date': '2020/04/13' },
                    { "company_id": 21, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 22, 'company_code': 'osaka_gas_facilities', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 23, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456', 'manage_unit_name': '123456',  'update_date': '2020/04/13' },
                    { "company_id": 24, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 25, 'company_code': 'osaka_gas_facilities', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 26, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456', 'manage_unit_name': '123456',  'update_date': '2020/04/13' },
                    { "company_id": 27, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 28, 'company_code': 'osaka_gas_facilities', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 29, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456', 'manage_unit_name': '123456',  'update_date': '2020/04/13' },
                    { "company_id": 30, 'company_code': 'kajima', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },
                    { "company_id": 31, 'company_code': 'osaka_gas_facilities', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' },

                    { "company_id": 5, 'company_code': 'osaka_gas_facilities', 'company_name': 'rakusuru@hakaru.jp', 'manage_unit_id': '123456',  'manage_unit_name': '123456', 'update_date': '2020/04/13' }
                    ], "all_data_count": 5};
                dispatch(fetchDeletedLogSuccess(data));
            }
        } catch (error) {
            dispatch(fetchDeletedLogFailure(error.message));
        }
    };
};
