// reducers/dataReducer.js
import ActionTypes from "../constants/ActionTypes";

const initialState = {
  data: [],
  loading: false,
  error: null,
  alldatanumber: 0

};

const companySearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_COMPANIES_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_COMPANIES_SUCCESS:
      return { ...state, loading: false, data: action.payload.data_list,alldatanumber: action.payload.all_data_count };
    case ActionTypes.FETCH_COMPANIES_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default companySearchReducer;
