import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FacilityEdit from "../components/FacilityEdit";
import { fetchFacilityEdit, deleteFacility, submitFacilityEdit} from "../actions/facilityedit";

const mapStateToProps = (state) => {
  const { facilityEdit } = state;
  return {
    facilitydata: facilityEdit.facilitydata,
    loading: facilityEdit.loading,
    error: facilityEdit.error
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchFacilityEdit,
      deleteFacility,
      submitFacilityEdit
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(FacilityEdit);