import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

// *************管理者新規作成******************
export const registerAdminRequest = () => ({
    type: ActionTypes.REGISTER_ADMIN_REQUEST
});

export const registerAdminSuccess = (message) => ({
    type: ActionTypes.REGISTER_ADMIN_SUCCESS,
    payload: message
});

export const registerAdminFailure = (error) => ({
    type: ActionTypes.REGISTER_ADMIN_FAILURE,
    payload: error
});

export const registerAdmin = (payload) => {
    return async (dispatch) => {
        dispatch(registerAdminRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + 'path=admin&type=insert-info-data', payload, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                let message = response.data.respons;
                if (response.data.status === 200) {
                    message = "登録しました。"; 
                }
                dispatch(registerAdminSuccess(message));
                return { message }; 
            } else {
                const msg = "登録しました。";
                // const response = { data: { respons: msg } };
                dispatch(registerAdminSuccess(msg));
                return { message: msg };
            }
        } catch (error) {
            const message = error.response?.data?.respons || "通信エラーが発生しました。";
            dispatch(registerAdminFailure(message));
            return { message };
        }
    };
};

export const getManageCompanyInfoRequest = () => ({
    type: ActionTypes.FETCH_MANAGE_COMPANYINFO_REQUEST
});

export const getManageCompanyInfoSuccess = (message) => ({
    type: ActionTypes.FETCH_MANAGE_COMPANYINFO_SUCCESS,
    payload: message
});

export const getManageCompanyInfoFailure = (error) => ({
    type: ActionTypes.FETCH_MANAGE_COMPANYINFO_FAILURE,
    payload: error
});

export const getManagementCompanyInfo = () => {
    return async (dispatch) => {
        dispatch(getManageCompanyInfoRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + 'path=company&type=get-data-login', {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });
                dispatch(getManageCompanyInfoSuccess(response.data.respons));
                return response;
            }
            else {
                const data = {
                    "admin_id": 1,
                    "company_id": 1,
                    "company_name": "長谷工コーポレーション"
                }
                dispatch(getManageCompanyInfoSuccess(data));
                return data;
            }

        } catch (error) {
            dispatch(getManageCompanyInfoFailure(error.message));
        }
    };
};