import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ChangePassword from '../components/ChangePassword';
import { updatePassword } from '../actions/passwordchange';

const mapStateToProps = (state) => {
    return {
        loading: state.changePassword.loading,
        error: state.changePassword.error,
        loginId: state.changePassword.loginId,
        userType: state.changePassword.userType 
    };
};
const mapDispatchToProps = (dispatch) => bindActionCreators({
    updatePassword
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);