import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AdminNew from "../components/AdminNew";
import { registerAdmin, getManagementCompanyInfo } from "../actions/adminnew";

const mapStateToProps = (state) => {
  const { adminNew } = state;
  console.log("adminNew:", adminNew);

  return {
    companyinfo: adminNew.companyinfo,
    loading: adminNew.loading,
    error: adminNew.error
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      registerAdmin,
      getManagementCompanyInfo
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AdminNew);