import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

//メンテナンスリストの入手リクエスト
export const fetchMaintenanceRequest = () => ({
    type: ActionTypes.FETCH_MAINTENANCE_REQUEST,
});

//メンテナンスリスト受信時に正常に処理されました
export const fetchMaintenanceSuccess = (maintenance) => {
    return {
        type: ActionTypes.FETCH_MAINTENANCE_SUCCESS,
        payload: maintenance
    };
};

//メンテナンスリスト取得時の失敗処理
export const fetchMaintenanceFailure = (error) => ({
    type: ActionTypes.FETCH_MAINTENANCE_FAILURE,
    payload: error
});

//メンテナンスリストをロードする機能
export const fetchMaintenance = (pageindex, itemsPerPage, company_code, company_name) => {
    return async (dispatch) => {
        dispatch(fetchMaintenanceRequest());
        try {
            if (!window.debug) {
                let strFilter = "";
                if (company_code) {
                    strFilter = `&company_code=${company_code}`;
                }
                if (company_name) {
                    strFilter = `&company_name=${company_name}`;
                }
                
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + `path=maintenance&type=get-list-data&page=${pageindex}&number_per_page=${itemsPerPage}` + strFilter, 
                    {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || {"data_list": [], "all_data_count":0};
                dispatch(fetchMaintenanceSuccess(data));
            }
            else {
                const data = {"data_list": [{ "company_id": 1, "company_code": "hpdemo", "company_name": "デモ会社	","screen_message": "テスト", "view_user_authority": 1},
                { "company_id": 2, 'company_code': 'hpdemo',"company_name": "デモ会社	", 'screen_message': 'テスト', "view_user_authority": 1},
                { "company_id": 3, 'company_code': 'hpdemo', 'screen_message': 'テスト', "view_user_authority": 1},
                { "company_id": 4, 'company_code': 'hpdemo', 'screen_message': 'テスト', "view_user_authority": 1},
                {"company_id": 5, 'company_code': 'hpdemo', 'screen_message': 'テスト', "view_user_authority": 1}
                ], "all_data_count": 5};
                dispatch(fetchMaintenanceSuccess(data));
            }


        } catch (error) {
            dispatch(fetchMaintenanceFailure(error.message));
        }
    };
};

//メンテナンス削除リクエストを開始する
export const fetchMaintenanceDeleteRequest = () => ({
    type: ActionTypes.MAINTENANCE_DELETE_REQUEST
});

//メンテナンスの削除時に正常に処理されました
export const fetchMaintenanceDeleteSuccess = (maintenance) => ({
    type: ActionTypes.MAINTENANCE_DELETE_SUCCESS,
    payload: maintenance
});

//メンテナンス削除時の失敗の処理
export const fetchMaintenanceDeleteFailure = (error) => ({
    type: ActionTypes.MAINTENANCE_DELETE_FAILURE,
    payload: error
});

// メンテナンス削除を行う関数
export const deletemaintenance = (payload) => {
    return async (dispatch) => {
        dispatch(fetchMaintenanceDeleteRequest());
        try {
            if (!window.debug) {
            const accessToken = localStorage.getItem("accessToken");

            const response = await axiosInstance.post(BASE_URL + "path=maintenance&type=delete-data", payload, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                }
            });

            if (response.data.status === 200) {
                const message = "削除しました。";
                dispatch(fetchMaintenanceDeleteSuccess(message));
                return message;
            }
            return response;
        } else {
            const msg = "完全に削除しました。";
            var response = {};
            response["data"] = { "respons": { "msg": msg } }
            dispatch(fetchMaintenanceDeleteSuccess(msg));
            return response;
        } 
    } catch (error) {
            dispatch(fetchMaintenanceDeleteFailure(error.message));
        }
    };
};

// メンテナンス更新リクエストを開始します
export const updatemaintenanceRequest = () => ({
    type: ActionTypes.MAINTENANCE_UPDATE_REQUEST,
});

// メンテナンスアップデート中に正常に処理されました
export const updatemaintenanceSuccess = (message) => ({
    type: ActionTypes.MAINTENANCE_UPDATE_SUCCESS,
    payload: message,
});

// メンテナンス更新中の失敗を処理します
export const updatemaintenanceFailure = (error) => ({
    type: ActionTypes.MAINTENANCE_UPDATE_FAILURE,
    payload: error,
});

// メンテナンス情報更新機能
export const submitMaintenance = (maintenance) => {
    return async (dispatch) => {
        dispatch(updatemaintenanceRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + 'path=maintenance&type=update-info-data', maintenance, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (response.data.status === 200) {
                    const message = "変更しました。";
                    dispatch(updatemaintenanceSuccess(message));
                    return message;
                }
            }
            else {
                const msg = "変更しました。";
                var response = {};
                response["data"] = { "respons": { "msg": msg } }
                dispatch(updatemaintenanceSuccess(msg));
                return response;
            }

        } catch (error) {
            dispatch(updatemaintenanceFailure(error.message));
        }
    };
};
