import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

//アクション初期化機能では詳細なログデータのダウンロードが必要です
export const fetchDeletedLogEditRequest = () => ({
    type: ActionTypes.FETCH_DELETEDLOGEDIT_REQUEST,
});

// データのロードが成功したときのアクションを作成する関数
export const fetchDeletedLogEditSuccess = (data) => {
    const payload = Array.isArray(data) ? data : [data]; 
    return {
        type: ActionTypes.FETCH_DELETEDLOGEDIT_SUCCESS,
        payload
    };
};

// データのロードに失敗した場合のアクションを作成する関数
export const fetchDeletedLogEditFailure = (error) => ({
    type: ActionTypes.FETCH_DELETEDLOGEDIT_FAILURE,
    payload: error
});

// 削除されたログの詳細データを取得する関数
export const fetchdeletedlogedit = (company_id) => {
    return async (dispatch) => {
        dispatch(fetchDeletedLogEditRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + 'path=company&type=get-data&id=' + company_id, {
                    
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });
                
                const data = response.data.respons || {"data_list": [], "all_data_count":0};
                dispatch(fetchDeletedLogEditSuccess(data));
            }
            else {
                const data = {"data_list": [{ "company_id": 1, 'company_code': 'hakaru_admin', 'company_name': 'rakusuru@hakaru.jp', 'lilz_management_unit_id': '有', 'lilz_management_unit_name': 'hakaru_admin	',"update_date" :'2020/04/13'},
                { "company_id": 2, 'company_code': 'hakaru_admin2', 'company_name': 'rakusuru@hakaru.jp', 'lilz_management_unit_id': '有', 'lilz_management_unit_name': 'hakaru_admin	',"update_date" :'2020/04/13' },
                { "company_id": 3, 'company_code': 'hakaru_admin', 'company_name': 'rakusuru@hakaru.jp', 'lilz_management_unit_id': '有', 'lilz_management_unit_name': 'hakaru_admin	',"update_date" :'2020/04/13' },
                { "company_id": 4, 'company_code': 'hakaru_admin', 'company_name': 'rakusuru@hakaru.jp', 'lilz_management_unit_id': '有', 'lilz_management_unit_name': 'hakaru_admin	' ,"update_date" :'2020/04/13'},
                { "company_id": 5, 'company_code': 'hakaru_admin', 'company_name': 'rakusuru@hakaru.jp', 'lilz_management_unit_id': '有', 'lilz_management_unit_name': 'hakaru_admin	' ,"update_date" :'2020/04/13'}
                ], "all_data_count": 5};
                dispatch(fetchDeletedLogEditSuccess(data));
            }


        } catch (error) {
            dispatch(fetchDeletedLogEditFailure(error.message));
        }
    };
};

// アクション初期化関数はログ メッセージのダウンロードを要求します
export const fetchDeletedLogEditmsgRequest = () => ({
    type: ActionTypes.FETCH_DELETEDLOGEDITMSG_REQUEST,
});

// ログメッセージが正常にロードされたときにアクションを作成する関数
export const fetchDeletedLogEditmsgSuccess = (data) => ({
    type: ActionTypes.FETCH_DELETEDLOGEDITMSG_SUCCESS,
    payload: data, 
  });
  

// ログメッセージの読み込みに失敗した場合のアクションを作成する関数
export const fetchDeletedLogEditmsgFailure = (error) => ({
    type: ActionTypes.FETCH_DELETEDLOGEDITMSG_FAILURE,
    payload: error
});

//この関数は詳細なログメッセージを取得します
export const fetchdeletedlogeditsmg = (company_id, log_type) => {
    return async (dispatch) => {
      dispatch(fetchDeletedLogEditmsgRequest());
      try {
        const accessToken = localStorage.getItem('accessToken');
  
        const response = await axiosInstance.get( `${BASE_URL}path=company&type=get-data-delete-log&id=${company_id}&log-type=${log_type}`, {
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            },
          }
        );
        const data = response.data.respons;
        dispatch(fetchDeletedLogEditmsgSuccess(data));
      } catch (error) {
        dispatch(fetchDeletedLogEditmsgFailure(error.message));
      }
    };
  };
  
