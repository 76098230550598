import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker, { registerLocale } from 'react-datepicker';
import React, { useState, useEffect, useRef } from 'react';
import { ja } from 'date-fns/locale';
import { format } from 'date-fns';
import messagesapmle from "../../constants/message";
import Modal from '../Modal/Modal';
const NOT_FOUND_IMG_PATH = "img/no_image.jpg";

const customJa = {
    ...ja,
    options: {
        ...ja.options,
        weekStartsOn: 1
    }
};

registerLocale('custom-ja', customJa);



//メッセージの編集と保存を許可します
const GaugeEdit = ({ onClose, message, getGaugeData, searchImage, submitAddPhotoAuto, submitAddPhotoManual, submitRemoveGaugeManual, submitUpdateGaugeManual, gauge_id, device_id, company_id, company_code, facility_id }) => {
    const [inputMessage, setInputMessage] = useState(message);
    const [startDate, setStartDate] = useState(() => {
        const today = new Date();
        today.setMonth(today.getMonth() - 1); // Subtract one month
        today.setDate(today.getDate() + 1); // Add one day
        return new Intl.DateTimeFormat("ja-JP", { year: "numeric", month: "2-digit", day: "2-digit" }).format(today);
    });
    const [endDate, setEndDate] = useState(() => {
        const today = new Date();
        return new Intl.DateTimeFormat("ja-JP", { year: "numeric", month: "2-digit", day: "2-digit" }).format(today);
    });
    const [currentGaugeData, setCurrentGaugeData] = useState({});
    const [isRotated, setIsRotated] = useState(false);
    const [isSearchTime, setSearchTime] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [up_manual_msg, setUpManualMsg] = useState("");
    const [photoCountLabel, setPhotoCountLabel] = useState("");
    const [photoSearchCount, setPhotoSearchCount] = useState("");
    const [photoSearchList, setPhotoSearchList] = useState([]);
    const [selectedPhotos, setSelectedPhotos] = useState([]);
    const [progress, setProgress] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const [fileName, setFileName] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [upmethod, setUpmethod] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadMessage, setUploadMessage] = useState("");
    const [gaugeMeasure, setGaugeMeasure] = useState([]);
    const fileInputRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [minmaxErrors, setminmaxErrors] = useState(
        gaugeMeasure.map(() => ({ max_error: "", min_error: "", measure_name: "", unit: "" }))
    );
    const [isUploadingVisible, setIsUploadingVisible] = useState(false);

    // State to manage the checked status of all checkboxes
    const [checkedStates, setCheckedStates] = useState(
        Array(photoSearchList.length).fill(false)
    );
    const KIKAN = 10;
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 10 }, (_, index) => currentYear - index);
    const months = Array.from({ length: 12 }, (_, index) => index + 1);
    const days = Array.from({ length: 31 }, (_, index) => index + 1);
    const hours = Array.from({ length: 24 }, (_, index) => index);
    const minutes = Array.from({ length: 60 }, (_, index) => index);

    const [datetime_manual, setDatetimeManual] = useState(() => {
        const today = new Date();
        return {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate(),
            hour: today.getHours(),
            minute: today.getMinutes()
        }
    });

    const handleMethodSelectChange = (event) => {
        const value = parseInt(event.target.value);
        setUpmethod(value);
    };

    useEffect(() => {
        const fetchData = async () => {
            const data = await getGaugeData(gauge_id, device_id, company_id);
            if (data.status === 200) {
                setCurrentGaugeData(data.gaugedata);
                if (Array.isArray(data.gaugedata?.gauge_measure_list)) {
                    const formattedList = data.gaugedata.gauge_measure_list.map((item) => ({
                        ...item,
                        min_limit: formattedNumber(item.min_limit, item.decimal_value),
                        max_limit: formattedNumber(item.max_limit, item.decimal_value),
                        diff_min_limit: formattedNumber(item.diff_min_limit, item.decimal_value),
                        diff_max_limit: formattedNumber(item.diff_max_limit, item.decimal_value)
                    }))
                    setGaugeMeasure(formattedList);
                }
                else {
                    setGaugeMeasure([]);
                }
                setminmaxErrors(data.gaugedata.gauge_measure_list.map(() => ({ max_error: "", min_error: "", measure_name: "", unit: "" })));
            }
            else {
                setErrorMessage(data.message);
            }

        };
        fetchData();
    }, [gauge_id, device_id, company_id, getGaugeData]);

    useEffect(() => {
        console.log("Current Input Message:", inputMessage);
        //空白を削除します。
        setInputMessage((prevMessage) => prevMessage.trim());
    }, [inputMessage, setInputMessage]);

    useEffect(() => {
        console.log("Up Manual Message:", up_manual_msg);
    }, [up_manual_msg]);

    // 入力値の変更を処理します
    const fncInputCheck = (input = "") => {
        const regRomaji = "!-~";
        const regPunc = "\\u300c\\u300d"; // 「  」
        const regHira = "\\u3040-\\u309f";
        const regKata = "\\u30a0-\\u30ff";
        const regFullWidthRoman = "\\uff01-\\uff9f";
        const regCommonKanji = "\\u4e00-\\u9faf";
        const regUncommKanji = "\\u3400-\\u4dbf";
        const regOtherChar = "\\u02dc\\u201c\\u201d\\u2018\\u2019"; // ˜ “ ”  ‘ ’
        const regstr_container = `^[${regRomaji}${regPunc}${regHira}${regKata}${regFullWidthRoman}${regCommonKanji}${regUncommKanji}${regOtherChar}]+$`;

        // Use RegExp for matching
        const regex = new RegExp(regstr_container);

        // Return whether the input matches the pattern
        return regex.test(input);
    };

    const formatDate = (date) => {
        return format(date, "yyyy年MM月dd日 HH:mm");
    };

    const twoDigit = (num) => {
        return num.toString().padStart(2, '0');
    };

    const toggleRotation = () => {
        setIsRotated((prev) => !prev);
    };

    const hanldeSearchTime = (event) => {
        setSearchTime(event.target.checked);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let newValue;
        if (name === "rotate_flg") {
            newValue = Number(event.target.checked);
        }
        else {
            newValue = fncInputCheck(value.trim()) ? value.trim() : "";
        }

        // Update the state with the modified value
        setCurrentGaugeData((prevData) => ({
            ...prevData,
            [name]: newValue,
        }));

    };


    // 全て選択/クリアのチェック
    const handleCheckAll = (flag) => {
        const newState = flag;
        setCheckedStates(Array(photoSearchList.length).fill(newState));
        if (flag) {
            // Add all items to the selected list
            setSelectedPhotos(photoSearchList);
        } else {
            // Clear the selected list
            setSelectedPhotos([]);
        }
    };

    // 画像毎を選択
    const handleCheckboxChange = (index) => {
        const updatedStates = [...checkedStates];
        updatedStates[index] = !updatedStates[index];
        setCheckedStates(updatedStates);
        handleSelectChange(index);
        setErrorMessage('');
    };

    // イメージリストのチェックボックスに入れるイベント 
    const handleSelectChange = (index) => {
        setSelectedPhotos((prevSelected) => {
            const isSelected = prevSelected.some((item) => item.index === index);
            if (isSelected) {
                return prevSelected.filter((item) => item.index !== index);
            } else {
                return [...prevSelected, { ...photoSearchList[index], index }];
            }
        });
    };

    const getDatetimeFormat = (localeString) => {
        // Split the locale string into date and time parts
        const [datePart, timePart] = localeString.split(" ");
        const [year, month, day] = datePart.split("/");
        const [hours, minutes, seconds] = timePart.split(":");

        // Format date and time as desired
        const up_date = year + twoDigit(month) + twoDigit(day);
        const up_time = twoDigit(hours) + twoDigit(minutes) + twoDigit(seconds);

        return `${up_date}/${up_time}`;
    };

    const handleAddPhotoAuto = async () => {
        //読み込み開始時にインターフェースを無効にする
        setIsDisabled(true);
        setIsUploadingVisible(true);
        // setIsUploading(true);
        const chunkSize = 10;
        var uploadedNum = 0;
        //チェックデータがない場合、なにもしない
        if (selectedPhotos.length <= 0) {
            setIsUploadingVisible(false);
            setIsDisabled(false);
            return;
        }

        // アップロード前表示調整
        setUploadMessage(selectedPhotos.length + "件アップロード中。。。");
        // プログレスバーを表示する
        setProgress(Math.round(((uploadedNum) / selectedPhotos.length) * 100));
        // イメージをアップロードする
        for (let i = 0; i < selectedPhotos.length; i += chunkSize) {
            uploadedNum = i + ((selectedPhotos.length - uploadedNum) > chunkSize ? chunkSize : (selectedPhotos.length - uploadedNum));
            const chunk = selectedPhotos.slice(i, uploadedNum);

            const photo_list = chunk.map((item) => {
                const localeString = new Date(item.datetime).toLocaleString('ja-JP', {
                    timeZone: 'Asia/Tokyo',
                });
                return {
                    datetime: localeString,
                    camera_s3_url: item.camera_url ? "camera_image/" + company_code + "/" + device_id + "/" + getDatetimeFormat(localeString) + "/" + item.camera_name : '',
                    camera_lilz_url: item.camera_url ? item.camera_url : '',
                    s3_camera_existed: item.s3_camera_existed ? 1 : "",
                    gauge_s3_url: item.gauge_url ? "gauge_image/" + company_code + "/" + gauge_id + "/" + getDatetimeFormat(localeString) + "/" + item.img_location + "/" + item.gauge_name : '',
                    gauge_lilz_url: item.gauge_url ? item.gauge_url : '',
                    s3_gauge_existed: item.s3_gauge_existed ? 1 : ""
                }
            });

            const payload = {
                camera_id: currentGaugeData.camera_id,
                company_id: company_id,
                facility_id: facility_id,
                device_id: device_id,
                gauge_id: gauge_id,
                camera_name_id: currentGaugeData.camera_name_id,
                photo_list: photo_list
            }
            const respond = await submitAddPhotoAuto(payload, uploadedNum);
            if (respond !== "OK") {
                return;
            }

            // あっぷろーど
            setPhotoSearchList((prevList) => {
                const remainingPhotos = prevList.slice(chunk.length); 
                return remainingPhotos;
            });
            setCheckedStates((prevStates) => prevStates.slice(chunk.length)); 
            setSelectedPhotos((prevPhotos) => prevPhotos.slice(chunkSize)); 
            setPhotoSearchCount((prevCount) => prevCount - chunkSize); 

            setProgress(Math.round(((uploadedNum) / selectedPhotos.length) * 100));
        }
        setUploadMessage(uploadedNum + "件の画像が正常にアップロードされました。");
        setIsDisabled(false);
    };

    // Upload file to API
    const uploadFileToAPI = async (filedata) => {
        setIsUploading(true);
        setErrorMessage('');

        try {
            const respond = await submitAddPhotoManual(filedata);
            if (respond === "OK") {
                setUploadMessage("画像が正常にアップロードされました。");
                return;
            }
            else {
                setErrorMessage("画像のアップロードが失敗しました");
                return;
            }

        } catch (error) {
            setErrorMessage("画像のアップロードが失敗しました");
        } finally {
            setIsUploading(false);
        }
    };

    const handleAddPhotoManual = async () => {
        if (!selectedFile) {
            return;
        }
        //ファイルサイズチェック
        if (selectedFile.size > 20 * 1024 * 1024) {
            setErrorMessage("20MBを超える画像ファイルはアップロードできません。");
            return;
        }

        // File type check (must be an image)
        if (!selectedFile.type.match('image.*')) {
            setErrorMessage("画像ファイルを選択してください。");
            return;
        }
        setIsDisabled(true);
        setIsUploading(true);

        const reader = new FileReader();
        reader.onload = function (e) {
            const imageData = e.target.result;
            const up_date = datetime_manual.year + "-" + twoDigit(datetime_manual.month) + "-" + twoDigit(datetime_manual.day);
            // const up_time = twoDigit(datetime_manual.hour) + ":" + twoDigit(datetime_manual.minute) + ":" + "00";
            const up_time = `${twoDigit(datetime_manual.hour)}:${twoDigit(datetime_manual.minute)}:00`;
            const get_datetime = up_date + " " + up_time;
            const datetime = up_date.replaceAll('-', '') + "/" + up_time.replaceAll(':', '');
            const ISOtime = new Date().toISOString();
            const img_location = ISOtime.split('.')[0] + '+00:00';
            const payload = {
                image_file: imageData,
                company_code: company_code,
                device_id: device_id,
                camera_id: currentGaugeData.camera_id,
                company_id: company_id,
                facility_id: facility_id,
                gauge_id: gauge_id,
                camera_name_id: currentGaugeData.camera_name_id,
                camera_s3_url: (upmethod === 1 || upmethod === 0) ? ("camera_image/" + company_code + "/" + device_id + "/" + datetime + "/" + fileName) : '',
                gauge_s3_url: (upmethod === 2 || upmethod === 0) ? ("gauge_image/" + company_code + "/" + gauge_id + "/" + datetime + "/" + img_location + "/" + fileName) : '',
                get_datetime: get_datetime
            };

            uploadFileToAPI(payload);
            setIsDisabled(false);
        };

        reader.onerror = function () {
            setIsDisabled(false);
            setIsUploading(false);
            setErrorMessage('Error reading the file.');
        };

        reader.readAsDataURL(selectedFile);
    }

    const resetErrors = () => {
        const resetErrorsArray = minmaxErrors.map((error) =>
            Object.keys(error).reduce((acc, key) => {
                acc[key] = "";
                return acc;
            }, {})
        );
        setminmaxErrors(resetErrorsArray);
    };

    // 異常値記入チェック
    const checkLimitVal = () => {
        var result = true;
        resetErrors();
        const newErrors = gaugeMeasure.map((item, index) => {
            const itemErrors = {};

            // 異常判定値の範囲をチェック
            if (item.diff_flg) {
                if (item.diff_max_limit_flg && !item.diff_max_limit) {
                    itemErrors.max_error = "上限値を入力してください。";
                    result = false;
                }
                if (item.diff_min_limit_flg && !item.diff_min_limit) {
                    itemErrors.min_error = "下限値を入力してください。";
                    result = false;
                }
                const regex = /^[0-9]*(?:\.[0-9]{0,5})?$/;
                if (item.diff_min_limit && !regex.test(item.diff_min_limit)) {
                    itemErrors.min_error = messagesapmle.INCORRECT_FORMAT_MSG.replace('%s', "下限");
                    result = false;
                }
                if (item.diff_max_limit && !regex.test(item.diff_max_limit)) {
                    itemErrors.max_error = messagesapmle.INCORRECT_FORMAT_MSG.replace('%s', "上限");
                    result = false;
                }
                if (item.diff_min_limit_flg && item.diff_max_limit_flg) {
                    if (parseFloat(item.diff_min_limit) >= parseFloat(item.diff_max_limit)) {
                        itemErrors.min_error = "下限値は上限値より小さく入力してください。";
                        result = false;
                    }
                }
            }
            else {
                if (item.max_limit_flg && !item.max_limit) {
                    itemErrors.max_error = "上限値を入力してください。";
                    result = false;
                }
                if (item.min_limit_flg && !item.min_limit) {
                    itemErrors.min_error = "下限値を入力してください。";
                    result = false;
                }
                if (item.min_limit_flg && item.max_limit_flg) {
                    if (parseFloat(item.min_limit) >= parseFloat(item.max_limit)) {
                        itemErrors.min_error = "下限値は上限値より小さく入力してください。";
                        result = false;
                    }
                }
            }
            // 計測名をチェック
            if (item.measure_name === "") {
                itemErrors.measure_name = messagesapmle.REQUIRED_MSG.replace("%s", "計測名");
                result = false;
            }
            const duplicateIndex = gaugeMeasure.findIndex(
                (otherItem, otherIndex) => otherIndex !== index && otherItem.measure_name === item.measure_name
            );
            if (duplicateIndex !== -1) {
                itemErrors.measure_name = messagesapmle.MEASURE_NAME_MUST_UNIQUE_MSG;
                result = false;
            }
            // 単位をチェック
            if (!item.unit) {
                itemErrors.unit = messagesapmle.REQUIRED_MSG.replace('%s', "単位");
                result = false;
            }

            return itemErrors;
        });

        setminmaxErrors(newErrors);
        return result;
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!checkLimitVal()) {
            return;
        }

        // Disable update button

        // API要求
        const updateData = async () => {
            const payload = {
                gauge_id: gauge_id,
                device_id: device_id,
                company_id: company_id,
                rotate_flg: currentGaugeData.rotate_flg,
                gauge_name: currentGaugeData.gauge_name,
                display_name: currentGaugeData.display_name,
                measure_list: gaugeMeasure
            };
            const data = await submitUpdateGaugeManual(payload);
            setModalMessage(data.respons);
            setIsConfirmationModal(false);
            setIsModalOpen(true);
        };

        updateData();
    };

    const handleSearchImage = async (event) => {
        event.preventDefault();

        setIsUploadingVisible(false);
        setProgress(0);
        setErrorMessage("");
        setUploadMessage("");
        setUpManualMsg("");

        const objstartDate = startDate ? new Date(startDate) : null;
        const objendDate = endDate ? new Date(endDate) : null;

        if (!objstartDate || !objendDate) {
            setErrorMessage("期間を入力してください。");
            return;
        }

        // 2年以内チェック
        const endDateCheck = new Date(
            objstartDate.getFullYear(),
            objstartDate.getMonth() + 6,
            objstartDate.getDate() - 1
        );

        if (objstartDate > objendDate) {
            setErrorMessage("正しい期間を入力してください。");
            return;
        }
        else if (objendDate > endDateCheck) {
            setErrorMessage("6か月以内に期間を入力してください。");
            return;
        }

        //  画像リストをクリアする
        setErrorMessage("");
        setPhotoCountLabel("画像を検索しています。");
        setPhotoSearchCount("");
        setPhotoSearchList([]);
        setSelectedPhotos([]);
        setCheckedStates([]);

        setUpManualMsg("");

        // 検索の時、画面を無効にする
        setIsDisabled(true);

        try {
            // 要求日付を細分する
            const dateRanges = splitRequestDate(startDate, endDate);
            const totalRequests = dateRanges.length;
            const results = [];
            for (let i = 0; i < totalRequests; i++) {
                const { start, end } = dateRanges[i];
                try {
                    const result = await fetchDataForRange(start, end);
                    if (result.status === 200) {
                        results.push(...result.photo_list);
                    }
                    else {
                        const err_message = result.message ? result.message : `Failed to fetch data for range ${startDate} to ${endDate}`;
                        throw new Error(err_message);
                    }
                } catch (err) {
                    throw new Error(`Error fetching data for range ${start} to ${end}: ${err.message}`);
                }
            }
            setPhotoSearchList(results);
            setPhotoSearchCount(results.length);
            setPhotoCountLabel(" 件見つかりました");
        }
        catch (err) {
            setUpManualMsg(err.message);
        }
        finally {
            // 画面を復帰する
            setIsDisabled(false);
        }


        // API要求
        const fetchData = async () => {
            const payload = {
                gauge_id: gauge_id,
                device_id: device_id,
                camera_id: currentGaugeData.camera_id,
                company_id: company_id,
                company_code: company_code,
                start_time: startDate,
                end_time: endDate
            };
            const data = await searchImage(payload);
            if (data.status === 200) {
                setPhotoSearchList(data.photo_list);
                setPhotoSearchCount(data.photo_list.length);
                setPhotoCountLabel(" 件見つかりました");
            }
            else {
                setUpManualMsg(data.message);
            }
        };
        try {
            await fetchData();
        } finally {
            // 画面を復帰する
            setIsDisabled(false);
        }

    };


    // Split Request
    function splitRequestDate(startDate, endDate) {
        const ranges = [];
        let current = new Date(startDate);

        while (current <= new Date(endDate)) {
            const rangeStart = current.toISOString().split('T')[0].replace(/-/g, '/');
            const next = new Date(current);
            next.setDate(next.getDate() + KIKAN - 1);
            const rangeEnd = next > new Date(endDate) ? endDate : next.toISOString().split('T')[0].replace(/-/g, '/');
            ranges.push({ start: rangeStart, end: rangeEnd });

            // Move to the next chunk
            current = new Date(next);
            current.setDate(current.getDate() + 1);
        }

        return ranges;
    }

    // Fetch data for a given date range
    async function fetchDataForRange(startDate, endDate) {
        // API要求
        const payload = {
            gauge_id: gauge_id,
            device_id: device_id,
            camera_id: currentGaugeData.camera_id,
            company_id: company_id,
            company_code: company_code,
            start_time: startDate,
            end_time: endDate
        };
        const data = await searchImage(payload);
        return data;
    }
    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
            setSelectedFile(file);
        }
    };

    const handleAddItem = () => {
        // setFormItems([...formItems, { id: Date.now(), name: '', unit: '', decimal: -1 }]);
        setGaugeMeasure((prevList) => [
            ...prevList,
            {
                gauge_id: gauge_id,
                company_id: parseInt(company_id),
                gauge_name: "",
                display_name: "",
                measure_id: "",
                measure_name: "",
                unit: "",
                decimal_value: "",
                min_limit: "",
                min_limit_flg: "",
                max_limit: "",
                max_limit_flg: "",
                diff_flg: 0,
                diff_type: 0,
                diff_min_limit: "",
                diff_min_limit_flg: 0,
                diff_max_limit_flg: 0,
                diff_max_limit: "",
                standard_time: 0,
                is_delete: 0,
                deleted_row: "",
                disabled_btn: "sakujo"
            }

        ]);
        setminmaxErrors((prevList) => [
            ...prevList,
            { max_error: "", min_error: "", measure_name: "", unit: "" }

        ]);
    };

    //数値変換、桁数チェック 末尾にゼロ入力可能
    const fncInputFormatCheck = (value, decimal_value = -1) => {
        const intNum = 10;
        const decNum = 5;
        let intCount = intNum;

        if ((value === "") || (value === "0") || (value === null)) {
            return value;
        }

        let result = value;

        // Split the value into integer and decimal parts
        const [intStr, decStr] = String(result).split(".");
        let decimalNum = 0;

        // Check if the number is negative
        const isMinus = intStr.startsWith("-");
        if (isMinus) {
            intCount = intNum + 1;
        }

        if (decStr) {
            if (decStr.length > decNum) {
                setModalMessage(`小数の数値は、${decNum}桁までの入力です。`);
                setIsConfirmationModal(false);
                setIsModalOpen(true);
                return "";
            } else if (intStr.length > intCount) {
                setModalMessage(`整数の数値は、${intNum}桁までの入力です。`);
                setIsConfirmationModal(false);
                setIsModalOpen(true);
                return "";
            }
            decimalNum = decimal_value < 0 ? decStr.length : parseInt(decimal_value);
        } else {
            if (value.length > intCount) {
                setModalMessage(`整数の数値は、${intNum}桁までの入力です。`);
                setIsConfirmationModal(false);
                setIsModalOpen(true);
                return "";
            }
            if (decimal_value >= 0) {
                decimalNum = parseInt(decimal_value);
            }
        }

        // Adjust the value to the specified decimal places
        if (decimalNum > 0) {
            if (isMinus) {
                result = Math.ceil(parseFloat(value) * 10 ** decimalNum) / 10 ** decimalNum;
            } else {
                result = Math.floor(parseFloat(value) * 10 ** decimalNum) / 10 ** decimalNum;
            }
        } else {
            result = isMinus ? Math.ceil(value) : Math.floor(value);
        }
        result = parseFloat(result).toFixed(decimalNum);

        // Check for NaN
        if (isNaN(result)) {
            setModalMessage("数値のみ入力可能です。");
            setIsConfirmationModal(false);
            setIsModalOpen(true);
            return "";
        }

        return result;
    }

    const formattedNumber = (value, decimalValue) => {
        if ((value === "") || (value === "0") || (value === null)) {
            return value;
        }
        if (decimalValue >= 0) {
            return Number(value).toFixed(decimalValue);
        } else {
            return value.toString().replace(/\.?0+$/, "");
        }
    };

    const handleMeasureInputChange = (index, name, inValue, type = "text") => {
        const newValue = inValue;
        setGaugeMeasure((prevList) =>
            prevList.map((item, i) => {
                if (i === index) {
                    const updatedItem = {
                        ...item,
                        [name]: type === "checkbox" ? (newValue ? 1 : 0) : newValue.trim()
                    };
                    if (name === "measure_name") {
                        updatedItem["measure_name"] = fncInputCheck(newValue.trim()) ? newValue.trim() : "";
                    }

                    if (name === "decimal_value") {
                        const minlimit = updatedItem.diff_flg ? "diff_min_limit" : "min_limit";
                        const maxlimit = updatedItem.diff_flg ? "diff_max_limit" : "max_limit";
                        const minvalue = fncInputFormatCheck(updatedItem[minlimit], newValue);
                        const maxvalue = fncInputFormatCheck(updatedItem[maxlimit], newValue);
                        updatedItem[minlimit] = minvalue;
                        updatedItem[maxlimit] = maxvalue;
                    }
                    return updatedItem;
                }
                return item;
            })
        );
    };

    const handleMeasureInputCheck = (index, name, inValue, type = "text") => {
        let newValue = inValue;
        if ((name === "max_limit") || (name === "min_limit") || (name === "diff_max_limit") || (name === "diff_min_limit")) {
            const decimalVal = gaugeMeasure[index].decimal_value ? gaugeMeasure[index].decimal_value : -1;
            newValue = fncInputFormatCheck(inValue, decimalVal);
        }

        setGaugeMeasure((prevList) =>
            prevList.map((item, i) =>
                i === index
                    ? {
                        ...item,
                        [name]: type === "checkbox" ? (newValue ? 1 : 0) : newValue.trim()
                    }
                    : item
            )
        );

    };

    const handleGaugeRemove = (msindex) => {
        const is_delete = gaugeMeasure[msindex].is_delete;
        const measure_id = gaugeMeasure[msindex].measure_id;
        if (!measure_id) {
            // measure_idがない場合は、直接削除されます。
            setGaugeMeasure((prevList) =>
                prevList.filter((_, index) => index !== msindex)
            );
            return;
        }

        // APIからの場合、確認ダイアログが表示されます。
        const confirmMsg = (is_delete) ? messagesapmle.CANCELLED_CONFIRM_MSG : messagesapmle.DELETED_CONFIRM_MSG;
        setSelectedIndex(msindex);
        setModalMessage(confirmMsg);
        setIsConfirmationModal(true);
        setIsModalOpen(true);
    };

    const handleConfirmDelete = () => {
        const is_delete = gaugeMeasure[selectedIndex].is_delete;
        const measure_id = gaugeMeasure[selectedIndex].measure_id;
        const payload = { measure_id: measure_id, is_delete: is_delete };

        const removeData = async (payload) => {
            try {
                const data = await submitRemoveGaugeManual(payload);
                if (data.status === 200) {
                    if (data.respons.is_delete === 2) {
                        // リストから項目を完全に削除します。
                        setGaugeMeasure((prevList) =>
                            prevList.filter((_, index) => index !== selectedIndex)
                        );
                    } else {
                        // is_delete状態を更新します。
                        setGaugeMeasure((prevGaugeMeasure) =>
                            prevGaugeMeasure.map((item, idx) =>
                                idx === selectedIndex ? { ...item, is_delete: data.respons.is_delete } : item
                            )
                        );
                    }
                    setModalMessage(message || '完全に削除しました。');
                } else {
                    setErrorMessage(data.message);
                }
            } catch (error) {
                setErrorMessage("削除に失敗しました。");

            } finally {
                try {
                    // データを更新するためにAPIを再呼び出します。
                    const updatedData = await getGaugeData(gauge_id, device_id, company_id);
                    if (updatedData.status === 200) {
                        const fetchGaugeMeasures = updatedData.gaugedata.gauge_measure_list;
                        //自動生成された動的な要素を取得します。
                        const newAddItems = gaugeMeasure.filter(item => !item.measure_id);
                        // APIからのリストと自動生成された要素を統合します。
                        setGaugeMeasure([...fetchGaugeMeasures, ...newAddItems]);
                    }
                } catch (error) {
                    console.error("error:", error);
                } finally {
                    setIsModalOpen(false);
                    setIsConfirmationModal(false);
                }
            }
        };
        removeData(payload);
    };


    return (
        <div className="popup-wrapper-company">
            <div className="popup-gauge">
                {/* メッセジー画面 */}
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                        onConfirm={handleConfirmDelete}
                        showCancelButton={isConfirmationModal}
                    />
                )}

                <div className="popup-top">
                    <span>計器マスタ編集</span>
                    <div className={isDisabled ? "disabled-overlay" : ""}>
                        <button className="popup-close" onClick={() => !isDisabled && onClose()}>&times;</button>
                    </div>
                </div>

                <div className="popup-content-gauge">
                    <form>
                        <div className={isDisabled ? "disabled-overlay" : ""}>
                            <div className="lay-search">
                                <div className="lay-search-wrap-company">

                                    <div className="w100per disp_in_table">
                                        <div className="w70per disp_in">
                                            <button type="button" className="mod-btn__roundborder add" onClick={handleSubmit}> 更新 </button>

                                            <div className='lay-search-company__form__col3' style={{ marginTop: '20px' }}>
                                                <div className="mod-form-company__set"  >
                                                    <dl>
                                                        <dt style={{ backgroundColor: '#4C4C4C' }}>メーターID</dt>
                                                        <dd>
                                                            <div className='mod-form-mail__text'>
                                                                <input type="text" id="gauge_id" name="gauge_id" className='readonlyCls' readOnly value={currentGaugeData.gauge_id || ''} />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>

                                            <div className='lay-search-company__form__col3' style={{ marginTop: '-10px' }}>
                                                <div className="mod-form-company__set">
                                                    <dl>
                                                        <dt style={{ backgroundColor: '#4C4C4C' }} >メーター名	</dt>
                                                        <dd>
                                                            <div className='mod-form-mail__text'>
                                                                <input type="text" id="gauge_name" name="gauge_name" value={currentGaugeData.gauge_name || ''} onChange={handleInputChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>

                                            <div className='lay-search-company__form__col3' style={{ marginTop: '-10px' }} >
                                                <div className="mod-form-company__set">
                                                    <dl>
                                                        <dt style={{ backgroundColor: '#4C4C4C' }} >画面表示名	</dt>
                                                        <dd>
                                                            <div className='mod-form-mail__text'>
                                                                <input type="text" id="display_name" name="display_name" value={currentGaugeData.display_name || ''} onChange={handleInputChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </div>

                                            <div className='lay-search-company__form__col3' style={{ marginTop: '-10px' }}>
                                                <ul>
                                                    <li>
                                                        <div className="mod-form-company__set">
                                                            <dl>
                                                                <dt className="dt-style" style={{ backgroundColor: '#4C4C4C' }} >回転操作</dt>
                                                                <dd >
                                                                <label className="label-flex" style={{ marginTop: '7px' }}>
                                                                    <input type="checkbox" className="checkbox-input"
                                                                        name="rotate_flg"
                                                                        checked={currentGaugeData.rotate_flg || false}
                                                                        onChange={isDisabled ? undefined : handleInputChange}
                                                                        onClick={isDisabled ? (e) => e.preventDefault() : undefined} 
                                                                        style={{
                                                                        WebkitAppearance: 'checkbox',
                                                                        marginRight: '5px',
                                                                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                                                                        }}
                                                                    />
                                                                    有効にする
                                                                </label>
                                                                </dd>
                                                            </dl>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                        </div>

                                        <div className="floatr disp_in w30per" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <img id="image_url"
                                                src={currentGaugeData.image_url ? currentGaugeData.image_url : NOT_FOUND_IMG_PATH}
                                                className={isRotated ? "img_180_rotate img_big" : "img_big"}
                                                alt="Display"
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = NOT_FOUND_IMG_PATH;
                                                }}
                                            />
                                            <button type="button" className="New_btn" name="RotateBtn" id="RotateBtn" onClick={toggleRotation} style={{ marginTop: "10px" }}>
                                                180°回転
                                            </button>
                                        </div>

                                    </div>

                                    <div className="line-with-text" style={{ marginTop: '20px' }} >
                                        <span>データ編集</span>
                                    </div>

                                    <div id="other-settings-display">
                                        <div className="setting-column" style={{ marginTop: '10px' }}>
                                            <button type="button" name="photoSearch" id="photoSearch" className="New_btn" data-added="0" onClick={handleSearchImage} >DBに存在しない画像を検索</button>
                                            <div className="search_time" style={{ marginTop: '10px' }} >
                                                <div className="search_time_label" style={{ marginRight: '10px' }}>
                                                    <label>
                                                        <input type="checkbox" className="checkbox-input d-none" style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} onChange={hanldeSearchTime} checked={isSearchTime || false} />
                                                        期間:
                                                    </label>
                                                </div>

                                                <div className="date-picker-container" >
                                                    <DatePicker
                                                        selected={startDate ? new Date(startDate) : null}
                                                        dateFormat="yyyy/MM/dd"
                                                        onChange={(date) => setStartDate(date ? format(date, "yyyy/MM/dd") : null)}
                                                        locale="custom-ja" className="custom-datepicker"
                                                    />
                                                </div>


                                                <span>～</span>

                                                <div className="date-picker-container">
                                                    <DatePicker
                                                        selected={endDate ? new Date(endDate) : null}
                                                        dateFormat="yyyy/MM/dd"
                                                        onChange={(date) => setEndDate(date ? format(date, "yyyy/MM/dd") : null)}
                                                        locale="custom-ja" className="custom-datepicker"
                                                    />
                                                </div>

                                            </div>
                                            <div>
                                                <div style={{ marginTop: '10px' }} >
                                                    <span id="photo_search_count" className="photo_search_count">{photoSearchCount}</span>
                                                    <span id="photo_count_label" className="photo_search_count">{photoCountLabel}</span>
                                                </div>

                                                <div className="photo_search_container">

                                                    <div id="photo-search-div">
                                                        <table id="photo_search_list">
                                                            <thead>
                                                                {photoSearchList.map((val, index) => (
                                                                    <tr key={index}>
                                                                        <th>{formatDate(val.datetime)}</th>
                                                                        <td style={{ verticalAlign: 'middle' }}>
                                                                            <label>
                                                                                データベースに追加
                                                                            </label>
                                                                            <input type="checkbox" className="checkbox-input" style={{ WebkitAppearance: 'checkbox', marginLeft: '7px', width: '22px', height: '15px' }} checked={checkedStates[index] || false} onChange={() => handleCheckboxChange(index)} />

                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </thead>
                                                        </table>
                                                    </div>


                                                    <div className="photo_search_buttons">
                                                        <button type="button" className="mod-btn__roundbordersearch add" onClick={() => handleCheckAll(true)}>すべて選択</button>
                                                        <button type="button" className="mod-btn__roundbordersearch add" onClick={() => handleCheckAll(false)}>すべてクリア</button>
                                                        <button type="button" className="mod-btn__roundbordersearch add" onClick={handleAddPhotoAuto}>追加</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="setting-column">
                                            <fieldset>
                                                <legend>手動追加</legend>
                                                <label htmlFor="img-file" className="custom-file-upload"> 画像ファイルを選択 </label>
                                                <div className="input-button-container">
                                                    <input type="text" value={fileName || ''} readOnly className="input-field" />
                                                    <button type="button" className="mod-btn__roundbordersearch" onClick={handleButtonClick}> 参照 </button>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        ref={fileInputRef}
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange}
                                                    />
                                                </div>

                                                <div className="custom-file-upload" style={{ marginTop: '-20px' }}>
                                                    追加日時
                                                </div>

                                                <div className="photo_manual_row" style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                    <select id="year" name="year" value={datetime_manual.year} className="lay-master__table_form" onChange={setDatetimeManual}>
                                                        {years.map(year => (
                                                            <option key={year} value={year}>{year}</option>
                                                        ))}
                                                    </select>

                                                    <span>年</span>

                                                    <select id="upTimeMonth" name="month" value={datetime_manual.month} className="lay-master__table_form" onChange={setDatetimeManual}>
                                                        {months.map(month => (
                                                            <option key={month} value={month}>{month}</option>
                                                        ))}
                                                    </select>

                                                    <span>月</span>

                                                    <select id="upTimeDate" name="day" value={datetime_manual.day} className="lay-master__table_form" onChange={setDatetimeManual}>
                                                        {days.map(day => (
                                                            <option key={day} value={day}>{day}</option>
                                                        ))}
                                                    </select>

                                                    <span>日</span>
                                                </div>

                                                <div className="photo_manual_row" style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
                                                    <select id="upTimeHour" name="hour" value={datetime_manual.hour} className="lay-master__table_form" onChange={setDatetimeManual}>
                                                        {hours.map(hour => (
                                                            <option key={hour} value={hour}>{hour.toString().padStart(2, '0')}</option>
                                                        ))}
                                                    </select>

                                                    <span>時</span>

                                                    <select id="upTimeMin" name="minute" value={datetime_manual.minute} className="lay-master__table_form" onChange={setDatetimeManual}>
                                                        {minutes.map(minute => (
                                                            <option key={minute} value={minute}>{minute.toString().padStart(2, '0')}</option>
                                                        ))}
                                                    </select>

                                                    <span>分</span>
                                                </div>

                                                <div className="custom-file-upload" style={{ marginTop: '10px' }}>
                                                    登録フォルダ
                                                </div>

                                                <div style={{ display: 'flex', alignItems: 'center', marginTop: '-10px' }}>
                                                    <div>
                                                        <select id="up-method" name="up-method" className="lay-master__option_form" onChange={handleMethodSelectChange}>
                                                            <option value='0'>全て</option>
                                                            <option value='1'>カメラ</option>
                                                            <option value='2'>計器</option>
                                                        </select>
                                                    </div>

                                                    <div className="button-container" style={{ marginBottom: '20px', marginLeft: '145px' }}>
                                                        <button id="photoAddManual" type="button" className="mod-btn__roundborder add"
                                                            disabled={isUploading}
                                                            onClick={handleAddPhotoManual}>追加</button>
                                                    </div>

                                                </div>
                                            </fieldset>

                                            <div className="upload-msg-div">
                                                <div className="upload-wait-container">
                                                    {/* Loader image */}
                                                    {isUploadingVisible && progress >= 0 && progress < 100 && (
                                                        <img id="waiting-img" src="img/loader.gif" alt="Loading..." />
                                                    )}

                                                    {/* Manual message */}
                                                    <div className="upload-wait-div" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                        <div className="up-manual-msg">
                                                            <span id="up-manual-msg" style={{ color: uploadMessage ? "limegreen" : "red", fontWeight: "bold" }}>
                                                                {uploadMessage || errorMessage || ""}
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>

                                                {/* Progress bar */}
                                                {isUploadingVisible && (
                                                    <div id="progressbar"
                                                        style={{
                                                            position: "relative", height: "30px", backgroundColor: "#e0e0e0",
                                                            borderRadius: "5px", overflow: "hidden", display: progress >= 0 ? "block" : "none",
                                                        }}>

                                                        <div className="progress-bar"
                                                            style={{
                                                                width: `${progress}%`,
                                                                backgroundColor: progress > 0 ? "#4297d7" : "#e0e0e0",
                                                                height: "100%",
                                                                transition: "width 0.3s ease",
                                                            }}>

                                                        </div>

                                                        <div
                                                            style={{
                                                                position: "absolute", top: "50%", left: "50%",
                                                                transform: "translate(-50%, -50%)",
                                                                color: "white",
                                                                fontWeight: "bold",
                                                            }}> {progress}%
                                                        </div>

                                                    </div>
                                                )}
                                            </div>

                                        </div>

                                    </div>

                                    <div className="line-with-text" style={{ marginTop: '-10px' }}>
                                        <span>計測項目</span>
                                    </div>

                                    <button style={{ marginTop: '10px' }} type="button" className="mod-btn__roundborder add" onClick={handleAddItem}> 追加 </button>
                                    {gaugeMeasure.length > 0 &&
                                        gaugeMeasure.map((item, index) => (
                                            <React.Fragment key={index}>

                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                                    <div style={{
                                                        display: 'flex',
                                                        width: '88%',
                                                        backgroundColor: item.disabled_btn === 'torikeshi' ? '#d3d3d3' : 'transparent',
                                                        pointerEvents: item.disabled_btn === 'torikeshi' ? 'none' : 'auto'
                                                    }}>
                                                        <div style={{
                                                            width: '40%',
                                                            backgroundColor: item.disabled_btn === 'torikeshi' ? '#d3d3d3' : 'transparent',
                                                            pointerEvents: item.disabled_btn === 'torikeshi' ? 'none' : 'auto',
                                                        }}>
                                                            <div style={{ marginLeft: '20px' }} >
                                                                <div className='lay-search-company__form__col3' style={{ marginTop: '10px' }}>
                                                                    <ul>
                                                                        <li>
                                                                            <div className="mod-form-company__set">
                                                                                <dl>
                                                                                    <dt style={{ backgroundColor: '#4C4C4C' }} >計測名</dt>
                                                                                    <dd>

                                                                                        <div className='mod-form-camera__text'>
                                                                                            <input type="text" id="measure_name" name="measure_name" value={item.measure_name || ""} data-index={index} onChange={(e) => handleMeasureInputChange(index, "measure_name", e.target.value)} autoComplete="off" />
                                                                                        </div>

                                                                                        <div className="error_msg">
                                                                                            {minmaxErrors[index].measure_name && <span style={{ color: 'red' }}>{minmaxErrors[index].measure_name}</span>}
                                                                                        </div>

                                                                                    </dd>

                                                                                </dl>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div className='lay-search-company__form__col3' style={{ marginTop: '10px' }}>
                                                                    <ul>
                                                                        <li>
                                                                            <div className="mod-form-company__set">
                                                                                <dl>
                                                                                    <dt style={{ backgroundColor: '#4C4C4C' }} >単位</dt>
                                                                                    <dd>
                                                                                        <div className='mod-form-camera__text'>
                                                                                            <input type="text" id="unit" name="unit" value={item.unit || ""} data-index={index} onChange={(e) => handleMeasureInputChange(index, "unit", e.target.value)} autoComplete="off" />
                                                                                        </div>
                                                                                        <div className="error_msg">
                                                                                            {minmaxErrors[index].unit && <span style={{ color: 'red' }}>{minmaxErrors[index].unit}</span>}
                                                                                        </div>
                                                                                    </dd>
                                                                                </dl>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <div className='lay-search-company__form__col3' style={{ marginTop: '10px' }}>
                                                                    <ul>
                                                                        <li>
                                                                            <div className="mod-form-company__set">
                                                                                <dl>
                                                                                    <dt style={{ backgroundColor: '#4C4C4C' }} >小数点桁数</dt>
                                                                                    <dd>
                                                                                        <div className='mod-form-mail__text'>
                                                                                            <select id="decimal_value" name="decimal_value" className="lay-master__camera_option_form"
                                                                                                value={item.decimal_value || -1} data-index={index}
                                                                                                onChange={(e) => handleMeasureInputChange(index, "decimal_value", e.target.value)}

                                                                                            >
                                                                                                <option value="-1">未設定</option>
                                                                                                <option value="0">0</option>
                                                                                                <option value="1">1</option>
                                                                                                <option value="2">2</option>
                                                                                                <option value="3">3</option>
                                                                                                <option value="4">4</option>
                                                                                                <option value="5">5</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </dd>
                                                                                </dl>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </div>

                                                                <label style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <input type="checkbox" className="checkbox-input" name="diff_flg"
                                                                        style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }}
                                                                        checked={item.diff_flg || false} data-index={index}
                                                                        onChange={(e) => handleMeasureInputChange(index, "diff_flg", e.target.checked, "checkbox")}
                                                                    />
                                                                    {/* <span style={{ marginRight: '10px' }}></span> */}
                                                                    差分値を計算する
                                                                </label>

                                                            </div>

                                                        </div>

                                                        <div style={{
                                                            width: '50%', marginTop: '12px',
                                                            backgroundColor: item.disabled_btn === 'torikeshi' ? '#d3d3d3' : 'transparent',
                                                            pointerEvents: item.disabled_btn === 'torikeshi' ? 'none' : 'auto',
                                                        }}>

                                                            {/* 異常判定値 */}
                                                            <div className="diff_limit">
                                                                <div className="label-style">異常判定値</div>

                                                                <div className="upper-wrapper">

                                                                    <div className="upper-group">
                                                                        <div className="upperlimit" >
                                                                            <input type="checkbox" className="checkbox-input" style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} checked={item.diff_flg ? item.diff_max_limit_flg : item.max_limit_flg} name={item.diff_flg ? "diff_max_limit_flg" : "max_limit_flg"} data-index={index} onChange={(e) => handleMeasureInputChange(index, item.diff_flg ? "diff_max_limit_flg" : "max_limit_flg", e.target.checked, "checkbox")} />
                                                                            <label >上限：</label>
                                                                        </div>
                                                                        <div className='mod-form-camera__text'>
                                                                            <input type="text" id="max_limit" value={item.diff_flg ? (item.diff_max_limit ? (item.diff_max_limit) : "") : (item.max_limit ? (item.max_limit) : "")} name={item.diff_flg ? "diff_max_limit" : "max_limit"} data-index={index} onChange={(e) => handleMeasureInputChange(index, item.diff_flg ? "diff_max_limit" : "max_limit", e.target.value)} onBlur={(e) => handleMeasureInputCheck(index, item.diff_flg ? "diff_max_limit" : "max_limit", e.target.value)} autoComplete="off" />
                                                                        </div>
                                                                        <span >以上</span>
                                                                    </div>

                                                                    <div className="error_msg">
                                                                        {minmaxErrors[index].max_error && <span style={{ color: 'red' }}>{minmaxErrors[index].max_error}</span>}
                                                                    </div>

                                                                </div>

                                                                <div className="lower-wrapper">
                                                                    <div className="lower-group">
                                                                        <div className="lowerlimit">
                                                                            <input type="checkbox" className="checkbox-input" style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} checked={item.diff_flg ? item.diff_min_limit_flg : item.min_limit_flg} name={item.diff_flg ? "diff_min_limit_flg" : "min_limit_flg"} onChange={(e) => handleMeasureInputChange(index, item.diff_flg ? "diff_min_limit_flg" : "min_limit_flg", e.target.checked, "checkbox")} />
                                                                            <label className="diff_min_limit_flg0">下限：</label>
                                                                        </div>

                                                                        <div className='mod-form-camera__text'>
                                                                            <input type="text" id="min_limit" value={item.diff_flg ? (item.diff_min_limit ? (item.diff_min_limit) : "") : (item.min_limit ? (item.min_limit) : "")} name={item.diff_flg ? "diff_min_limit" : "min_limit"} data-index={index} onBlur={(e) => handleMeasureInputCheck(index, item.diff_flg ? "diff_min_limit" : "min_limit", e.target.value)} onChange={(e) => handleMeasureInputChange(index, item.diff_flg ? "diff_min_limit" : "min_limit", e.target.value)} autoComplete="off" />
                                                                        </div>

                                                                        <span>以下</span>

                                                                    </div>

                                                                    <div className="error_msg">
                                                                        {minmaxErrors[index].min_error && <span style={{ color: 'red' }}>{minmaxErrors[index].min_error}</span>}
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className='lay-search-company__form__col3' style={{ marginTop: '20px', visibility: item.diff_flg ? 'visible' : 'hidden' }}>
                                                                <ul>
                                                                    <li>
                                                                        <div className="mod-form-company__set">
                                                                            <dl>
                                                                                <dt style={{ backgroundColor: '#4C4C4C' }} >差分計算設定</dt>
                                                                                <dd>
                                                                                    <div className="mod-form-mail__text">
                                                                                        <select id="decimal_value" name="decimal_value" className="lay-master__camera_option_form" >
                                                                                            <option value="0">前回データ差分</option>
                                                                                            <option value="1">前日データ差分</option>
                                                                                        </select>
                                                                                    </div>
                                                                                </dd>
                                                                            </dl>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                            <div className="lay-search-company__form__col3" style={{ marginTop: '10px' }}>
                                                                <ul>
                                                                    <li>
                                                                        <div className="mod-form-company__set">
                                                                            <dl>
                                                                                <dt style={{ backgroundColor: '#4C4C4C', height: '50px' }} >基準時間<br />(データ表示時間)</dt>
                                                                                <dd>
                                                                                    <div className='mod-form-mail__text'>
                                                                                        <select id="standard_time" name="standard_time" className="lay-master__camera_option_form"
                                                                                            style={{ height: '50px' }}
                                                                                            value={item.standard_time || 0}
                                                                                            data-index={index}
                                                                                            onChange={(e) => handleMeasureInputChange(index, "standard_time", e.target.value)
                                                                                            }>
                                                                                            {Array.from({ length: 24 }).map((_, index) => (
                                                                                                <option key={index} value={index}>{index} 時</option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                                </dd>
                                                                            </dl>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        </div>

                                                    </div>

                                                    <div style={{ width: '10%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', position: 'relative', top: '-20px' }}>
                                                        <div>
                                                            <button type="button" className="mod-btn__admindelete add" onClick={() => handleGaugeRemove(index)}>{item.disabled_btn === "torikeshi" ? "取消" : "削除"} </button>
                                                        </div>
                                                    </div>

                                                </div>

                                                <hr className="div-border-bottom"></hr>

                                            </React.Fragment>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

// コンポーネントに渡された props を確認します
GaugeEdit.propTypes = {
    onClose: PropTypes.func.isRequired,
    message: PropTypes.string,
    getGaugeData: PropTypes.func.isRequired,
    searchImage: PropTypes.func.isRequired,
    submitAddPhotoAuto: PropTypes.func.isRequired,
    submitAddPhotoManual: PropTypes.func.isRequired,
    submitRemoveGaugeManual: PropTypes.func.isRequired,
    submitUpdateGaugeManual: PropTypes.func.isRequired,
    gauge_id: PropTypes.string,
    device_id: PropTypes.string,
    company_id: PropTypes.string,
    company_code: PropTypes.string,
    facility_id: PropTypes.number,
    camera_name_id: PropTypes.string
};

export default GaugeEdit;

