import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MailEdit from "../components/MailEdit";
import { fetchMailEdit, submitMailEdit} from "../actions/mailedit";

const mapStateToProps = (state) => {
  const { mailSend } = state;
  return {
    maildata: mailSend.maildata,
    loading: mailSend.loading,
    error: mailSend.error
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMailEdit,
      submitMailEdit
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MailEdit);