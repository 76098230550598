export function getUser() {
    let userdata = {};
    let item = localStorage.getItem('user');
    let autologin = false;
    if (item && item !== 'undefined') {
        let user = JSON.parse(item);
        if (user && user.token) {
            userdata = user;
            autologin = true;
        }
    }

    if (!autologin) {
        item = localStorage.getItem("user");
        if (item && item !== 'undefined') {
            let user = JSON.parse(item);
            if (user && user.token) {
                userdata = user;
            }
        }
    }
    return userdata;
}

export function checkUser() {
    const user = getUser();
    return (user && user.token);
}

export function authHeader() {
    const user = getUser();
    if (user && user.token) {
        return { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + user.token 
        };
    } else {
        return {};
    }
}
