import React, { useState, useEffect,useCallback  } from 'react';
import PropTypes from 'prop-types';
import { useLocation,useNavigate } from 'react-router-dom';
import './DeletedLogEdit.css';
import Modal from '../Modal/Modal';

//詳細情報の表示・編集
const DeletedEdit = ({ deletedlogedit = [], loading, error, fetchdeletedlogedit, fetchdeletedlogeditsmg, logMessages }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const company_id = queryParams.get('DeletedLogEdit'); 
    const [activeButton, setActiveButton] = useState(1); 
    // const [reload] = useState(false); 
    const [deletedLogEdit, setDeletedLogEdit] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage] = useState('');
    const [showCopyIcon, setShowCopyIcon] = useState(false); 
    const [logDetails, setLogDetails] = useState(null); 
    const [isLoadingData, setIsLoadingData] = useState(true);
    const [isLogDetailsLoading, setIsLogDetailsLoading] = useState(false); 

    //会社 ID が存在する場合に削除されたレコード データをロードする
    useEffect(() => {
        if (company_id) {
            setIsLoadingData(true); 
            setLogDetails(""); 
            fetchdeletedlogedit(company_id).finally(() => {
                setIsLoadingData(false); 
            });
        }
    }, [company_id, fetchdeletedlogedit]);

    useEffect(() => {
        if (deletedlogedit.length > 0) {
            setDeletedLogEdit(deletedlogedit);
        }
    }, [deletedlogedit]);

    useEffect(() => {
        if (!isLogDetailsLoading) {
            if (logMessages && logMessages.log_msg) {
                setLogDetails(logMessages.log_msg); 
            } else {
                setLogDetails("削除ログなし"); 
            }
        }
    }, [logMessages, isLogDetailsLoading]);
    
    // 選択したボタンの種類に基づいてログ情報を取得します
    const handleLogButton = useCallback(async (buttonType) => {
        setActiveButton(buttonType);
        setShowCopyIcon(false);
        setLogDetails(null);
        setIsLogDetailsLoading(true);
        try {
            await fetchdeletedlogeditsmg(company_id, buttonType);
        } catch (err) {
            setLogDetails("削除ログなし");
        } finally {
            setIsLogDetailsLoading(false);
        }
    }, [fetchdeletedlogeditsmg, company_id]);

    useEffect(() => {
        if (company_id) {
            handleLogButton(1);
        }
    }, [company_id, handleLogButton]);
    
    // ログ情報をコピーします
    const handleCopyClick = () => {
        const logDetails = document.getElementById("log_details");
        navigator.clipboard.writeText(logDetails.value)
        .then(() => {
            setShowCopyIcon(true); 
        })
        .catch(err => console.error('Error copying text: ', err));
    };

    // 前のページに戻る
    const handleBackClick = () => {
        const page = queryParams.get('page');  
        navigate(`/DeletedLogMaster?page=${page}`);  
    };
    
    return (
        <div id='Main' className='MainMail' >
            <div id='Contents'  className='ContentsMail' >
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                    />
                )}

                <div id="ajax-view-disp">
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>削除組織ログ詳細</span>
                                </div>

                                <div className="lay-master-set__frame" style={{ marginTop: '20px' }}>
                                    <div className="lay-master__table">
                                        <form id="DeletedEdit">
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-login-id">組織ID</th>
                                                        <th className="col-user-name">組織名</th>
                                                        <th className="col-user-name">管理ユニットID</th>
                                                        <th className="col-api-token">管理ユニット名</th>
                                                        <th className="col-api-token-date">削除日</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {isLoadingData ? (
                                                        <tr>
                                                            <td colSpan="5" style={{ textAlign: "center" }}>&nbsp;</td>
                                                        </tr>
                                                        ) : deletedLogEdit.length > 0 ? (
                                                            deletedLogEdit.map((val, index) => (
                                                                <tr key={index}>
                                                                    <td>{val.company_code}</td>
                                                                    <td>{val.company_name}</td>
                                                                    <td>{val.manage_unit_id}</td>
                                                                    <td>{val.manage_unit_name}</td>
                                                                    <td>{val.update_date}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                        <tr>
                                                            <td colSpan="5" style={{ textAlign: "center" }}>
                                                                <div className="mod-no-data">表示データが存在しません。</div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </form>
                                    </div>

                                </div>

                                <div style={{ width: '100%',
                                    marginTop: '20px', 
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center' 
                                }}>
                                    <div style={{ display: 'flex', gap: '10px' }}> 
                                        <div>
                                            <button type="button"
                                                className={`mod-btn__${activeButton === 1 ? 'logedit' : 'roundborderlog'} add`}
                                                disabled={activeButton === 1}
                                                onClick={() => handleLogButton(1)}>
                                                DB削除ログ
                                            </button>
                                        </div>

                                        <div>
                                            <button type="button" 
                                                className={`mod-btn__${activeButton === 2 ? 'logedit' : 'roundborderlog'} add`}
                                                disabled={activeButton === 2}
                                                onClick={() => handleLogButton(2)}>
                                                データ復旧
                                            </button>
                                        </div>

                                        <div>
                                            <button
                                                type="button"
                                                className={`mod-btn__${activeButton === 3 ? 'logedit' : 'roundborderlog'} add`}
                                                disabled={activeButton === 3}
                                                onClick={() => handleLogButton(3)}>
                                                S3削除ログ
                                            </button>
                                        </div>

                                        <div>
                                            <div style={{ display: 'flex' }}>
                                                <button
                                                    type="button"
                                                    className="mod-btn__deletedlog copy"
                                                    onClick={handleCopyClick}>
                                                    コピー
                                                    {showCopyIcon && <img id="icon-copy" src="/img/icon-sent.png" alt="Copied!" />}
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="add-btn" style={{ marginLeft: 'auto' }}>
                                        <button
                                            type="button"
                                            className="mod-btn__adminback back"
                                            onClick={handleBackClick}>
                                            戻る
                                        </button>
                                    </div>
                                </div>

                                <div className="log-details" style={{ marginTop: '20px' }}>
                                    <textarea id="log_details" readOnly value={isLogDetailsLoading ? '' : logDetails || ''}  />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// propsのデータ型を確認する
DeletedEdit.propTypes = {
    deletedlogedit: PropTypes.array, 
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchdeletedlogedit: PropTypes.func.isRequired
};

export default React.memo(DeletedEdit);
