import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  admindata: {},
  error: null
};
const adminListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADMIN_EDIT_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.ADMIN_EDIT_SUCCESS:
      return { ...state, loading: false, admindata: action.payload };
    case ActionTypes.ADMIN_EDIT_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.ADMIN_EDIT_SUBMIT_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.ADMIN_EDIT_SUBMIT_SUCCESS:
      return { ...state, loading: false};
    case ActionTypes.ADMIN_EDIT_SUBMIT_FAILURE:
      return { ...state, loading: false};
    default:
      return state;
  }
};

export default adminListReducer;
