import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CompanyEdit from "../components/CompanyEdit";
import { fetchCompanyEdit, registerCompany} from "../actions/companyedit";

const mapStateToProps = (state) => {
  const { companyEdit } = state;
  return {
    companydata: companyEdit.companydata,
    loading: true,
    error: companyEdit.error
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchCompanyEdit,
      registerCompany
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CompanyEdit);