import ActionTypes from "../constants/ActionTypes";
  
  const initialState = {
    isFetching: false,
    otpToken: null,
    errorMessage: null,
    accessToken: null,
    refreshToken: null,
    otpEnabled: false,
    user: {
      authorityClass: parseInt(localStorage.getItem('authorityClass') || 0, 10)
    },   
    otpAttemptCount: 0,
    errorCode: null,
  };
  
  const loginReducer = (state = initialState, action) => {
    switch (action.type) {
      case ActionTypes.RESEND_OTP_REQUEST:
        return { ...state, isFetching: true, };

      case ActionTypes.RESEND_OTP_SUCCESS:
        return {...state,isFetching: false, sessionId: action.sessionId, otpToken: action.payload,errorMessage: null };

      case ActionTypes.RESEND_OTP_FAILURE:
        return { ...state,isFetching: false, errorMessage: action.payload };

      case ActionTypes.REQUEST_SEND_LOGIN_INFORMATION:
        return { ...state,isFetching: true };

      case ActionTypes.RECEIVE_SEND_LOGIN_INFORMATION_SUCCESS:
        return { ...state, isFetching: false, accessToken: action.payload.accessToken, refreshToken: action.payload.refreshToken, otpEnabled: action.payload.otpEnabled, errorMessage: null , authorityClass: action.payload.authorityClass || state.authorityClass};

      case ActionTypes.RECEIVE_SEND_LOGIN_INFORMATION_FAILURE:
        return { ...state, isFetching: false, errorMessage: action.payload,  };

      case ActionTypes.RECEIVE_OTP_ENABLED:
        return {  ...state, otpEnabled: true, otpToken: action.payload.otpToken, session_id: action.payload.sessionId,  isFetching: false };

      case ActionTypes.CHECK_OTP_REQUEST:
        return { ...state, isFetching: true, errorMessage: action.payload};

      case ActionTypes.CHECK_OTP_SUCCESS:
        localStorage.setItem('accessToken', action.payload.accessToken);
        localStorage.setItem('refreshToken', action.payload.refreshToken);
        return { ...state, accessToken: action.payload.accessToken, refreshToken: action.payload.refreshToken, otpEnabled: false, isFetching: false, errorMessage: null };

      case ActionTypes.CHECK_OTP_FAILURE:
        console.log('Received Error Message:', action.payload); 
        return { ...state, isFetching: false, errorMessage: action.payload };

      case ActionTypes.INCREMENT_OTP_ATTEMPT:
        console.log('Incrementing OTP attempt:', state.otpAttemptCount + 1);
        return { ...state, otpAttemptCount: state.otpAttemptCount + 1 };

      case ActionTypes.REFRESH_ACCESS_TOKEN_REQUEST:
        return { ...state, isFetching: true };

      case ActionTypes.REFRESH_ACCESS_TOKEN_SUCCESS:
        return { ...state,isFetching: false, accessToken: action.payload.accessToken, errorMessage: null };

      case ActionTypes.REFRESH_ACCESS_TOKEN_FAILURE:
        return { ...state, isFetching: false, errorMessage: action.payload };

      case ActionTypes.CLEAR_ERROR:
        return { ...state, errorMessage: null };

      case ActionTypes.FETCH_USER_SUCCESS:
        return { ...state, user: action.payload, errorMessage: null };

      case ActionTypes.FETCH_USER_FAILURE:
        return { ...state, errorMessage: action.payload };

      case ActionTypes.LOGOUT_USER:
        return { isFetching: false, otpToken: null, errorMessage: null, accessToken: null, refreshToken: null, user: null, otpAttemptCount: 0, errorCode: null };
        
      default:
        return state;
    }
  };
  
export default loginReducer;
  