import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import UserEdit from "../components/UserEdit";
import { fetchUserEdit, submitUserEdit} from "../actions/useredit";

const mapStateToProps = (state) => {
  const { userEdit } = state;
  return {
    userdata: userEdit.userdata,
    loading: userEdit.loading,
    error: userEdit.error
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUserEdit,
      submitUserEdit
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);