import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Menu from "../components/Menu";
import { refreshAccessToken, logout } from "../actions/user";

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      logout,
      refreshAccessToken
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(Menu);
