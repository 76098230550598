import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import './AdminNew.css';
import Modal from '../Modal/Modal';
import { checkInput } from '../../helpers/validation';
import CompanySearch from '../CompanySearch/CompanySearch';
import { format } from 'date-fns';

const AdminNew = ({ registerAdmin, getManagementCompanyInfo, loading, error, companyinfo = {} }) => {
    const navigate = useNavigate();

    const [loginid, setLoginId] = useState('');
    const [password, setPassword] = useState('');
    const [admin_name, setAdmin_name] = useState('');
    const [lilzgauge_api_token, setLilzgauge_api_token] = useState('');
    const [lilzgauge_api_token_date, setLilzgauge_api_token_date] = useState('');
    const [company_name, setCompany_name] = useState('');
    const [company_id, setCompany_id] = useState(0);
    const [access_token, setAccessToken] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [email, setEmail] = useState('');
    const [errorMessages, setErrorMessages] = useState({});

    // Function to generate a random token
    const createAccessToken = () => {
        const randomBytes = new Uint8Array(32);
        window.crypto.getRandomValues(randomBytes);
        const tokenString = Array.from(randomBytes, byte => byte.toString(16).padStart(2, '0')).join('');
        setAccessToken(tokenString);
    };

    const handleCheckboxChange = (e) => {
        const checked = e.target.checked;
        setIs2FAEnabled(checked);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    useEffect(() => {
        getManagementCompanyInfo();
    }, [getManagementCompanyInfo]);

    useEffect(() => {
        if (Object.keys(companyinfo).length > 0) {
            setCompany_name(companyinfo.company_name);
            setCompany_id(companyinfo.company_id);
        }
        
    }, [companyinfo]);

    useEffect(() => {
        if (lilzgauge_api_token) {
            const today = new Date();
            setLilzgauge_api_token_date(format(today, 'yyyy/MM/dd'));
        }
        else {
            setLilzgauge_api_token_date('');
        }
    }, [lilzgauge_api_token]);


    const handleBackClick = () => {
        navigate(-1);
    };

    const validateInput = () => {
        const errors = {};
        // Check for empty input fields
        var errmessage = checkInput(loginid, 5, 16, 'ログインID');
        if (errmessage) {
            // setModalMessage(errmessage);
            // setIsModalOpen(true);
            // return;
            errors.loginid = errmessage;
        }
        errmessage = checkInput(password, 8, 32, 'パスワード');
        if (errmessage) {
            // setModalMessage(errmessage);
            // setIsModalOpen(true);
            // return;
            errors.password = errmessage;
        }
        // Check if 2FA is enabled and email is empty
        if (is2FAEnabled && !email.trim()) {
            // setErrorEmail('メールアドレスを入力してください。');
            // return;
            errors.is2FAEnabled = 'メールアドレスを入力してください。';
        }
        // Check if email format is valid
        else if (is2FAEnabled && !isEmailValid(email)) {
            // setErrorEmail('メールアドレスが正しくありません。');
            // return;
            errors.is2FAEnabled = 'メールアドレスが正しくありません。';
        }

        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };

    const handleRegister = async () => {
        if (!validateInput()) {
            return;
        }
        try {
            const payload = {
                login_id: loginid,
                password: password,
                admin_name: admin_name,
                lilz_gauge_token: lilzgauge_api_token,
                lilz_gauge_token_date: lilzgauge_api_token_date,
                access_token: access_token,
                otp_enable: is2FAEnabled ? 1 : 0,
                mail_address: email,
                company_id: company_id,
            };
            const response = await registerAdmin(payload);
            setModalMessage(response?.message || "登録しました。");
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage("登録に失敗しました。");
            setIsModalOpen(true);
        }
    };
    
    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleSelect = (item, id) => {
        setCompany_name(item); 
        setCompany_id(id);
    };

    const isEmailValid = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    return (
        <div id='Main' className='MainEdit' >
           <div id='Contents' className='ContentEdit'>
                {/* メッセジー画面 */}
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                    />
                )}
                {/* 組織検索画面 */}
                {showPopup && (<CompanySearch onClose={handleClosePopup} onSelect={handleSelect} />)}
                {/* 組織一覧画面 */}
                <div id="ajax-view-disp">
                    {/* 管理者一覧 */}
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>管理者新規登録</span>
                                </div>
                                <div className="lay-master-set__form">
                                    <form id="AdminNew">
                                        <div className="wrapper" style={{ textAlign: 'left' }}>
                                            <div className="mail-send__set">
                                                <div className="mail-input">
                                                    {/* ボタン */}
                                                    <div style={{ width: '130%', marginTop: '20px' }}>
                                                        <div style={{ display: 'flex' }}>

                                                            <div className='add-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__roundborder add"
                                                                    onClick={handleRegister}
                                                                >
                                                                    登録
                                                                </button>
                                                            </div>
                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__adminback back"
                                                                    onClick={handleBackClick}
                                                                >
                                                                    戻る
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 設定項目 */}
                                                    <div className="mod-form__set" style={{ marginTop: '40px' }}>
                                                        <dl>
                                                            <dt>ログインID</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        id="login_id"
                                                                        name="login_id"
                                                                        value={loginid}
                                                                        onChange={(e) => setLoginId(e.target.value)}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="error_msg">
                                                                        {errorMessages.loginid && <span style={{ color: 'red' }}>{errorMessages.loginid}</span>}
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>パスワード</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        id="password"
                                                                        className="password-mask"
                                                                        name="password"
                                                                        value={password}
                                                                        onChange={(e) => setPassword(e.target.value)}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="error_msg">
                                                                        {errorMessages.password && <span style={{ color: 'red' }}>{errorMessages.password}</span>}
                                                                    </div>
                                                                </div>

                                                            </dd>
                                                        </dl>
                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>ユーザ名</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        id="admin_name"
                                                                        name="admin_name"
                                                                        value={admin_name}
                                                                        onChange={(e) => setAdmin_name(e.target.value)}
                                                                        autoComplete="off"
                                                                    />
                                                                </div>

                                                            </dd>
                                                        </dl>
                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt className='textarea'>APIトークン</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <textarea
                                                                        type="text"
                                                                        id="lilzgauge_api_token"
                                                                        name="lilzgauge_api_token"
                                                                        value={lilzgauge_api_token}
                                                                        onChange={(e) => setLilzgauge_api_token(e.target.value)}
                                                                    />
                                                                </div>

                                                            </dd>
                                                        </dl>
                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>APIトークン登録日</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        className='readonlyCls'
                                                                        type="text"
                                                                        id="lilzgauge_api_token_date"
                                                                        name="lilzgauge_api_token_date"
                                                                        value={lilzgauge_api_token_date}
                                                                        // onChange={(e) => setLilzgauge_api_token_date(e.target.value)}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </dd>
                                                        </dl>
                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>アクセストークン</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        className='readonlyCls'
                                                                        type="text"
                                                                        id="access_token"
                                                                        name="access_token"
                                                                        value={access_token}
                                                                        onChange={(e) => setAccessToken(e.target.value)}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </dd>
                                                            <button type="button" className="mod-btn__roundborder add" style={{ marginLeft: '10px' }} onClick={createAccessToken}>
                                                                生成
                                                            </button>
                                                        </dl>
                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>管理組織</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        className='readonlyCls'
                                                                        type="text"
                                                                        id="company_name"
                                                                        name="company_name"
                                                                        value={company_name}
                                                                        onChange={(e) => setCompany_name(e.target.value)}
                                                                        readOnly
                                                                    />
                                                                </div>

                                                            </dd>
                                                            <button type="button" className="mod-btn__roundborder add" style={{ marginLeft: '10px' }} onClick={handleOpenPopup} >
                                                                組織検索
                                                            </button>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt className="double">二段階認証</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <label style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                                                    <input type="checkbox" className="checkbox-input" style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} checked={is2FAEnabled} onChange={handleCheckboxChange}/>
                                                                    <span style={{ marginRight: '10px' }}></span>
                                                                    二段階認証を使用する
                                                                </label>

                                                                <div className="mod-form__company">
                                                                    <input
                                                                        type="text"
                                                                        name="mail_2fa"
                                                                        value={email}
                                                                        onChange={handleEmailChange}
                                                                        readOnly={!is2FAEnabled}
                                                                        style={{ background: is2FAEnabled ? '#fff' : 'lightgray' }} 
                                                                        autoComplete="off"/>
                                                                    <div className="error_msg">
                                                                        {errorMessages.is2FAEnabled && <span style={{ color: 'red' }}>{errorMessages.is2FAEnabled}</span>}
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

AdminNew.propTypes = {
    registerAdmin: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    getManagementCompanyInfo: PropTypes.func.isRequired,
    companyinfo: PropTypes.object.isRequired
};

export default React.memo(AdminNew);