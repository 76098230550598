import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './UserEdit.css';
import Modal from '../Modal/Modal';
import { checkInput } from '../../helpers/validation';
import CompanySearch from '../CompanySearch/CompanySearch';

const UserEdit = ({ fetchUserEdit, submitUserEdit, userdata = {}, loading, error }) => {
    const navigate = useNavigate();
    const [login_id, setLoginId] = useState('');
    const [user_name, setUser_name] = useState('');
    const [company_name, setCompany_name] = useState('');
    const [company_id, setCompany_id] = useState('');
    const [authority_class, setAuthority_class] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const user_id = queryParams.get('user_id');
    const [isFetching, setIsFetching] = useState(false);
    const [is2FAEnabled, setIs2FAEnabled] = useState(false);
    const [email, setEmail] = useState('');
    const [errorMessages, setErrorMessages] = useState({});
    const [islock, setLock] = useState(0);
    // const [userType, setUserType] = useState('user');
    const [localLoading, setLocalLoading] = useState(false);

    useEffect(() => {
        if (user_id) {
            setLocalLoading(true);
            fetchUserEdit(user_id).finally(() => setLocalLoading(false));
        }
    }, [user_id, fetchUserEdit]);

    const handleCheckboxChange = (e) => {
        const checked = e.target.checked;
        setIs2FAEnabled(checked);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    useEffect(() => {
        if (Object.keys(userdata).length > 0) {
            setLoginId(userdata.login_id || '');
            setUser_name(userdata.user_name || '');
            setCompany_name(userdata.company_name || '');
            setAuthority_class(userdata.authority_class || '');
            setIs2FAEnabled(userdata.otp_enable || '');
            setEmail(userdata.mail_address || '');
            setLock(userdata.is_delete || '');
            setCompany_id(userdata.company_id || '');
        }
    }, [userdata]);

    const handleBackClick = () => {
        const page = queryParams.get('page');
        navigate(`/UserMaster?page=${page}`);
    };

    const handlePWChange = () => {
        navigate(`/passwordChangeDisplay?userType=user&loginId=${user_id}`);
    };

    const validateInput = () => {
        const errors = {};
        // Check for empty input fields
        var errmessage = checkInput(login_id, 5, 16, 'ログインID');
        if (errmessage) {
            errors.login_id = errmessage;
        }
        if (!company_name) {
            errors.company_name = '登録組織は必須項目です。';
        }
        // Check if 2FA is enabled and email is empty
        if (is2FAEnabled && !email.trim()) {
            errors.is2FAEnabled = 'メールアドレスを入力してください。';
        }
        // Check if email format is valid
        else if (is2FAEnabled && !isEmailValid(email)) {
            errors.is2FAEnabled = 'メールアドレスが正しくありません。';
        }

        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSaveUser = async () => {
        try {
            if (!validateInput()) {
                return;
            }
            const payload = {
                user_id: user_id,
                company_id: company_id,
                login_id: login_id,
                user_name: user_name,
                authority_class: authority_class,
                otp_enable: is2FAEnabled ? 1 : 0,
                mail_address: email
            };
            const response = await submitUserEdit(payload);
            setModalMessage(response?.message || "変更しました。");
            setIsModalOpen(true);
            setIsConfirmationModal(false);
        } catch (error) {
            setModalMessage("登録に失敗しました。");
            setIsModalOpen(true);
        }
    };

    const handleDeleteUser = () => {
        setModalMessage('削除しますか？');
        setIsConfirmationModal(true);
        setIsModalOpen(true);
    };

    const handleLockUser = () => {
        if (!islock) {
            setModalMessage('ロックしますか？');
        }
        else {
            setModalMessage('解除しますか？');
        }
        setIsConfirmationModal(true);
        setIsModalOpen(true);

    };

    const handleConfirm = async () => {
        setIsModalOpen(false);
        setIsFetching(true);
        try {
            let payload = {};
            let lock_flag;
            if (modalMessage === "ロックしますか？") {
                payload = {
                    user_id: user_id,
                    login_id: login_id,
                    company_id: company_id,
                    user_name: user_name,
                    authority_class: authority_class,
                    otp_enable: is2FAEnabled,
                    mail_address: email,
                    is_delete: 2
                };
                // setLock(1);
                lock_flag = 2;
            }
            else if (modalMessage === "解除しますか？") {
                payload = {
                    user_id: user_id,
                    login_id: login_id,
                    company_id: company_id,
                    user_name: user_name,
                    authority_class: authority_class,
                    otp_enable: is2FAEnabled ? 1 : 0,
                    mail_address: email,
                    is_delete: 0
                };
                // setLock(0);
                lock_flag = 0;
            }
            else if (modalMessage === "削除しますか？") {
                payload = {
                    user_id: user_id,
                    login_id: login_id,
                    company_id: company_id,
                    user_name: user_name,
                    authority_class: authority_class,
                    otp_enable: is2FAEnabled ? 1 : 0,
                    mail_address: email,
                    is_delete: 1
                };
                lock_flag = 1;
            }
            const response = await submitUserEdit(payload);
            let message = "";
            if (response.status === 200) {
                setLock(lock_flag);
                message = response.respons ? response.respons : "変更しました。";
                handleBackClick();
            }
            else {
                message = response.respons ? response.respons : "変更に失敗しました。";
            }
            setModalMessage(message);
            setIsModalOpen(true);
            setIsConfirmationModal(false);

        } catch (error) {
            setModalMessage(error.message || "変更に失敗しました。");
            setIsModalOpen(true);
            setIsConfirmationModal(false);
        }

    }

    const handleOpenPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleSelect = (item, id) => {
        setCompany_name(item);
        setCompany_id(id);
    };

    // Validate email format
    const isEmailValid = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    return (
        <div id='Main' className='MainEdit'  >
            <div id='Contents' className='ContentEdit'>
                {/* メッセジー画面 */}
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                        onConfirm={handleConfirm}
                        showCancelButton={isConfirmationModal}
                    />
                )}
                {/* 組織検索画面 */}
                {showPopup && (<CompanySearch onClose={handleClosePopup} onSelect={handleSelect} />)}
                {/* 組織一覧画面 */}
                <div id="ajax-view-disp">
                    {/* 管理者一覧 */}
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>ユーザ編集</span>
                                </div>
                                <div className="lay-master-set__form">
                                    <form id="UserEdit">
                                        <div className="wrapper" style={{ textAlign: 'left' }}>
                                            <div className="mail-send__set">
                                                <div className="mail-input">
                                                    {/* ボタン */}
                                                    <div style={{ width: '50%', marginTop: '20px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div>
                                                                <button
                                                                    type="button"
                                                                    className={"mod-btn__adminedit morelong islock ? 'disabled' : ''"}
                                                                    onClick={handlePWChange}
                                                                    disabled={islock}
                                                                >
                                                                    パスワード編集
                                                                </button>
                                                            </div>
                                                            <div className='add-btn'>
                                                                <button
                                                                    type="button"
                                                                    className={"mod-btn__roundborder add islock ? 'disabled' : ''"}
                                                                    onClick={handleSaveUser}
                                                                    disabled={islock}
                                                                >
                                                                    更新
                                                                </button>
                                                            </div>
                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className={"mod-btn__admindelete add"}
                                                                    onClick={handleLockUser}
                                                                    // disabled={islock}
                                                                >
                                                                    {islock === 0 ? "ロック" : "解除"}
                                                                </button>
                                                            </div>
                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__admindelete add"
                                                                    onClick={handleDeleteUser}
                                                                >
                                                                    削除
                                                                </button>
                                                            </div>
                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__adminback back"
                                                                    onClick={handleBackClick}
                                                                >
                                                                    戻る
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* 設定項目 */}
                                                    <dl className="mod-form__set" style={{ marginTop: '40px' }} >
                                                        <dl>
                                                            <dt>登録組織</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        className='readonlyCls'
                                                                        type="text"
                                                                        id="company_name"
                                                                        name="company_name"
                                                                        value={localLoading ? '' : company_name}
                                                                        onChange={(e) => setCompany_name(e.target.value)}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </dd>
                                                            <button type="button" className={"mod-btn__roundborder add islock ? 'disabled' : ''"} style={{ marginLeft: '10px' }} onClick={handleOpenPopup} disabled={islock}>
                                                                組織検索
                                                            </button>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>ログインID</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        id="login_id"
                                                                        name="login_id"
                                                                        value={ localLoading ? '' : login_id}
                                                                        onChange={(e) => setLoginId(e.target.value)}
                                                                        autoComplete="off"
                                                                    />
                                                                    {errorMessages.login_id && <span style={{ color: 'red' }}>{errorMessages.login_id}</span>}
                                                                </div>

                                                            </dd>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>ユーザ名</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        id="user_name"
                                                                        name="user_name"
                                                                        value={localLoading ? '' : user_name}
                                                                        onChange={(e) => setUser_name(e.target.value)}
                                                                        autoComplete="off"
                                                                    />
                                                                </div>

                                                            </dd>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>設定</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <select
                                                                        id="authority_class"
                                                                        name="authority_class"
                                                                        value={localLoading ? '' : authority_class}
                                                                        onChange={(e) => setAuthority_class(e.target.value)}
                                                                    >
                                                                        <option value="0">一般</option>
                                                                        <option value="1">管理（一般）</option>
                                                                        <option value="9">管理（システム）</option>
                                                                    </select>
                                                                </div>
                                                            </dd>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt className="double">二段階認証</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <label style={{ fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                                                    <input type="checkbox" className="checkbox-input" style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} checked={is2FAEnabled} onChange={handleCheckboxChange} />
                                                                    <span style={{ marginRight: '10px' }}></span>
                                                                    二段階認証を使用する
                                                                </label>

                                                                <div className="mod-form__company">
                                                                    <input
                                                                        type="text"
                                                                        name="mail_2fa"
                                                                        value={localLoading ? '' : email}
                                                                        onChange={handleEmailChange}
                                                                        readOnly={!is2FAEnabled}
                                                                        style={{ background: is2FAEnabled ? '#fff' : 'lightgray' }} />
                                                                    <div className="error_msg">
                                                                        {errorMessages.is2FAEnabled && <span style={{ color: 'red' }}>{errorMessages.is2FAEnabled}</span>}
                                                                    </div>
                                                                </div>
                                                            </dd>
                                                        </dl>

                                                    </dl>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div className={`overlay ${(loading || isFetching) ? 'active' : ''}`}>
                    <div className={`spinner ${(loading || isFetching) ? 'active' : ''}`}></div>
                </div>
        </div>
    );
};

UserEdit.propTypes = {
    userdata: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchUserEdit: PropTypes.func.isRequired,
    submitUserEdit: PropTypes.func.isRequired
};

export default React.memo(UserEdit);