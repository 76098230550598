import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MaintenanceNew from "../components/MaintenanceNew";
import { registerMaintenance,fetchcompanynew } from "../actions/maintenancenew";

const mapStateToProps = (state) => {
  const { maintenancenew } = state;

  return {
    companynew: maintenancenew.companynew,
    loading: maintenancenew.loading,
    error: maintenancenew.error,
    alldatanumber: maintenancenew.alldatanumber
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {   
      registerMaintenance,
      fetchcompanynew
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceNew);