import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";
import message from "../constants/message";

export const fetchCameraChangeRequest = () => ({
  type: ActionTypes.FETCH_CAMERA_CHANGE_DATA_REQUEST,
});

export const fetchCameraChangeSuccess = (data) => ({
  type: ActionTypes.FETCH_CAMERA_CHANGE_DATA_SUCCESS,
  payload: data,
});

export const fetchCameraChangeFailure = (error) => ({
  type: ActionTypes.FETCH_CAMERA_CHANGE_DATA_FAILURE,
  payload: error,
});


export const fetchCameraChange = (id, company_id) => {
  return async (dispatch) => {
    dispatch(fetchCameraChangeRequest());
    try {
      if (!window.debug) {
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
          dispatch(fetchCameraChangeFailure('No access token found in localStorage'));
          return;
        }

        const response = await axiosInstance.get(BASE_URL + `path=camera&type=get-data&id=${id}&company_id=${company_id}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        const data = response.data.respons || {};
        dispatch(fetchCameraChangeSuccess(data));
      }
      else {
        const data = {
          "device_id": "24688000518003ee",
          "disused_flag": "1",
          "image_source": "% d",
          "api_facility_id": "9193807b-8dc5-4ff8-ac8f-124026307f5e",
          "camera_name": "24688000518003ee",
          "camera_display_name": "24688000518003ee_display",
          "facility_id": "1",
          "facility_name": "施設１",
          "company_id": "kamimaru",
          "company_code": "カミマル株式会社	",
          "company_name": "%s",
          "firmware_version": "0.0.e1.2.8",
          "battery_percent": "100",
          "create_date": "2024/11/19",
          "facility_list": [{ "facility_id": "1", "facility_name": "施設１", "facility_name_display": "施設１" }, { "facility_id": "2", "facility_name": "施設2", "facility_name_display": "施設２" }],
          "gaugeInfoList": [
            { "gauge_id": "1", "gauge_name": "% s", "gauge_display_name": "% s", "image_source": "% s" },
            { "gauge_id": "2", "gauge_name": "% s", "gauge_display_name": "% s", "image_source": "% s" }]
        };

        dispatch(fetchCameraChangeSuccess(data));
      }
    } catch (error) {
      dispatch(fetchCameraChangeFailure(error.message));
    }
  };
};

export const fetchGetGaugeDataRequest = () => ({
  type: ActionTypes.FETCH_GET_GAUGE_DATA_REQUEST,
});

export const fetchGetGaugeDataSuccess = (dataCount) => ({
  type: ActionTypes.FETCH_GET_GAUGE_DATA_SUCCESS,
  payload: dataCount
});

export const fetchGetGaugeDataFailure = (error) => ({
  type: ActionTypes.FETCH_GET_GAUGE_DATA_FAILURE,
  payload: error,
});

export const fetchGetGaugeData = (gauge_id, device_id, company_id) => {
  return async (dispatch) => {
    dispatch(fetchGetGaugeDataRequest());
    try {
      if (!window.debug) {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axiosInstance.get(BASE_URL + `path=gauge&type=get-data&id=${gauge_id}&company_id=${company_id}&device_id=${device_id}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });
        dispatch(fetchGetGaugeDataSuccess(response.data.respons));
        const data = {
          status: 200,
          gaugedata: response.data.respons
        };
        return data;
      }
      else {
        const data = {
          "gauge_id": "088a8c72-fe94-44eb-8eae-c2238d25a5fb",
          "gauge_name": "名称未設定",
          "gauge_display_name": "名称未設定",
          "rotate_flag": 1,
          "image_source": "https://rakusuru-bucket-stg.s3.amazonaws.com/gauge_image/cbe4da2e-520c-43e0-ba2c-d4388848ba28/0OBF15e79729-aa6c-4eda-a2d5-cd1df2a41818/20241216/080000/0OCF55b8c860-0af2-4e9c-be1e-7b2071e656e8/annotated_image.jpg",
          "gauge_measure_list": []
        };

        dispatch(fetchGetGaugeDataSuccess(data));
        return data;
      }
    } catch (error) {
      // Dispatch failure action if the API call fails
      dispatch(fetchGetGaugeDataFailure(error.message));
      return {
        status: 500,
        message: error.message
      };
    }
  };
};

export const sendCameraChangeRequest = () => ({
  type: ActionTypes.CAMERA_EDIT_SUBMIT_REQUEST,
});

export const sendCameraChangeSuccess = (message) => ({
  type: ActionTypes.CAMERA_EDIT_SUBMIT_SUCCESS,
  payload: message,
});

export const sendCameraChangeFailure = (error) => ({
  type: ActionTypes.CAMERA_EDIT_SUBMIT_FAILURE,
  payload: error,
});

export const submitCameraEdit = (company_id, camera_id, disused_flg, image_source, facility_id, display_name, display_name_id, first_use_day) => {
  return async (dispatch) => {
    dispatch(sendCameraChangeRequest());
    try {
      if (!window.debug) {
        const accessToken = localStorage.getItem('accessToken');
        const payload = {
          company_id: company_id,
          device_id: camera_id,
          disused_flg: disused_flg,
          image_source: image_source,
          facility_id: facility_id,
          display_name: display_name,
          display_name_id: display_name_id,
          first_year_month_day: first_use_day
        };
        const response = await axiosInstance.post(BASE_URL + 'path=camera&type=update-info-data', payload, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        if (response.data.status === 200) {
          dispatch(sendCameraChangeSuccess(message.UPDATE_SUCCESS_MSG));
          return message.UPDATE_SUCCESS_MSG;
        }
      }
      else {
        const msg = message.UPDATE_SUCCESS_MSG;
        dispatch(sendCameraChangeSuccess(msg));
        return msg;
      }
    } catch (error) {
      // Dispatch failure action if the API call fails
      dispatch(sendCameraChangeFailure(error.message));
    }
  };
};
// =================================================================================
export const fetchCameraDeleteRequest = () => ({
  type: ActionTypes.CAMERA_DELETE_REQUEST
});

export const fetchCameraDeleteSuccess = (camera) => ({
  type: ActionTypes.CAMERA_DELETE_SUCCESS,
  payload: camera
});

export const fetchCameraDeleteFailure = (error) => ({
  type: ActionTypes.CAMERA_DELETE_FAILURE,
  payload: error
});

export const submitCameraDelete = (company_id, camera_id, is_delete, display_name_id) => {
  return async (dispatch) => {
    dispatch(fetchCameraDeleteRequest());
    try {
      if (!window.debug) {
        const accessToken = localStorage.getItem('accessToken');
        const payload = {
          company_id: company_id,
          device_id: camera_id,
          is_delete: is_delete,
          display_name_id: display_name_id
        };
        const response = await axiosInstance.post(BASE_URL + 'path=camera&type=delete-data', payload, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        // Check response here
        if (response.data.status === 200) {
          const returnmessage = is_delete ? message.DELETED_MSG : message.COMPLETED_DELETE_MSG;
          dispatch(fetchCameraDeleteSuccess(returnmessage));
          return returnmessage;
        }
      }
      else {
        const returnmessage = is_delete ? message.DELETED_MSG : message.COMPLETED_DELETE_MSG;
        dispatch(fetchCameraDeleteSuccess(returnmessage));
        return returnmessage;
      }

    } catch (error) {
      dispatch(fetchCameraDeleteFailure(error.message));
    }
  };
};

// ==============================DBに存在しない画像を検索==============================
export const fetchImagesRequest = () => ({
  type: ActionTypes.CAMERA_ADDPHOTO_AUTO_REQUEST
});

export const fetchImagesSuccess = (camera) => ({
  type: ActionTypes.CAMERA_ADDPHOTO_AUTO_SUCCESS,
  payload: camera
});

export const fetchImagesFailure = (error) => ({
  type: ActionTypes.CAMERA_ADDPHOTO_AUTO_FAILURE,
  payload: error
});

export const searchImage = (payload) => {
  const timeout = 1000 * 300; // 5 seconds timeout
  return async (dispatch) => {
    dispatch(fetchImagesRequest());
    try {
      // window.debug = true;
      if (!window.debug) {
        const controller = new AbortController(); // Signal to abort the fetch
        // const signal = controller.signal;

        // Timeout logic
        const timeoutId = setTimeout(() => {
          controller.abort();
        }, timeout);

        const accessToken = localStorage.getItem('accessToken');
        const response = await axiosInstance.post(BASE_URL + 'path=gauge&type=search-list-photo', payload, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        clearTimeout(timeoutId); // Clear timeout on success

        if (response.data.status === 200) {
          const data = {
            status: 200,
            photo_list: response.data.respons.photo_list || []
          };
          dispatch(fetchImagesSuccess(data));
          return data;
        }
      }
      else {
        const respons = {
          status: 200,
          respons: {
            photo_list: [
              { datetime: "2024/11/19", gauge_name: "gaugeNAME", gauge_url: "https://rakusuru-bucket-stg.s3.amazonaws.com/gauge_image/hakaru_demo/d5f510d8-7b3f-4859-8ba7-0bf3b07bd619/20220725/100000/0M7P42d7e471-45e4-4313-8737-e367877f454e/annotated_image.jpg", img_location: "", camera_url: "", camera_name: "name123", s3_gauge_existed: 1 },
              { datetime: "2024/11/19", gauge_name: "gaugeNAME", gauge_url: "https://rakusuru-bucket-stg.s3.amazonaws.com/gauge_image/hakaru_demo/d5f510d8-7b3f-4859-8ba7-0bf3b07bd619/20220725/100000/0M7P42d7e471-45e4-4313-8737-e367877f454e/annotated_image.jpg", img_location: "", camera_url: "", camera_name: "name123", s3_gauge_existed: 1 },
              { datetime: "2024/11/19", gauge_name: "gaugeNAME", gauge_url: "https://rakusuru-bucket-stg.s3.amazonaws.com/gauge_image/hakaru_demo/d5f510d8-7b3f-4859-8ba7-0bf3b07bd619/20220725/100000/0M7P42d7e471-45e4-4313-8737-e367877f454e/annotated_image.jpg", img_location: "", camera_url: "", camera_name: "name123", s3_gauge_existed: 1 }]
          }
        };
        const data = {
          status: 200,
          photo_list: respons.respons.photo_list
        };
        dispatch(fetchImagesSuccess(data));
        return data;
      }

    } catch (error) {
      if (error.name === 'AbortError') {
        dispatch(fetchImagesFailure("タイムアウトで検索停止。"));
        return {
          status: 500,
          message: "タイムアウトで検索停止。"
        };
      } else {
        dispatch(fetchImagesFailure(error.message));
        return {
          status: 500,
          message: error.message
        };
      }
    }
  };
};

// ==============================自動追加部分の追加==============================
export const sendAddPhotoAutoRequest = () => ({
  type: ActionTypes.CAMERA_ADDPHOTO_AUTO_REQUEST
});

export const sendAddPhotoAutoSuccess = (camera) => ({
  type: ActionTypes.CAMERA_ADDPHOTO_AUTO_SUCCESS,
  payload: camera
});

export const sendAddPhotoAutoFailure = (error) => ({
  type: ActionTypes.CAMERA_ADDPHOTO_AUTO_FAILURE,
  payload: error
});

export const submitAddPhotoAuto = (payload, uploadedNum) => {
  return async (dispatch) => {
    dispatch(sendAddPhotoAutoRequest());
    try {
      if (!window.debug) {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axiosInstance.post(BASE_URL + 'path=gauge&type=insert-list-photo-auto', payload, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        // Check response here
        if (response.data.status === 200) {
          const returnmessage = "OK";
          dispatch(sendAddPhotoAutoSuccess(returnmessage));
          return returnmessage;
        }
      }
      else {
        const returnmessage = "OK";
        dispatch(sendAddPhotoAutoSuccess(returnmessage));
        return returnmessage;
      }

    } catch (error) {
      if (error.name === 'AbortError') {
        dispatch(sendAddPhotoAutoFailure(uploadedNum + "件がアップロード、タイムアウトのため停止された。"));
        return (uploadedNum + "件がアップロード、タイムアウトのため停止された。");
      } else {
        dispatch(sendAddPhotoAutoFailure("画像のアップロードが失敗しました。"));
        return "画像のアップロードが失敗しました。";
      }
    }
  };
};

// ==============================手動追加部分の追加==============================
export const sendAddPhotoManualRequest = () => ({
  type: ActionTypes.CAMERA_ADDPHOTO_MANUAL_REQUEST
});

export const sendAddPhotoManualSuccess = (camera) => ({
  type: ActionTypes.CAMERA_ADDPHOTO_MANUAL_SUCCESS,
  payload: camera
});

export const sendAddPhotoManualFailure = (error) => ({
  type: ActionTypes.CAMERA_ADDPHOTO_MANUAL_FAILURE,
  payload: error
});

export const submitAddPhotoManual = (payload) => {
  return async (dispatch) => {
    dispatch(sendAddPhotoManualRequest());
    try {
      if (!window.debug) {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axiosInstance.post(BASE_URL + 'path=gauge&type=insert-list-photo-manual', payload, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        // Check response here
        if (response.data.status === 200) {
          const returnmessage = "OK";
          dispatch(sendAddPhotoManualSuccess(returnmessage));
          return returnmessage;
        }
      }
      else {
        const returnmessage = "OK";
        dispatch(sendAddPhotoManualSuccess(returnmessage));
        return returnmessage;
      }

    } catch (error) {
      const returnmessage = "NG";
        dispatch(sendAddPhotoManualFailure(returnmessage));
        return returnmessage;
    }
  };
}

// ==============================Gauge計測項目削除==============================
export const submitRemoveGaugeRequest = () => ({
  type: ActionTypes.REMOVE_GAUGE_REQUEST
})

export const submitRemoveGaugeSuccess = (camera) => ({
  type: ActionTypes.REMOVE_GAUGE_SUCCESS,
  payload: camera
});

export const submitRemoveGaugeFailure = (error) => ({
  type: ActionTypes.REMOVE_GAUGE_FAILURE,
  payload: error
});

export const submitRemoveGaugeManual = (payload) => {
  return async (dispatch) => {
    dispatch(submitRemoveGaugeRequest());
    try {
      if (!window.debug) {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axiosInstance.post(BASE_URL + 'path=gauge&type=delete-measurement-data', payload, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        // Check response here
        if (response.data.status === 200) {
          // const returnmessage = "OK";
          dispatch(submitRemoveGaugeSuccess(response.respons.is_delete));
          return response;
        }
      }
      else {
        const response = {status:200, respons:{is_delete:0}};
        dispatch(submitRemoveGaugeSuccess(response.respons.is_delete));
        return response;
      }

    } catch (error) {
        dispatch(submitRemoveGaugeFailure(error.message));
        return error.message;
    }
  };
}

// ==============================Gaugeデータ更新==============================
export const submitUpdateGaugeRequest = () => ({
  type: ActionTypes.UPDATE_GAUGE_REQUEST
})

export const submitUpdateGaugeSuccess = (camera) => ({
  type: ActionTypes.UPDATE_GAUGE_SUCCESS,
  payload: camera
});

export const submitUpdateGaugeFailure = (error) => ({
  type: ActionTypes.UPDATE_GAUGE_FAILURE,
  payload: error
});

export const submitUpdateGaugeManual = (payload) => {
  return async (dispatch) => {
    dispatch(submitUpdateGaugeRequest());
    try {
      if (!window.debug) {
        const accessToken = localStorage.getItem('accessToken');
        const response = await axiosInstance.post(BASE_URL + 'path=gauge&type=update-info-data', payload, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          }
        });

        // Check response here
        if (response.data.status === 200) {
          const response = {status:200, respons:"更新しました。"};
          dispatch(submitUpdateGaugeSuccess(response.respons));
          return response;
        }
      }
      else {
        const response = {status:200, respons:"更新しました。"};
        dispatch(submitUpdateGaugeSuccess(response.respons));
        return response;
      }

    } catch (error) {
        dispatch(submitUpdateGaugeFailure(error.message));
        return error.message;
    }
  };
}