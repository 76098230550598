
import React, { useState, useEffect ,useCallback} from 'react';
import PropTypes from 'prop-types';
import {  useLocation,useNavigate } from 'react-router-dom';
import './Facility.css';

//FacilityList コンポーネントを定義する
const FacilityList = ({ facility = [], loading, error, fetchFacility,alldatanumber }) => {
    const navigate = useNavigate();
    const location = useLocation(); 
    const [facilityList, seFacilityList] = useState(['']);
    const itemsPerPage = 20;
    const [pageindex] = useState(1);
    const [search, setSearch] = useState({ company_code: '', company_name: '', facility_id: '', facility_name: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(alldatanumber / itemsPerPage);
    const [isLoading, setisLoading] = useState(true);
    
    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const page = parseInt(query.get('page'), 10) || 1;  
        setCurrentPage(page);
        setisLoading(true);
        setSearch({ company_code: '', company_name: '', facility_id : '' , facility_name : '' });
        //pageindex および itemsPerPage パラメーターを指定して function を呼び出します。
        fetchFacility(page, itemsPerPage)
            .finally(() => setisLoading(false));
    }, [fetchFacility, itemsPerPage,location.search]);
    
    //施設が有効かどうかを確認する。
    useEffect(() => {
        if (facility) {
            seFacilityList(facility);
        }
    }, [facility]);

    //検索入力フィールドの変更イベントを処理します。
    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };
    
    const handleEditFacility = useCallback((facility_id) => {
        //施設IDを指定して施設編集ページに移動します。
        navigate(`/FacilityEdit?facility_id=${facility_id}&page=${currentPage}`);
    }, [navigate, currentPage]);
     
    //検索フォームが送信されたときにイベントを処理する。
    const handleSearchSubmit = (event) => {
        if (event) event.preventDefault();

        fetchFacility(pageindex, itemsPerPage, search.company_code, search.company_name, search.facility_id, search.facility_name);
    };
    
    //ページネーションを表示。
    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
        // ページネーションインターフェイスをレンダリングします
        return (
            <div className="pager">
                <ul className="pagination">
                    {pages.map(page => (
                        <li key={page} className="page-item">
                        <div onClick={() => onPageChange(page)} className={`${currentPage === page ? 'active' : ''}`}>
                            <span>{page}</span>
                        </div>
                    </li>
                    
                    ))}
                </ul>
            </div>
        );
    };

    const handMenuClick = (page) => {
        if (page === currentPage) {
            fetchFacility(page, itemsPerPage, search.company_code, search.company_name);
        }
        navigate(`?page=${page}`);
        setCurrentPage(page);
    }
     
    //ページネーションでのページ変更イベントの処理
    const handlePageChange = (page) => {
        // navigate(`?page=${page}`);
        // setCurrentPage(page);
        // fetchFacility(page, itemsPerPage, search.company_code, search.company_name);
        handMenuClick(page);
    };
    
    return (
        <div id='Main'className='MainMail' >
           <div id='Contents'className='ContentsMail' >
                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>施設一覧</span>
                                </div>
                                {/*データ検索ボックス */}
                                <form onSubmit={handleSearchSubmit} style={{ marginTop: '20px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>

                                            <li>
                                                <div className="mod-form-graph__set d-none">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>

                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>施設ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="facility_id" name="facility_id" value={search.facility_id} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>施設名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="facility_name" name="facility_name" value={search.facility_name} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>


                                            <li>
                                            <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>
                                        </ul>
                                    </div>

                                </form>

                                {/*テーブルリストの作成*/}
                                <div className="lay-master-set__frame"style={{ marginTop: '20px' }}>
                                    <div className="lay-master__table">
                                        <button type="button" className='mod-btn__roundborderNew'onClick={() => navigate('/FacilityNew')}  > 新規作成 </button>
                                        <form id="FacilityList"style={{ marginTop: '15px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-facility-cot1">組織名</th>
                                                        <th className="col-facility-cot2">施設ID</th>
                                                        <th className="col-facility-cot3">施設名</th>
                                                        <th className="col-facility-cot4">作成日</th>
                                                        <th className="col-facility-cot5">編集</th>
                                                    </tr>
                                                </thead>
                                                {!isLoading && (
                                                    <tbody>
                                                        {facilityList.length > 0 ? (
                                                            facilityList.map((val, index) => (
                                                                <tr key={index} className={val.is_delete === 1 ? 'disabled' : ''}>
                                                                    <td className="col-facility-cot1" style={{ textAlign: 'left' }} >{val.company_name}</td>
                                                                    <td className="col-facility-cot2">{val.facility_id}</td>
                                                                    <td className="col-facility-cot3" style={{ textAlign: 'left' }} >{val.facility_name}</td>
                                                                    <td className="col-facility-cot4">{val.c_datetime}</td>
                                                                    <td className="col-facility-cot5">
                                                                        <button
                                                                            type="button"
                                                                            id={`facility_info_edit_${index}`}
                                                                            className="mod-btn__companylist" 
                                                                            onClick={() => handleEditFacility(val.facility_id)}
                                                                            >  編集
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                {/*データが利用できない場合の表示 */}
                                                                <td colSpan="5" style={{ textAlign: "center" }}>
                                                                    <div className="mod-no-data">表示データが存在しません。</div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                 )}
                                            </table>
                                        </form>
                                        {/*テーブルのページ */}
                                        {totalPages > 1 && (
                                            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                                        )}                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

FacilityList.propTypes = {
    facility: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchFacility: PropTypes.func.isRequired
};

export default React.memo(FacilityList);