import React from 'react';
import Header from './head';
import SideMenu from './sidemenu';
import { Outlet } from 'react-router-dom';
import './Menu.css';


const Menu = ({ logout }, refreshAccessToken) => {
    return (
        <div>
            <Header logout={logout}/>
            <SideMenu />
            <div id="Main">
                <Outlet /> {}
            </div>
        </div>
    );
};


export default Menu;
