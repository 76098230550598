//バリデーションメッセージ
const messages = {
    REQUIRED_MSG: "%sは必須項目です。",
    REQUIRED_MSG_ONLY: "必須入力です。",
    DIFFERS_MSG: "元のパスワードと新しいパスワードが同じです。",
    MATCHING_MSG: "新しいパスワードの入力と一致していません。",
    ALPHA_NUMBERIC_SYMBOL_MSG: "%sを半角英数字記号で入力してください。",
    ALPHA_NUMBERIC_SYMBOL_MSG_ONLY: "半角英数字記号で入力してください。",
    ALPHA_NUMBERIC_SYMBOL_MSG_ONLY_LENGTH: "半角英数字8文字以上で入力してください。",
    NUMERIC_MSG: "%sを半角整数文字入力してください。",
    MAX_LENGTH_MSG: "%sを半角英数字%s文字以下で入力してください。",
    MAX_LENGTH_MSG_ONLY: "%D半角英数字%s文字以下で入力してください。",
    MESSAGE_MAX_LENGTH_MSG: "%sを%s文字以下で入力してください。",
    MIN_LENGTH_MSG: "%sを半角英数字%s文字以上で入力してください。",
    MIN_LENGTH_MSG_ONLY: "%D半角英数字%s文字以上で入力してください。",
    VALID_EMAIL_MSG: "%sのメールアドレスが正しくありません。",
    INCORRECT_FORMAT_MSG: "%sを正しく入力してください。",
    UNIQUE_MSG: "%sはすでに登録されてます。",
    INCORRECT_SELECT_MSG: "%sを正しく選択してください。",
    PERCENTAGE_RANGE_MSG: "%sは100以下入力してください。",
    MEASURE_NAME_MUST_UNIQUE_MSG: "同一名称が登録されています。",

    //セッションメッセージ
    ADMIN_PASS_UPDATE_MSG: "管理者のパスワードを変更しました。",
    NO_ADMIN_MSG: "管理者が登録されていません。",
    TOKEN_ERROR_MSG: "ログイン認証エラー。",
    REGISTER_SUCCESS_MSG: "登録しました。",
    DUPLICATE_MAINTENANCE_MSG: "すでにメッセージが登録されています。",
    UPDATE_SUCCESS_MSG: "変更しました。",
    DELETED_MSG: "削除しました。",
    COMPLETED_DELETE_MSG: "完全に削除しました。",
    CANCELLED_MSG: "取消しました。",
    HIDDEN_MSG: "非表示にしました。",
    DISPLAY_MSG: "再表示にしました。",
    INCORRECT_PASS_MSG: "パスワードが正しくありません。",
    DELETED_CONFIRM_MSG: "削除しますか？",
    CANCELLED_CONFIRM_MSG: "取消しますか？",
    EMPTY_EMAIL_MSG: "メールアドレスを入力してください。",
    EMPTY_MAINTENANCE_MSG: "メッセージを入力してください。",
    LOCKED_CONFIRM_MSG: "ロックしますか？",
    UNLOCKED_CONFIRM_MSG: "解除しますか？",
    LOCKED_MSG: "ロックしました。",
    UNLOCKED_MSG: "解除しました。",
    HIDDEN_CONFIRM_MSG: "非表示にしますか？",
    DISPLAY_CONFIRM_MSG: "再表示にしますか？",
    FACILITY_CHANGE_CONFIRM_MSG: "施設を変更するとカメラの設定も解除されます。施設変更をしますか？",
    GET_API_DATA_FAILE: "APIデータ取得に失敗しました。",
    GET_API_DATA_FAILE_PART: "一部のデータ取得に失敗しました。",
    GET_API_DATA_SUCCESS: "データ取得しました。",

    //画像パス
    IMG_PATH: "assets/admin/images/",
    NOT_FOUND_IMG_PATH: "assets/admin/images/no_image.jpg"
};
export default messages;