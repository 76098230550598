import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  facility: [],
  error: null,
  alldatanumber: 0
};
const facilityListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_FACILITY_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_FACILITY_SUCCESS:
      return { ...state, loading: false, facility: action.payload.data_list, alldatanumber: action.payload.all_data_count};
    case ActionTypes.FETCH_FACILITY_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case ActionTypes.REGISTER_FACILITY_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.REGISTER_FACILITY_SUCCESS:
      return { ...state, loading: false};
    case ActionTypes.REGISTER_FACILITY_FAILURE:
      return { ...state, loading: false};
    default:
      return state;
  }
};

export default facilityListReducer;
