import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";


// 企業の新しいリストを取得するリクエストを開始します
export const fetchCompanynewRequest = () => ({
    type: ActionTypes.FETCH_COMPANYNEW_REQUEST,
});

// 新しい企業リストを受信すると正常に処理されました
export const fetchCompanynewSuccess = (companynew) => ({
    type: ActionTypes.FETCH_COMPANYNEW_SUCCESS,
    payload: companynew
});

// 新しい企業リストを取得する際の失敗を処理します
export const fetchCompanynewFailure = (error) => ({
    type: ActionTypes.FETCH_COMPANYNEW_FAILURE,
    payload: error
});

// 新しい企業リストをロードする関数
export const fetchcompanynew = (pageindex, itemsPerPage, company_code, company_name) => {
    return async (dispatch) => {
        dispatch(fetchCompanynewRequest());
        try {
            if (!window.debug) {
                let strFilter = "";
                if (company_code) {
                    strFilter = `&company_code=${company_code}`;
                }
                if (company_name) {
                    strFilter = `&company_name=${company_name}`;
                }

                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + `path=maintenance&type=get-list-data-company&page=${pageindex}&number_per_page=${itemsPerPage}`+ strFilter, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });
                
                const data = response.data.respons || {"data_list": [], "all_data_count":0};
                dispatch(fetchCompanynewSuccess(data));
            }
            else {
                const data = {"data_list": [{
                    'company_id': 1, 'company_code': 'konoike', 'company_name': '鴻池運輸株式会社', "manage_unit_id": "d596b52c-aaa0-4681-8395-20ab0a7f6640	",
                    "manage_unit_name": "MU999_レンタル品管理用	", "create_date": "2020/07/07", "is_hidden": 0
                },
                { 'company_id': 2, 'company_code': 'konoike', 'company_name': 'デモ会社', "manage_unit_name": "MU999_レンタル品管理用	", "create_date": "2020/07/07", "is_hidden": 0 },
                { 'company_id': 3, 'company_code': 'snb', 'company_name': '新日本ビルサービス株式会社', "manage_unit_name": "MU999_レンタル品管理用	", "create_date": "2020/07/07", "is_hidden": 0 },
                { 'company_id': 4, 'company_code': 'co-jsp', 'company_name': '株式会社JSP', "manage_unit_name": "MU999_レンタル品管理用	", "create_date": "2020/07/07", "is_hidden": 1 },
                { 'company_id': 5, 'company_code': 'okada-syouten', 'company_name': '岡田商店', "manage_unit_name": "MU999_レンタル品管理用	", "create_date": "2020/07/07", "is_hidden": 0 }
                ], "all_data_count": 5};
                dispatch(fetchCompanynewSuccess(data));
            }

        } catch (error) {
            dispatch(fetchCompanynewFailure(error.message));
        }
    };
};

// 新しい管理情報の登録リクエストを開始します
export const registerMaintenanceRequest = () => ({
    type: ActionTypes.REGISTER_MAINTENANCE_REQUEST
});

// 管理情報の登録処理が成功しました
export const registerMaintenanceSuccess = (message) => ({
    type: ActionTypes.REGISTER_MAINTENANCE_SUCCESS,
    payload: message
});

// 管理情報登録時の失敗処理
export const registerMaintenanceFailure = (error) => ({
    type: ActionTypes.REGISTER_MAINTENANCE_FAILURE,
    payload: error
});

// 管理情報を新規登録する関数
export const registerMaintenance = (payload) => {
    return async (dispatch) => {
        dispatch(registerMaintenanceRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + 'path=maintenance&type=insert-info-data', payload, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });
                
                let message = response.data.respons;
                if (response.data.status === 200 && response.data.respons === true ) {
                    message = "登録しました。"; 
                }
                dispatch(registerMaintenanceSuccess(message));
                return { message }; 
            } else {
                const msg = "登録しました。";
                // const response = { data: { respons: msg } };
                dispatch(registerMaintenanceSuccess(msg));
                return { message: msg };
            }
        } catch (error) {
            const message = error.response?.data?.respons || "通信エラーが発生しました。";
            dispatch(registerMaintenanceFailure(message));
            return { message };
        }
    };
};