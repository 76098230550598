import React, { useState, useEffect,useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Modal from '../Modal/Modal';
import './MailEdit.css';

//メール情報を編集する
const MailEdit = ({ fetchMailEdit, submitMailEdit, maildata = {}, loading, error }) => {
    const navigate = useNavigate();
    const [company_id, setCompany_id] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [abnomal_alert_flg, setAbnormalAlertFlag] = useState(false);
    const [battery_alert_flg, setBatteryAlertFlag] = useState(false);
    const [battery_alert_amount, setBatteryAlertAmount] = useState(''); 
    const [mail_to, setMailToList] = useState(['']);
    const [mail_cc, setMailCcList] = useState(['']);
    const [mail_bcc, setMailBccList] = useState(['']);
    const [errors, setErrors] = useState({});
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [localLoading, setLocalLoading] = useState(false); 

    //メールデータをダウンロードする
    const handleAddMailTo = () => {
        setMailToList([...mail_to, '']); 
    };

    const handleAddMailCc = () => {
        setMailCcList([...mail_cc, '']);  
    };

    const handleAddMailBcc = () => {
        setMailBccList([...mail_bcc, '']);  
    };

    const handleChangeMailTo = (index, value) => {
        const newMailList = [...mail_to];
        newMailList[index] = value|| '';
        setMailToList(newMailList);
    };

    const handleChangeMailCc = (index, value) => {
        const newMailListCc = [...mail_cc];
        newMailListCc[index] = value|| '';
        setMailCcList(newMailListCc);
    };

    const handleChangeMailBcc = (index, value) => {
        const newMailListBcc = [...mail_bcc];
        newMailListBcc[index] = value|| '';
        setMailBccList(newMailListBcc);
    };

    // useEffect(() => {
    //     const id = queryParams.get('company_id');
    //     setCompany_id(id);
    //     if (id) {
    //         fetchMailEdit(id);
    //     }
    // }, [location.search]);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search); 
        const id = queryParams.get('company_id');
        setCompany_id(id);
        if (id) {
            setLocalLoading(true); 
            fetchMailEdit(id).finally(() => setLocalLoading(false)); 

        }
    }, [location.search, fetchMailEdit]); 
    
    //新しいデータが利用可能になったときに状態を更新する
    useEffect(() => {
        if (maildata) {
            setMailToList(maildata.mail_to || ['']);  
            setMailCcList(maildata.mail_cc || ['']);  
            setMailBccList(maildata.mail_bcc || ['']);  
            setAbnormalAlertFlag(maildata.abnomal_alert_flg === 1);
            setBatteryAlertFlag(maildata.battery_alert_flg === 1);
            setBatteryAlertAmount(maildata.battery_alert_amount || '');  
        }
    }, [maildata]);
    
    //前のページに戻る
    const handleBackClick = () => {
        const page = queryParams.get('page');  
        navigate(`/MailMaster?page=${page}`);  
    };

    //メール形式を確認する
    const validateEmail = (email) => {
        const trimEmail = email.trim(); 
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(trimEmail);
    };
    

    //すべてのメールの形式を確認する
    const validateAllEmails = () => {
        let isValid = true;
        const newErrors = {};
        mail_to.forEach((mail, index) => {
            if (mail && !validateEmail(mail)) {
                newErrors[`mail_to_${index}`] = "メールアドレスが正しくありません。";
                isValid = false;
            }
        });

        mail_cc.forEach((mail, index) => {
            if (mail && !validateEmail(mail)) {
                newErrors[`mail_cc_${index}`] = "メールアドレスが正しくありません。";
                isValid = false;
            }
        });

        mail_bcc.forEach((mail, index) => {
            if (mail && !validateEmail(mail)) {
                newErrors[`mail_Bcc_${index}`] = "メールアドレスが正しくありません。";
                isValid = false;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    // 変更を保存
    const handleSaveEmailEdit = async () => {
        const isValid = validateAllEmails();
    
        if (battery_alert_amount > 100 || battery_alert_amount < 0) {
            setErrors(prevErrors => ({
                ...prevErrors,
                battery_alert_amount: '電池残量は100以下入力してください。'
            }));
            return;
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                battery_alert_amount: undefined 
            }));
        }
    
        if (!isValid) {
            return;
        }
    
        try {
            const payload = {
                mail_to: mail_to.map(mail => mail.trim()).filter(mail => mail !== ''),
                mail_cc: mail_cc.map(mail => mail.trim()).filter(mail => mail !== ''),
                mail_bcc: mail_bcc.map(mail => mail.trim()).filter(mail => mail !== ''),
                abnomal_alert_flg: abnomal_alert_flg ? 1 : 0,
                battery_alert_flg: battery_alert_flg ? 1 : 0,
                battery_alert_amount: battery_alert_amount,
                company_id: company_id
            };
            const response = await submitMailEdit(payload);
            setModalMessage(response?.message || "変更しました。");
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage("登録に失敗しました。");
            setIsModalOpen(true);
        }
    };    
    
    // 確認時の処理
    const handleConfirmAction = async () => {
        setIsModalOpen(false); 
        setIsFetching(true); 
        try {
            let response;
            const message = response?.data?.respons?.msg || '操作が成功しました。';
            setModalMessage(message);

            navigate(-1);
        } catch (error) {
            setModalMessage(error.message || 'エラーが発生しました。再試行してください。');
            setIsConfirmationModal(false);
            setIsModalOpen(true); 
        } finally {
            setIsFetching(false); 
        }
    };
    
    return (
        <div id='Main' className='MainEdit'  >
            <div id='Contents'className='ContentEdit'>
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => {
                            setIsModalOpen(false);
                            // navigate(-1); 
                        }}                        
                        message={modalMessage} 
                        onConfirm={handleConfirmAction}
                        showCancelButton={isConfirmationModal}
                    />
                )}

                <div id="ajax-view-disp">
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>メール情報編集 </span>
                                </div>
                                <div className="lay-master-set__form">
                                    <form id="MailEdit">
                                        <div className="wrapper" style={{ textAlign: 'left' }}>
                                            <div className="mail-send__set">
                                                <div className="mail-input">

                                                    <div style={{ width: '100%', marginTop: '20px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className='add-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__roundborder add"
                                                                    onClick={handleSaveEmailEdit}
                                                                >
                                                                    更新
                                                                </button>
                                                            </div>
                                                            
                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__adminback back"
                                                                    onClick={handleBackClick}
                                                                >
                                                                    戻る
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="mod-form__set">
                                                        
                                                        <div className="checkbox-frame" style={{ marginBottom: '10px' }}>
                                                            <div style={{ display: 'flex' }}>
                                                                <div>
                                                                    <input type="checkbox" className="checkbox-input" 
                                                                        style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} 
                                                                        checked={ localLoading ? false : abnomal_alert_flg}
                                                                        onChange={(e) => setAbnormalAlertFlag(e.target.checked)}
                                                                    />
                                                                </div>
                                                                
                                                                <div>
                                                                    <span style={{ fontSize: '18px' }}>
                                                                        データが異常値と判定された際にメールを送信する
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="checkbox-frame">
                                                            <div style={{ marginBottom: '30px', display: 'flex' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div>
                                                                        <label>
                                                                            <input type="checkbox" className="checkbox-input" style={{ WebkitAppearance: 'checkbox', marginRight: '2px' }} 
                                                                                checked={ localLoading ? false : battery_alert_flg}
                                                                                onChange={(e) => setBatteryAlertFlag(e.target.checked)} 
                                                                            />
                                                                        </label>
                                                                    </div>
                                                                    <div>
                                                                        <span style={{ fontSize: '18px' }}>
                                                                            電池残量が低下した場合にメールを送信する
                                                                        </span>
                                                                        {errors.battery_alert_amount && (
                                                                            <div className="error_mail" style={{ color: 'red' }}>
                                                                                {errors.battery_alert_amount}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="battery-input">
                                                                    <input
                                                                        type="text"
                                                                        id="battery_alert_amount"
                                                                        name="battery_alert_amount"
                                                                        value={ localLoading ? '' : battery_alert_amount}
                                                                        onChange={(e) => {
                                                                            const value = e.target.value;
                                                                            if (/^\d*$/.test(value)) {
                                                                                setBatteryAlertAmount(value);
                                                                            }
                                                                        }}  
                                                                        pattern="\d*"
                                                                        maxLength="3"
                                                                        style={{background: "#fff"}}
                                                                        autoComplete="off"
                                                                    />
                                                                    <span>%</span>
                                                                    
                                                                </div>

                                                                
                                                            </div>
                                                        </div>


                                                        <div className="mail-input">
                                                            <div style={{ width: '120%', marginTop: '10px' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div>
                                                                        <span>TO</span>
                                                                    </div>
                                                          
                                                                    <div className="add-btn">
                                                                        <button
                                                                            type="button"
                                                                            className="mod-btn__mailedit add"
                                                                            onClick={handleAddMailTo}
                                                                            disabled={mail_to.length >= 10} 
                                                                            >追加
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {mail_to.map((mail, index) => (
                                                                <div key={index} className="mod-form__set">
                                                                    <dl style={{ marginTop: '10px' }}>
                                                                        <dt>{`宛先${index + 1}`}</dt>  
                                                                            <dd style={{ width: '50%' }}>
                                                                            <div className="mod-form__text">
                                                                                <input
                                                                                    type="text"
                                                                                    name={`mail_to_${index}`}
                                                                                    value={ localLoading ? '' : mail}
                                                                                    autoComplete="off" 
                                                                                    onChange={(e) => handleChangeMailTo(index, e.target.value)}
                                                                                />
                                                                                {errors[`mail_to_${index}`] && (
                                                                                    <div className="error_mail" style={{ color: 'red' }}>
                                                                                        {`宛先${index + 1}のメールアドレスが正しくありません。`}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            ))}
                                                        </div>

                                                        <div className="mail-input">
                                                            <div style={{ width: '120%', marginTop: '10px' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div>
                                                                        <span>CC</span>
                                                                    </div>
                                                                    
                                                                    <div className="add-btn">
                                                                        <button
                                                                            type="button"
                                                                            className="mod-btn__mailedit add"
                                                                            onClick={handleAddMailCc}
                                                                            disabled={mail_cc.length >= 10}
                                                                            >追加
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {mail_cc.map((mail, index) => (
                                                                <div key={index} className="mod-form__set">
                                                                    <dl style={{ marginTop: '10px' }}>
                                                                        <dt>{`宛先${index + 1}`}</dt>  
                                                                            <dd style={{ width: '50%' }}>
                                                                            <div className="mod-form__text">
                                                                                <input
                                                                                    type="text"
                                                                                    name={`mail_cc_${index}`}
                                                                                    value={ localLoading ? '' : mail}
                                                                                    autoComplete="off" 
                                                                                    onChange={(e) => handleChangeMailCc(index, e.target.value)}
                                                                                />
                                                                                {errors[`mail_cc_${index}`] && (
                                                                                    <div className="error_mail" style={{ color: 'red' }}>
                                                                                        {`宛先${index + 1}のメールアドレスが正しくありません。`}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            ))}
                                                        </div>

                                                        <div className="mail-input">
                                                            <div style={{ width: '120%', marginTop: '10px' }}>
                                                                <div style={{ display: 'flex' }}>
                                                                    <div>
                                                                        <span>BCC</span>
                                                                    </div>
                                                                    <div className="add-btn">
                                                                        <button
                                                                            type="button"
                                                                            className="mod-btn__mailedit add"
                                                                            onClick={handleAddMailBcc}
                                                                            disabled={mail_bcc.length >= 10}
                                                                            >追加
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {mail_bcc.map((mail, index) => (
                                                                <div key={index} className="mod-form__set">
                                                                    <dl style={{ marginTop: '10px' }}>
                                                                        <dt>{`宛先${index + 1}`}</dt>  
                                                                            <dd style={{ width: '50%' }}>
                                                                            <div className="mod-form__text">
                                                                                <input
                                                                                    type="text"
                                                                                    name={`mail_Bcc_${index}`}
                                                                                    value={ localLoading ? '' : mail}
                                                                                    autoComplete="off" 
                                                                                    onChange={(e) => handleChangeMailBcc(index, e.target.value)}
                                                                                />
                                                                                {errors[`mail_Bcc_${index}`] && (
                                                                                    <div className="error_mail" style={{ color: 'red' }}>
                                                                                        {`宛先${index + 1}のメールアドレスが正しくありません。`}
                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        </dd>
                                                                    </dl>
                                                                </div>
                                                            ))}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`overlay ${(loading || isFetching) ? 'active' : ''}`}>
                <div className={`spinner ${(loading || isFetching) ? 'active' : ''}`}></div>
            </div>
        </div>
    );
};

MailEdit.propTypes = {
    maildata: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchMailEdit: PropTypes.func.isRequired,
    submitMailEdit: PropTypes.func.isRequired
};

export default React.memo(MailEdit);