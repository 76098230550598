import ActionTypes from '../constants/ActionTypes';

const initialState = {
    loading: false,
    cameraData: {},
    gaugeData: {},
    error: null,
    dataCount: null
};

const cameraChangeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.FETCH_CAMERA_CHANGE_DATA_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.FETCH_CAMERA_CHANGE_DATA_SUCCESS:
            return { ...state, loading: false, cameraData: action.payload };
        case ActionTypes.FETCH_CAMERA_CHANGE_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.CAMERA_DELETE_REQUEST:
            return { ...state, loading: false, dataCount: action.payload };
        case ActionTypes.CAMERA_DELETE_FAILURE:
            return { ...state, loading: false, error: action.payload };
        case ActionTypes.CAMERA_DELETE_SUCCESS:
            return { ...state, loading: true };
        case ActionTypes.FETCH_GET_GAUGE_DATA_REQUEST:
            return { ...state, loading: true };
        case ActionTypes.FETCH_GET_GAUGE_DATA_SUCCESS:
            return { ...state, loading: false, gaugeData: action.payload };
        case ActionTypes.FETCH_GET_GAUGE_DATA_FAILURE:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export default cameraChangeReducer;
