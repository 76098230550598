import ActionTypes from "../constants/ActionTypes";

const initialState = {
  loading: false,
  companynew: [],
  error: null,
  alldatanumber: 0
};
const maintenancenewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_COMPANYNEW_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.FETCH_COMPANYNEW_SUCCESS:
      return { ...state, loading: false, companynew: action.payload.data_list, alldatanumber: action.payload.all_data_count  };
    case ActionTypes.FETCH_COMPANYNEW_FAILURE:
      return { ...state, loading: false, error: action.payload };

      case ActionTypes.REGISTER_MAINTENANCE_REQUEST:
        return { ...state, loading: true };
      case ActionTypes.REGISTER_MAINTENANCE_SUCCESS:
        return { ...state, loading: false, message: action.payload };
      case ActionTypes.REGISTER_MAINTENANCE_FAILURE:
        return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default maintenancenewReducer;
