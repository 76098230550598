import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './MaintenanceList.css';
import MailSearch from './MailSearch';
import Modal from '../Modal/Modal';

//メンテナンスリストの表示と管理
const MaintenanceList = ({ maintenance = [], submitMaintenance,deletemaintenance,loading, error, fetchMaintenance, alldatanumber }) => {
    const navigate = useNavigate();
    const location = useLocation(); 
    const [maintenanceList, setMaintenanceList] = useState([]);
    const [search, setSearch] = useState({ company_code: '', company_name: '' });
    const itemsPerPage = 20;
    const [reload, setReload] = useState(false); 
    const [pageindex, setPageIdx] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [modalMessage, setModalMessage] = useState('');
    const [isConfirmationModal, setIsConfirmationModal] = useState(false);
    const [isFetching, setIsFetching] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(''); 
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);
    const [selectedAuthority, setSelectedAuthority] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(alldatanumber / itemsPerPage);
    const [isLoading, setisLoading] = useState(true);
    
    // メンテナンスリストを更新
    useEffect(() => {
        if (maintenance) {
            setMaintenanceList(maintenance)
        }
    }, [maintenance]);
    
    // メンテナンスリストをロード
    useEffect(() => {
        setSearch({ company_code: '', company_name: '' });
        fetchMaintenance(pageindex, itemsPerPage)
            .finally(() => setisLoading(false));
    }, [fetchMaintenance,pageindex,reload,location]);
    
    //編集ポップアップを開く
    const handleOpenPopup = (company_id, authority, message) => {
        setCurrentMessage(message);
        setSelectedCompanyId(company_id);
        setSelectedAuthority(authority);
        setShowPopup(true);
    };
    
    //ページネーション
    const Pagination = ({ totalPages, currentPage, onPageChange }) => {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

        return (
            <div className="pager">
                <ul className="pagination">
                    {pages.map(page => (
                        <li key={page} className="page-item">
                        <div onClick={() => onPageChange(page)} className={`${currentPage === page ? 'active' : ''}`}>
                            <span>{page}</span>
                        </div>
                    </li>
                    
                    ))}
                </ul>
            </div>
        );
    };

    //ページを変更する
    const handlePageChange = (page) => {
        setPageIdx(page); 
        setCurrentPage(page);
        fetchMaintenance(page, itemsPerPage, search.company_code, search.company_name);
    };
    
    //削除の確認
    const handleConfirmDelete = async () => {
        if (!isConfirmationModal) {
            setModalMessage("削除しますか？");
            setIsConfirmationModal(true);
            setIsModalOpen(true);
            return;
        }
        setIsModalOpen(false);
        setIsFetching(true);
        try {
            const payload = Array.from(selectedItems).map(compoundId => {
                const [company_id, authority] = compoundId.split('-');
                return {
                    company_id: parseInt(company_id),
                    view_user_authority: parseInt(authority)
                };
            });
            if (payload.length === 0) {
                setModalMessage("削除するデータを選択してください。");
                setIsModalOpen(true);
                setIsFetching(false);
                return;
            }
            const response = await deletemaintenance(payload);
            setModalMessage(response || "完全に削除しました。");
            setIsConfirmationModal(false);
            setIsModalOpen(true);
            setSelectedItems(new Set());
            setReload(prev => !prev); 

        } catch (error) {
            setModalMessage(error.message || "エラーが発生しました。再試行してください。");
            setIsConfirmationModal(false);
            setIsModalOpen(true);
        } finally {
            setIsFetching(false);
        }
    };
    
    //新規追加ページに移動します
    const handleButtonClick = () => {
        navigate('/MaintenanceNew');
    };

    //ポップアップを閉じます
    const handleClosePopup = () => {
        setShowPopup(false);
    };

    //選択を変更する
    const handleCheckboxChange = (event, company_id, authority) => {
        const id = `${company_id}-${authority}`;
        const isChecked = event.target.checked;
        const newSelectedItems = new Set(selectedItems);
        if (isChecked) {
            newSelectedItems.add(id);
        } else {
            newSelectedItems.delete(id);
        }
        setSelectedItems(new Set(newSelectedItems)); 
    };

    //すべて選択またはすべて削除
    const toggleSelectAll = () => {
        const newSelectedItems = new Set(selectedItems);
        const allSelected = maintenanceList.every(val => newSelectedItems.has(`${val.company_id}-${val.view_user_authority}`));
    
        if (allSelected) {
            maintenanceList.forEach(val => {
                newSelectedItems.delete(`${val.company_id}-${val.view_user_authority}`);
            });
        } else {
            maintenanceList.forEach(val => {
                newSelectedItems.add(`${val.company_id}-${val.view_user_authority}`);
            });
        }
    
        setSelectedItems(new Set(newSelectedItems));
    };
    
    //検索値を変更する
    const handleSearchChange = (event) => {
        setSearch({ ...search, [event.target.name]: event.target.value });
    };
    
    //検索時の処理
    const handleSearchSubmit = async  (event) => {
        if (event) event.preventDefault();
        setSelectedItems(new Set());
        setisLoading(true);
        await fetchMaintenance(pageindex, itemsPerPage, search.company_code, search.company_name);
        setisLoading(false);
    };
    
    // 編集内容を保存
    const handleSaveMaintenance = async (editedMessage) => {
        try {
            const payload = {
                company_id: selectedCompanyId,
                view_user_authority: selectedAuthority,
                screen_message: editedMessage,
            };
            const response = await submitMaintenance(payload);
            setModalMessage(response || "変更しました。");
            setIsModalOpen(true);
            setReload(prev => !prev);  

        } catch (error) {
            setModalMessage(error.message || "登録に失敗しました。");
            setIsModalOpen(true);
        }
    };
    
    return (
        <div id='Main' className='MainMail'  >
            <div id='Contents' className='ContentsMail'>
            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    message={modalMessage}
                    onConfirm={isConfirmationModal ? handleConfirmDelete : null} 
                    showCancelButton={isConfirmationModal}  
                />
                )}


                {showPopup && (
                    <MailSearch onClose={handleClosePopup} message={currentMessage} onSave={handleSaveMaintenance} />
                )} 
                <div id="ajax-view-disp">
                    <div className="display-main" id="id_admin_list">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>メンテナンス設定一覧</span>
                                </div>
                                <form onSubmit={handleSearchSubmit} style={{ marginTop: '20px' }}>
                                    <div className='lay-search-graph__form__col3'>
                                        <ul>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織ID</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_code" name="company_code" value={search.company_code} onChange={handleSearchChange} autoComplete="off"/>
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="mod-form-graph__set">
                                                    <dl>
                                                        <dt>組織名</dt>
                                                        <dd>
                                                            <div className='mod-form-graph__text'>
                                                                <input type="text" id="company_name" name="company_name" value={search.company_name} onChange={handleSearchChange} autoComplete="off" />
                                                            </div>
                                                        </dd>
                                                    </dl>
                                                </div>
                                            </li>
                                            <li>
                                                <button type="submit" className="btn_facilitylist"> 表示 </button>
                                            </li>

                                        </ul>
                                    </div>

                                </form>

                                <div className="lay-master-set__frame" style={{ marginTop: '15px' }}>
                                    <div className="lay-master__table">
                                        
                                    <ul style={{ display: 'flex', alignItems: 'center', gap: '40px', listStyle: 'none', padding: '0', margin: '0 0 15px 0' }}>
                                            <li>
                                                <button type="submit" className="mod-btn__roundborder add " onClick={handleButtonClick}  
                                                 >追加 </button>
                                            
                                            </li>

                                            <li>
                                                <button type="submit" disabled={selectedItems.size === 0 || isFetching} onClick={handleConfirmDelete}  className={"btn_mailsearchback add add ishidden ? 'disabled' : '' " } >削除</button>
                                            </li>
                                        </ul>

                                        <div style={{ cursor: 'pointer', color: 'blue' ,  display: 'inline-block'}} onClick={toggleSelectAll}>
                                            全てのチェックを操作
                                        </div>
                                        
                                        <form id="MaintenanceList" style={{ marginTop: '15px' }}>
                                            <table>
                                                <thead>
                                                    <tr>
                                                        <th className="col-maintenance-cot1">削除チェック</th>
                                                        <th className="col-maintenance-cot2">組織ID</th>
                                                        <th className="col-maintenance-cot3">組織名</th>
                                                        <th className="col-maintenance-cot4">制御ユーザ権限</th>
                                                        <th className="col-maintenance-cot5">メッセージ</th>
                                                        <th className="col-maintenance-cot6">編集</th>
                                                    </tr>
                                                </thead>
                                                {!isLoading && (
                                                    <tbody>
                                                        {maintenanceList.length > 0 ? (
                                                            maintenanceList.map((val, index) => (
                                                                <tr key={index} className={val.deleted_row}>
                                                                    <td className="col-maintenance-cot1" >
                                                                        <input type="checkbox" checked={selectedItems.has(`${val.company_id}-${val.view_user_authority}`)}
                                                                            className="checkbox-input is_delete" style={{ WebkitAppearance: 'checkbox' }} 
                                                                            onChange={(e) => handleCheckboxChange(e, val.company_id, val.view_user_authority)}
                                                                        />
                                                                    </td>
                                                                    <td className="col-maintenance-cot2" style={{ textAlign: 'left' }} >{val.company_code}</td>
                                                                    <td className="col-maintenance-cot3" style={{ textAlign: 'left' }} >{val.company_name}</td>
                                                                    <td className="col-maintenance-cot4">
                                                                        {val.view_user_authority === 0 ? "一般":
                                                                        val.view_user_authority === 1 ? "管理（一般）"
                                                                        : ""}
                                                                    </td>
                                                                    <td className="col-maintenance-cot5" style={{ textAlign: 'left' }} >{val.screen_message}</td>
                                                                    <td className="col-maintenance-cot6">
                                                                        <button type="button" className="mod-btn__companylist" onClick={() => handleOpenPopup(val.company_id, val.view_user_authority, val.screen_message)}>
                                                                            編集
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="6" style={{ textAlign: "center" }}>
                                                                    <div className="mod-no-data">表示データが存在しません。</div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                  )}
                                            </table>
                                        </form>
                                        {totalPages > 1 && (
                                            <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                                        )}                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

MaintenanceList.propTypes = {
    maintenance: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    alldatanumber: PropTypes.number.isRequired,
    fetchMaintenance: PropTypes.func.isRequired,
    deletemaintenance: PropTypes.func.isRequired,
    submitMaintenance: PropTypes.func.isRequired
};

export default React.memo(MaintenanceList);