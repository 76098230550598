import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

//電子メール情報の編集リクエストを開始する
export const fetchMailEditRequest = () => ({
    type: ActionTypes.MAIL_EDIT_REQUEST
});

//編集が必要な電子メール情報を受信した場合、正常に処理されました
export const fetchMailEditSuccess = (data) => ({
    type: ActionTypes.MAIL_EDIT_SUCCESS,
    payload: data
});

//電子メール情報編集時の失敗の処理
export const fetchMailEditFailure = (error) => ({
    type: ActionTypes.MAIL_EDIT_FAILURE,
    payload: error
});

//編集が必要なメール情報をダウンロードする機能です
export const fetchMailEdit = (company_id) => {
    return async (dispatch) => {
        dispatch(fetchMailEditRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + 'path=mail&type=get-data&id=' + company_id, {}, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                const data = response.data.respons || {};
                dispatch(fetchMailEditSuccess(data));
                return response;
            }
            else {
                const data = {
                    "mail_to": ["test@gmail.com", "test1@gmail.com"],
                    "mail_cc": ["test234@gmail.com"],
                    "mail_bcc": ["test4567@gmail.com"],
                    "abnomal_alert_flag": 1,
                    "battery_alert_flag": 1,
                    "battery_alert_amount": 10,
                    "company_id": "1",
                }
                dispatch(fetchMailEditSuccess(data));
                return data;
            }

        } catch (error) {
            dispatch(fetchMailEditFailure(error.message));
        }
    };
};

//情報更新リクエストの開始
export const updateMailRequest = () => ({
    type: ActionTypes.MAIL_UPDATE_REQUEST,
});

//メール情報更新時に正常に処理されました
export const updateMailSuccess = (message) => ({
    type: ActionTypes.MAIL_UPDATE_SUCCESS,
    payload: message,
});

//メール情報更新時の失敗処理
export const updateMailFailure = (error) => ({
    type: ActionTypes.MAIL_UPDATE_FAILURE,
    payload: error,
});

//メール情報を更新する
export const submitMailEdit = (maildata) => {
    return async (dispatch) => {
        dispatch(updateMailRequest());
        try {
            if (!window.debug) {
            const accessToken = localStorage.getItem('accessToken');
            // const payload = {
            //     mail_to: maildata.mail_to,
            //     mail_cc: maildata.mail_cc,
            //     mail_bcc: maildata.mail_bcc,
            //     abnomal_alert_flg: maildata.abnomal_alert_flg,
            //     battery_alert_flg: maildata.battery_alert_flg,
            //     battery_alert_amount: maildata.battery_alert_amount,
            //     company_id: maildata.company_id
            // };

            const response = await axiosInstance.post(BASE_URL + 'path=mail&type=update-info-data', maildata, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            let message = response.data.respons;
                if (response.data.status === 200) {
                    message = "変更しました。"; 
                }
                dispatch(updateMailSuccess(message));
                return { message }; 
            } else {
                const msg = "変更しました。";
                // const response = { data: { respons: msg } };
                dispatch(updateMailSuccess(msg));
                return { message: msg };
            }
        } catch (error) {
            const message = error.response?.data?.respons || "通信エラーが発生しました。";
            dispatch(updateMailFailure(message));
            return { message };
        }
    };
};

