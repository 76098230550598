import ActionTypes from "../constants/ActionTypes";
import BASE_URL from "../constants/Api";
import axiosInstance from "../helpers/refreshTokenMiddleware";

export const fetchCompanyEditRequest = () => ({
    type: ActionTypes.COMPANY_EDIT_REQUEST
});

export const fetchCompanyEditSuccess = (company) => ({
    type: ActionTypes.COMPANY_EDIT_SUCCESS,
    payload: company
});

export const fetchCompanyEditFailure = (error) => ({
    type: ActionTypes.COMPANY_EDIT_FAILURE,
    payload: error
});


export const fetchCompanyEdit = (company_id) => {
    return async (dispatch) => {
        dispatch(fetchCompanyEditRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.get(BASE_URL + 'path=company&type=get-data&id=' + company_id, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                // Check response here
                const data = response.data.respons || {};
                dispatch(fetchCompanyEditSuccess(data));
                return response;
            }
            else {
                const data = {
                    "company_id": 1,
                    "company_code": "hakaru_rental",
                    "company_name": "レンタル品管理用",
                    "manage_unit_id": "d596b52c-aaa0-4681-8395-20ab0a7f6640",
                    "manage_unit_name": "MU999_レンタル品管理用",
                    "create_date": '2020/07/07',
                    "is_hidden": 1
                }
                dispatch(fetchCompanyEditSuccess(data));
                return data;
            }

        } catch (error) {
            dispatch(fetchCompanyEditFailure(error.message));
        }
    };
};


export const registerCompanyRequest = () => ({
    type: ActionTypes.REGISTER_COMPANY_REQUEST,
});

export const registerCompanySuccess = (message) => ({
    type: ActionTypes.REGISTER_COMPANY_SUCCESS,
    payload: message,
});

export const registerCompanyFailure = (error) => ({
    type: ActionTypes.REGISTER_COMPANY_FAILURE,
    payload: error,
});

export const registerCompany = (payload) => {
    return async (dispatch) => {
        dispatch(registerCompanyRequest());
        try {
            if (!window.debug) {
                const accessToken = localStorage.getItem('accessToken');
                const response = await axiosInstance.post(BASE_URL + 'path=company&type=update-info-data', payload, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                let message = response.data.respons;
                if (response.data.status === 200) {
                    message = "変更しました。"; 
                }
                dispatch(registerCompanySuccess(message));
                return { message }; 
            } else {
                const msg = "変更しました。";
                // const response = { data: { respons: msg } };
                dispatch(registerCompanySuccess(msg));
                return { message: msg };
            }
        } catch (error) {
            const message = error.response?.data?.respons || "通信エラーが発生しました。";
            dispatch(registerCompanyFailure(message));
            return { message };
        }
    };
};
