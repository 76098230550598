import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import './CompanyEdit.css';
import Modal from '../Modal/Modal';

import message from "../../constants/message";


const formatMessage = (template, ...values) => {
    return values.reduce((formatted, value) => formatted.replace('%s', value), template);
}

const CompanyEdit = ({ fetchCompanyEdit, registerCompany, companydata = {}, loading, error }) => {
    // const queryParams = new URLSearchParams(location.search);
    // const location = useLocation();
    const navigate = useNavigate();
    const sessioncompanyid = localStorage.getItem('company_id');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const company_id = queryParams.get('company_id');
    const [company_code, setCompany_code] = useState('');
    const [company_name, setCompany_name] = useState('');
    const [manage_unit_id, setManage_unit_id] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [manage_unit_name, setManage_unit_name] = useState('');
    const [create_date, setCreate_date] = useState('');
    const [errorMessages, setErrorMessages] = useState({});
    const [ishidden, setHidden] = useState(0);
    const isDisabled = sessioncompanyid === company_id;

    useEffect(() => {
        if (company_id) {
            fetchCompanyEdit(company_id);
        }
    }, [company_id, fetchCompanyEdit]);


    useEffect(() => {
    
        if ( Object.keys(companydata).length > 0) {
            setCompany_code(companydata.company_code || '');
            setCompany_name(companydata.company_name || '');
            setManage_unit_id(companydata.manage_unit_id || '');
            setManage_unit_name(companydata.manage_unit_name || '');
            setCreate_date(companydata.create_date || '');
            setHidden(companydata.is_hidden || 0);
        }
    }, [companydata ]);

    useEffect(() => {
        if (loading) {
            setCompany_code('');
            setCompany_name('');
            setManage_unit_id('');
            setManage_unit_name('');
            setCreate_date('');
            setHidden(0);
        }
    }, [loading]); 

    const handleBackClick = () => {
        const page = queryParams.get('page');  
        navigate(`/CompanyMaster?page=${page}`);  
    };
    const validateInput = () => {
        const errors = {};
        if (!company_code.trim()) {
            errors.company_code = formatMessage(message.REQUIRED_MSG, '組織ID');
        }

        if (!company_name.trim()) {
            errors.company_name = formatMessage(message.REQUIRED_MSG, '組織名');
        }

        setErrorMessages(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSaveCompany = async () => {
        try {
            if (!validateInput()) {
                return;
            }
            const payload = {
                "company_id": company_id,
                "company_code": company_code,
                "company_name": company_name
            };
            const response = await registerCompany(payload);
            // handleBackClick();
            setModalMessage(response?.message || "変更しました。");
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage("変更に失敗しました。");
            setIsModalOpen(true);
        }
    };

    const handleDeleteCompany = async () => {
        try {
            const payload = {
                "company_id": company_id,
                "company_code": company_code,
                "company_name": company_name,
                "is_delete": 1
            };
            const response = await registerCompany(payload);
            handleBackClick();
            const message = response;
            setModalMessage(message || "削除しました。");
            setIsModalOpen(true);
        } catch (error) {
            setModalMessage(error.message || "削除に失敗しました。");
            setIsModalOpen(true);
        }
    };

    const handleHideUnhideCompany = async (ishidden) => {
        try {
            const payload = {
                "company_id": company_id,
                "company_code": company_code,
                "company_name": company_name,
                "is_hidden": ishidden
            };
            setHidden(ishidden);
            const response = await registerCompany(payload);
            const message = response;
            setModalMessage(message || "再表示にしました。");
            setIsModalOpen(true);
            handleBackClick();
        } catch (error) {
            setModalMessage(error.message || "変更に失敗しました。");
            setIsModalOpen(true);
        }
    };
    return (
        <div id='Main' className='MainEdit' >
            <div id='Contents'className='ContentEdit'>
                {/* メッセジー画面 */}
                {isModalOpen && (
                    <Modal
                        isOpen={isModalOpen}
                        onClose={() => setIsModalOpen(false)}
                        message={modalMessage}
                    />
                )}
                {/* 組織検索画面 */}
                {/* {showPopup && (<CompanySearch onClose={handleClosePopup} onSelect={handleSelect} />)} */}
                {/* 組織一覧画面 */}
                <div id="ajax-view-disp">
                    {/* 管理者一覧 */}
                    <div className="display-main">
                        <div className="lay-master-set">
                            <div className="lay-master-set-wrap">
                                <div className="lay-master-set__title">
                                    <span>組織情報編集</span>
                                </div>
                                <div className="lay-master-set__form">
                                    <form id="AdminEdit">
                                        <div className="wrapper" style={{ textAlign: 'left' }}>
                                            <div className="mail-send__set">
                                                <div className="mail-input">
                                                    {/* ボタン */}
                                                    <div style={{ width: '70%', marginTop: '20px' }}>
                                                        <div style={{ display: 'flex' }}>
                                                            <div className='add-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__companyedit morelong"
                                                                    onClick={handleHideUnhideCompany}
                                                                >
                                                                    {ishidden === 1 ? "再表示" : "非表示"}
                                                                </button>
                                                            </div>
                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className={"mod-btn__admindelete add isDisabled ? 'disabled' : ''"}
                                                                    onClick={handleDeleteCompany}
                                                                    disabled={isDisabled}
                                                                >
                                                                    削除
                                                                </button>
                                                            </div>
                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className={"mod-btn__roundborder add ishidden ? 'disabled' : ''"}
                                                                    onClick={handleSaveCompany}
                                                                    disabled={ishidden}
                                                                >
                                                                    更新
                                                                </button>
                                                            </div>

                                                            <div className='back-btn'>
                                                                <button
                                                                    type="button"
                                                                    className="mod-btn__adminback back"
                                                                    onClick={handleBackClick}
                                                                >
                                                                    戻る
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    {/* 設定項目 */}
                                                    <div className="mod-form__set" style={{ marginTop: '40px' }} >
                                                        <dl>
                                                            <dt>組織ID</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        id="company_code"
                                                                        name="company_code"
                                                                        value={ company_code}
                                                                        onChange={(e) => setCompany_code(e.target.value)}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="error_msg">
                                                                        {errorMessages.company_code && <span style={{ color: 'red' }}>{errorMessages.company_code}</span>}
                                                                    </div>
                                                                </div>

                                                            </dd>
                                                        </dl>


                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>組織名</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        type="text"
                                                                        id="company_name"
                                                                        name="company_name"
                                                                        value={ company_name}
                                                                        onChange={(e) => setCompany_name(e.target.value)}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="error_msg">
                                                                        {errorMessages.company_name && <span style={{ color: 'red' }}>{errorMessages.company_name}</span>}
                                                                    </div>
                                                                </div>

                                                            </dd>
                                                        </dl>


                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>管理ユニットID</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        className='readonlyCls'
                                                                        type="text"
                                                                        id="manage_unit_id"
                                                                        name="manage_unit_id"
                                                                        value={  manage_unit_id}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </dd>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>管理ユニット名</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        className='readonlyCls'
                                                                        type="text"
                                                                        id="manage_unit_name"
                                                                        name="manage_unit_name"
                                                                        value={  manage_unit_name}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </dd>
                                                        </dl>

                                                        <dl style={{ marginTop: '10px' }}>
                                                            <dt>取得日</dt>
                                                            <dd style={{ width: '60%' }}>
                                                                <div className="mod-form__text">
                                                                    <input
                                                                        className='readonlyCls'
                                                                        type="text"
                                                                        id="create_date"
                                                                        name="create_date"
                                                                        value={  create_date}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                            </dd>
                                                        </dl>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

CompanyEdit.propTypes = {
    companydata: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    fetchCompanyEdit: PropTypes.func.isRequired,
    registerCompany: PropTypes.func.isRequired
};

export default React.memo(CompanyEdit);